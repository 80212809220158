/*eslint-disable*/
import { Model, ManyToMany, ForeignKey, OneToOne } from "redux-orm";
import generateORMActionName from "../redux/reducers/orm.action.gen";
import {
    CREATE_ACTION,
    UPDATE_ACTION,
    DELETE_ACTION,
    CREATE_BATCH_ACTION,
    UPDATE_BATCH_ACTION,
    DELETE_BATCH_ACTION,
    DELETE_ALL_ACTION,
    ID_ATTRIBUTE,
    VERSION_ATTRIBUTE,
    QUERY_VERSION_ATTRIBUTE,
    SOFT_DELETE_ATTRIBUTE,
    DELETE_SOFT_ACTION,
    DELETE_SOFT_BATCH_ACTION,
    DELETE_SOFT_ALL_ACTION,
    DELETE_ADVANCE_ACTION,
} from "../Constants/constants";
import _ from "lodash";

const sep = "_____";
const vep = "@@@";

export default class BaseORMModel extends Model {
    static getVersionId(d) {
        if (!d) return null;

        if (Array.isArray(d)) {
            return _.join(
                (d ?? []).map((_d) => _d[QUERY_VERSION_ATTRIBUTE]),
                sep
            );
        }

        if (typeof d === "object") {
            return (d ?? {})[QUERY_VERSION_ATTRIBUTE] ?? null;
        }

        return null;
    }

    advDelete({ cascade = false } = {}) {
        const virtualFields = this.constructor.virtualFields;

        if (!cascade) {
            this.delete();
            return;
        }

        // cascade is true, then delete one of many side and many sides
        const oneFromOneToOneFields = _.keys(virtualFields ?? {}).filter(
            (field) => virtualFields[field] instanceof OneToOne
        );

        const mForeignFields = _.keys(virtualFields ?? {}).filter(
            (field) => virtualFields[field] instanceof ForeignKey
        );
        const mFields = _.keys(virtualFields ?? {}).filter(
            (field) => virtualFields[field] instanceof ManyToMany
        );

        // delete one from one to many fields first
        (oneFromOneToOneFields ?? []).forEach((field) => {
            if (this[field] && this[field].advDelete)
                this[field].advDelete({ cascade });
        });

        (mForeignFields ?? []).forEach((field) => {
            if (this[field] && this[field].toModelArray)
                (this[field].toModelArray() ?? []).forEach((m) => {
                    m && m.advDelete && m.advDelete({ cascade });
                });
        });

        (mFields ?? []).forEach((field) => {
            if (this[field] && this[field].toModelArray && this[field].clear) {
                this[field].clear();
            }
        });

        this.delete && this.delete();
    }

    expand({ include }) {
        include = include || [];
        const fields = this.constructor.fields;
        const virtualFields = this.constructor.virtualFields;

        // loop through include and filter by declared relation fields
        include = include
            .map((incl) => {
                if (typeof incl === "string")
                    return {
                        field: incl,
                        include: [],
                    };
                else if (typeof incl === "object")
                    return {
                        field: (_.keys(incl) ?? [])[0],

                        include: incl[(_.keys(incl) ?? [])[0]] ?? [],
                    };

                return null;
            })
            .filter((d) => d)
            .map(({ field: key, include }) => {
                if (
                    virtualFields[key] &&
                    virtualFields[key] instanceof ForeignKey
                ) {
                    return {
                        field: key,
                        type: "oneFromOneToMany",
                        include,
                        meta: virtualFields[key],
                    };
                }
                if (
                    virtualFields[key] &&
                    virtualFields[key] instanceof OneToOne
                ) {
                    return {
                        field: key,
                        type: "oneFromOneToOne",
                        include,
                        meta: virtualFields[key],
                    };
                } else if (
                    virtualFields[key] &&
                    virtualFields[key] instanceof ManyToMany
                ) {
                    return {
                        field: key,
                        type: "manyToMany",
                        include,
                        meta: virtualFields[key],
                    };
                } else {
                    let relationKey = _.keys(fields ?? {}).filter(
                        (field) =>
                            fields[field] instanceof ForeignKey &&
                            // relation key is the given key
                            (fields[field].as ?? field) === key
                    )[0];

                    let relMeta = fields[relationKey];

                    if (relMeta) {
                        return {
                            field: relMeta.as ?? key,
                            type: "manyFromOneToMany",
                            include,
                            meta: relMeta,
                        };
                    }

                    relationKey = _.keys(fields ?? {}).filter(
                        (field) =>
                            fields[field] instanceof OneToOne &&
                            // relation key is the given key
                            (fields[field].as ?? field) === key
                    )[0];

                    relMeta = fields[relationKey];

                    if (relMeta) {
                        return {
                            field: relMeta.as ?? key,
                            type: "manyFromOneToOne",
                            include,
                            meta: relMeta,
                        };
                    }
                }

                return null;
            })
            .filter((d) => d);

        let ret = {
            ...(this.ref ?? {}),
        };

        ret = _.omit(ret ?? {}, this.constructor.options.deleteAttribute);

        // foreach relation field, getting the model from the session
        include.forEach(({ field, include, type, meta }) => {
            // many to many
            // access relation.toModelArray, loopthrough it and pass include down
            if (type === "manyToMany") {
                ret[field] = this[field]
                    ? this[field]
                          .toModelArray()
                          .filter(
                              (ins) =>
                                  ins &&
                                  ins.ref &&
                                  !((ins ?? {}).ref ?? {})[
                                      ins.constructor.options.deleteAttribute
                                  ]
                          )
                          .map((modelInstance) => {
                              return modelInstance.expand({ include });
                          })
                    : [];
            }

            // one to many
            // access relation.toModelArray, loopthrough it and pass include down
            if (type === "oneFromOneToMany") {
                ret[field] = this[field]
                    ? this[field]
                          .toModelArray()
                          .filter(
                              (ins) =>
                                  ins &&
                                  ins.ref &&
                                  !((ins ?? {}).ref ?? {})[
                                      ins.constructor.options.deleteAttribute
                                  ]
                          )
                          .map((modelInstance) => {
                              return modelInstance.expand({ include });
                          })
                    : [];
            }
            if (type === "manyFromOneToMany") {
                ret[field] =
                    this[field] &&
                    this[field].ref &&
                    !this[field].ref[
                        this[field].constructor.options.deleteAttribute
                    ]
                        ? this[field].expand({ include })
                        : null;
            }

            // one to one
            // access relation.toModelArray, loopthrough it and pass include down
            if (type === "oneFromOneToOne" || type === "manyFromOneToOne") {
                ret[field] =
                    this[field] &&
                    this[field].ref &&
                    !this[field].ref[
                        this[field].constructor.options.deleteAttribute
                    ]
                        ? this[field].expand({ include })
                        : null;
            }
        });

        const retId =
            (this.ref ?? {})[this.constructor.options.idAttribute] ?? "";
        const verId =
            (this.ref ?? {})[this.constructor.options.versionAttribute] ?? "";
        const retver = _.join([retId, verId], vep);

        const relationRefIds =
            !include || include.length === 0
                ? ""
                : _.join(
                      (include ?? [])
                          .map(({ field } = {}) => {
                              let idrep = null;
                              let ins = null;
                              try {
                                  if (this[field].toModelArray) {
                                      ins = this[field].toModelArray();
                                  }
                              } catch (ex) {}

                              try {
                                  if (!ins) ins = [this[field]];
                              } catch (ex) {}

                              let lookup = null;

                              try {
                                  idrep = _.join(
                                      (ins ?? []).map((modelInstance) => {
                                          const idAttribute =
                                              modelInstance.constructor.options
                                                  .idAttribute;
                                          const queryAttribute =
                                              modelInstance.constructor.options
                                                  .queryAttribute;
                                          if (!lookup) {
                                              lookup = _.keyBy(
                                                  _.flattenDeep([ret[field]]),
                                                  (d) => d[idAttribute]
                                              );
                                          }

                                          const modId = (modelInstance.ref ??
                                              {})[idAttribute];
                                          const modVer = (modelInstance.ref ??
                                              {})[
                                              modelInstance.constructor.options
                                                  .versionAttribute
                                          ];

                                          return (
                                              (lookup[modId] ?? {})[
                                                  queryAttribute
                                              ] ?? _.join([modId, modVer], vep)
                                          );
                                      }),
                                      sep
                                  );

                                  return idrep;
                              } catch (ex) {}

                              return "";
                          })
                          .filter(
                              (d) =>
                                  d !== null &&
                                  d !== undefined &&
                                  d.toString().trim() !== ""
                          ),
                      sep
                  );

        ret[this.constructor.options.queryAttribute] = _.join(
            [retver, relationRefIds].filter(
                (d) =>
                    d !== null && d !== undefined && d.toString().trim() !== ""
            ),
            sep
        );

        return ret;
    }

    static select(session, { include, filter } = {}) {
        filter = filter || {};

        const afterFilter = this.filter({
            ...(filter ?? {}),
            [this.options.deleteAttribute]: false,
        }).toModelArray();

        // getting all relation fields
        return afterFilter.map((f) => f.expand({ include: include ?? [] }));
    }

    static normalise(session, entities = {}, data = {}) {
        if (!session) return null;

        const idValue = data[this.options.idAttribute];

        if (!entities) {
            entities = {};
        }

        if (!entities[this.modelName]) {
            entities[this.modelName] = {};
        }

        // no relation fields in data
        // return entities;
        // omit relation fields
        let relations = _.keys(data ?? {})
            .map((key) => {
                if (data[key] === null || data[key] === undefined) return null;

                if (
                    this.virtualFields[key] &&
                    this.virtualFields[key] instanceof ForeignKey
                ) {
                    return {
                        field: key,
                        type: "oneFromOneToMany",
                        data: data[key] ?? [],
                        meta: this.virtualFields[key],
                    };
                }
                if (
                    this.virtualFields[key] &&
                    this.virtualFields[key] instanceof OneToOne
                ) {
                    return {
                        field: key,
                        type: "oneFromOneToOne",
                        data: data[key] ?? [],
                        meta: this.virtualFields[key],
                    };
                } else if (
                    this.virtualFields[key] &&
                    this.virtualFields[key] instanceof ManyToMany &&
                    this.fields[key] &&
                    this.fields[key] instanceof ManyToMany
                ) {
                    return {
                        field: key,
                        type: "decManyToMany",
                        data: data[key] ?? [],
                        meta: this.virtualFields[key],
                    };
                } else if (
                    this.virtualFields[key] &&
                    this.virtualFields[key] instanceof ManyToMany
                ) {
                    return {
                        field: key,
                        type: "nonDecManyToMany",
                        data: data[key] ?? [],
                        meta: this.virtualFields[key],
                    };
                } else {
                    let foreignKey = _.keys(this.fields ?? {}).filter(
                        (field) =>
                            this.fields[field] instanceof ForeignKey &&
                            // (field === key || this.fields[field].as === key)
                            // foreign key is the given key
                            (field === key ||
                                // relation key is the given key
                                (this.fields[field].as ?? field) === key)
                    )[0];

                    let relMeta = this.fields[foreignKey];

                    if (relMeta) {
                        return {
                            field: foreignKey,
                            type: "manyFromOneToMany",
                            data: data[key],
                            meta: relMeta,
                        };
                    }

                    foreignKey = _.keys(this.fields ?? {}).filter(
                        (field) =>
                            this.fields[field] instanceof OneToOne &&
                            // (field === key || this.fields[field].as === key)
                            // foreign key is the given key
                            (field === key ||
                                // relation key is the given key
                                (this.fields[field].as ?? field) === key)
                    )[0];

                    relMeta = this.fields[foreignKey];

                    if (relMeta) {
                        return {
                            field: foreignKey,
                            type: "manyFromOneToOne",
                            data: data[key],
                            meta: relMeta,
                        };
                    }
                }

                return null;
            })
            .filter((d) => d);

        if (data && idValue !== null && idValue !== undefined) {
            // remove relation fields from data
            (relations ?? []).forEach((relation) => {
                data = _.omit(data ?? {}, relation.field);
            });

            const emptyRelation = {};
            _.keys(this.fields ?? {})
                .filter(
                    (field) =>
                        this.fields[field] instanceof ForeignKey ||
                        this.fields[field] instanceof OneToOne
                )
                .forEach((field) => {
                    if (
                        session &&
                        session[this.modelName] &&
                        session[this.modelName].withId &&
                        session[this.modelName].withId(idValue) &&
                        session[this.modelName].withId(idValue)[field]
                    ) {
                    } else {
                        emptyRelation[field] = null;
                    }
                });

            // assign entities
            entities[this.modelName] = {
                ...(entities[this.modelName] ?? {}),
                ...{
                    [idValue]: {
                        ...emptyRelation,
                        ...(data ?? {}),
                        ...((entities[this.modelName] ?? {})[idValue] ?? {}),
                    },
                },
            };
        } else {
            return entities;
        }

        // no relation fields
        if ((relations ?? []).length === 0) return entities;

        // otherwise
        // run normalisation
        (relations || []).forEach(({ field, type, data, meta }) => {
            if (type === "decManyToMany") {
                if (
                    data &&
                    Array.isArray(data) &&
                    meta &&
                    meta.toModelName !== null &&
                    meta.toModelName !== undefined &&
                    session[meta.toModelName]
                ) {
                    entities[this.modelName][idValue][field] = (data ?? []).map(
                        (d) => {
                            if (typeof d === "string") return d;

                            return d[
                                session[meta.toModelName].options.idAttribute
                            ];
                        }
                    );

                    // build data for the nested
                    (data ?? []).forEach((d) => {
                        typeof d === "object" &&
                            session[meta.toModelName].normalise(
                                session,
                                entities,
                                d ?? {}
                            );
                    });
                }
            } else if (type === "nonDecManyToMany") {
                if (
                    data &&
                    Array.isArray(data) &&
                    meta &&
                    meta.toModelName !== null &&
                    meta.toModelName !== undefined &&
                    session[meta.toModelName]
                ) {
                    if (!entities[meta.toModelName])
                        entities[meta.toModelName] = {};

                    (data ?? []).forEach((d) => {
                        const id = (d ?? {})[
                            session[meta.toModelName].options.idAttribute
                        ];
                        if (!entities[meta.toModelName][id])
                            entities[meta.toModelName][id] = {};

                        if (!entities[meta.toModelName][id][meta.relatedName])
                            entities[meta.toModelName][id][meta.relatedName] =
                                [];

                        entities[meta.toModelName][id][meta.relatedName].push(
                            idValue
                        );
                    });

                    // build data for the nested
                    (data ?? []).forEach((d) => {
                        typeof d === "object" &&
                            session[meta.toModelName].normalise(
                                session,
                                entities,
                                d ?? {}
                            );
                    });
                }
            } else if (
                type === "oneFromOneToMany" ||
                type === "oneFromOneToOne"
            ) {
                if (
                    data &&
                    ((type === "oneFromOneToMany" && Array.isArray(data)) ||
                        (type === "oneFromOneToOne" && !Array.isArray(data))) &&
                    meta &&
                    meta.toModelName !== null &&
                    meta.toModelName !== undefined &&
                    session[meta.toModelName]
                ) {
                    let foreignKey = null;
                    let desModel = session[meta.toModelName];

                    if (desModel) {
                        foreignKey = _.keys(desModel.fields ?? {}).filter(
                            (_field) =>
                                (desModel.fields[_field] instanceof
                                    ForeignKey ||
                                    desModel.fields[_field] instanceof
                                        OneToOne) &&
                                desModel.fields[_field].relatedName === field
                        )[0];
                    }

                    // build data for the nested
                    _.flattenDeep([data ?? []]).forEach((d) => {
                        typeof d === "object" &&
                            session[meta.toModelName].normalise(
                                session,
                                entities,
                                d ?? {}
                            );
                    });

                    if (
                        desModel &&
                        foreignKey !== null &&
                        foreignKey !== undefined
                    ) {
                        _.flattenDeep([data ?? []]).forEach((d) => {
                            let dIdValue = null;

                            if (typeof d === "object") {
                                dIdValue =
                                    d[
                                        session[meta.toModelName].options
                                            .idAttribute
                                    ];
                            } else if (typeof d === "string") {
                                dIdValue = d;
                            }

                            // inject foreign key

                            if (!entities[meta.toModelName]) {
                                entities[meta.toModelName] = {};
                            }

                            if (
                                dIdValue !== null &&
                                dIdValue !== undefined &&
                                !entities[meta.toModelName][dIdValue]
                            ) {
                                entities[meta.toModelName][dIdValue] = {
                                    [session[meta.toModelName].options
                                        .idAttribute]: dIdValue,
                                };
                            }

                            if (dIdValue !== null && dIdValue !== undefined) {
                                entities[meta.toModelName][dIdValue][
                                    foreignKey
                                ] = idValue;
                            }
                        });
                    }
                }
            } else if (
                type === "manyFromOneToMany" ||
                type === "manyFromOneToOne"
            ) {
                if (
                    data &&
                    typeof data === "object" &&
                    !Array.isArray(data) &&
                    meta &&
                    meta.toModelName !== null &&
                    meta.toModelName !== undefined &&
                    session[meta.toModelName]
                ) {
                    entities[this.modelName][idValue][field] =
                        data[session[meta.toModelName].options.idAttribute];

                    session[meta.toModelName].normalise(
                        session,
                        entities,
                        data ?? {}
                    );
                } else if (data && typeof data === "string") {
                    entities[this.modelName][idValue][field] = data;
                }
            }
        });

        return entities;
    }

    static upsert(data, modelClass, session) {
        if (!data) return;

        const normalised = modelClass.normalise(session, {}, data);

        _.keys(normalised ?? {}).forEach((modelName) => {
            const cls = session[modelName];

            const data = normalised[modelName];

            if (cls && data) {
                _.values(data || {}).forEach((d) => {
                    if (cls.idExists(d[cls.options.idAttribute])) {
                        const inst = cls.withId(d[cls.options.idAttribute]);
                        const currentVersion =
                            (inst.ref ?? {})[cls.options.versionAttribute] ?? 0;

                        const uP = _.omit(d ?? {}, cls.options.idAttribute);
                        uP[cls.options.versionAttribute] = currentVersion + 1;

                        inst.update({
                            [cls.options.deleteAttribute]: false,
                            ...(uP ?? {}),
                        });
                    } else {
                        d[cls.options.versionAttribute] = 0;
                        cls.create({
                            [cls.options.deleteAttribute]: false,
                            ...(d ?? {}),
                        });
                    }
                });
            }
        });
    }

    static reducer(action, modelClass, session) {
        const sessionModel = session[modelClass.modelName];

        let manyToManyKeys = [];
        if (sessionModel) {
            manyToManyKeys = manyToManyKeys.concat(
                _.keys(sessionModel.virtualFields ?? {}).filter((field) => {
                    return (
                        sessionModel.virtualFields[field] instanceof ManyToMany
                    );
                })
            );
        }

        // support many to many deletion
        manyToManyKeys.forEach((key) => {
            switch (action.type) {
                case generateORMActionName({
                    slice: modelClass.modelName,
                    actionName: `${DELETE_BATCH_ACTION}_${key}`,
                }):
                    if (action.payload && sessionModel) {
                        const id =
                            action.payload[sessionModel.options.idAttribute];
                        if (sessionModel.idExists(id)) {
                            const inst = sessionModel.withId(id);
                            let refIdAttribute = null;
                            let refVersionAttribute = null;
                            let refDeleteAttribute = null;

                            if (inst[key]) {
                                refIdAttribute =
                                    inst[key].modelClass.options.idAttribute;
                                refVersionAttribute =
                                    inst[key].modelClass.options
                                        .versionAttribute;
                                refDeleteAttribute =
                                    inst[key].modelClass.options
                                        .deleteAttribute;

                                (action.payload[key] ?? []).forEach((d) => {
                                    inst[key] &&
                                        inst[key].remove &&
                                        inst[key].remove(d[refIdAttribute]);
                                });
                            }
                        }
                    }
                    break;

                case generateORMActionName({
                    slice: modelClass.modelName,
                    actionName: `${DELETE_ALL_ACTION}_${key}`,
                }):
                    if (action.payload && sessionModel) {
                        const id =
                            action.payload[sessionModel.options.idAttribute];
                        if (sessionModel.idExists(id)) {
                            const inst = sessionModel.withId(id);
                            inst[key] && inst[key].clear && inst[key].clear();
                        }
                    }
                    break;

                case generateORMActionName({
                    slice: modelClass.modelName,
                    actionName: `${CREATE_BATCH_ACTION}_${key}`,
                }):
                    if (action.payload && sessionModel) {
                        const parentId =
                            action.payload[sessionModel.options.idAttribute];
                        const payload = action.payload[key] ?? [];

                        let inst = null;
                        let refIdAttribute = null;
                        let refVersionAttribute = null;
                        let refDeleteAttribute = null;

                        if (sessionModel.idExists(parentId)) {
                            inst = sessionModel.withId(parentId);
                        }

                        if (inst && inst[key]) {
                            refIdAttribute =
                                inst[key].modelClass.options.idAttribute;
                            refVersionAttribute =
                                inst[key].modelClass.options.versionAttribute;
                            refDeleteAttribute =
                                inst[key].modelClass.options.deleteAttribute;

                            (payload || []).forEach((d) => {
                                const payloadId = d[refIdAttribute];

                                this.upsert(d, inst[key].modelClass, session);
                                const element = inst[key]
                                    .filter({
                                        [refIdAttribute]: payloadId,
                                    })
                                    .toRefArray()[0];

                                if (payloadId && !element) {
                                    inst[key].add(payloadId);
                                }
                            });
                        }
                    }
                    break;
            }
        });

        switch (action.type) {
            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: CREATE_ACTION,
            }):
                if (action.payload) {
                    modelClass.upsert(action.payload, modelClass, session);
                }
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: UPDATE_ACTION,
            }):
                if (action.payload) {
                    modelClass.upsert(action.payload, modelClass, session);
                }
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_ACTION,
            }):
                if (action.payload) {
                    const inst = modelClass.withId(
                        action.payload[modelClass.options.idAttribute]
                    );
                    inst && inst.delete();
                }
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_SOFT_ACTION,
            }):
                if (action.payload) {
                    modelClass.upsert(
                        {
                            ...(action.payload ?? {}),
                            [modelClass.options.deleteAttribute]: true,
                        },
                        modelClass,
                        session
                    );
                }
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: CREATE_BATCH_ACTION,
            }):
                (action.payload ?? []).forEach((p) => {
                    modelClass.upsert(p, modelClass, session);
                    // p && modelClass.create(p);
                });
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: UPDATE_BATCH_ACTION,
            }):
                (action.payload ?? []).forEach((p) => {
                    // if (p) {
                    //     const uP = _.omit(p ?? {}, modelClass.options.idAttribute);
                    //     modelClass.withId(action.payload[modelClass.options.idAttribute]).update(uP);
                    // }

                    modelClass.upsert(p, modelClass, session);
                });
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_BATCH_ACTION,
            }):
                (action.payload ?? []).forEach((p) => {
                    if (p) {
                        const inst = modelClass.withId(
                            p[modelClass.options.idAttribute]
                        );
                        inst.delete();
                    }
                });
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_SOFT_BATCH_ACTION,
            }):
                if (action.payload) {
                    (action.payload ?? []).forEach((p) => {
                        modelClass.upsert(
                            {
                                ...(p ?? {}),
                                [modelClass.options.deleteAttribute]: true,
                            },
                            modelClass,
                            session
                        );
                    });
                }
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_ALL_ACTION,
            }):
                modelClass
                    .all()
                    .toModelArray()
                    .forEach((d) => {
                        d && d.delete();
                    });
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_SOFT_ALL_ACTION,
            }):
                modelClass
                    .all()
                    .toModelArray()
                    .forEach((d) => {
                        d.update({
                            [d.constructor.options.deleteAttribute]: true,
                        });
                    });
                break;

            case generateORMActionName({
                slice: modelClass.modelName,
                actionName: DELETE_ADVANCE_ACTION,
            }):
                const { filter, cascade = false } = action.payload ?? {};
                const modelInstances = modelClass
                    .filter(filter ?? {})
                    .toModelArray();
                (modelInstances || []).forEach((modelInstance) =>
                    modelInstance.advDelete({ cascade })
                );

                break;
        }

        // Return value is ignored.
        return undefined;
    }
}

BaseORMModel.options = {
    idAttribute: ID_ATTRIBUTE,
    versionAttribute: VERSION_ATTRIBUTE,
    queryAttribute: QUERY_VERSION_ATTRIBUTE,
    deleteAttribute: SOFT_DELETE_ATTRIBUTE,
};
