export interface EflowRole {
    name: KnownRoles;
    description: string;
}

export interface EflowRoleOperation {
    name: KnownRoleOperations;
    description: string;
}

export interface EflowRoles {
    APP_ADMIN: EflowRole;
    APP_USER: EflowRole;
    GROUP_ADMIN: EflowRole;
    GROUP_USER: EflowRole;
}

export interface EflowRoleOperations {
    GROUP_INVITE: EflowRoleOperation;
    GROUP_MANAGE: EflowRoleOperation;
    GROUP_DATA: EflowRoleOperation;
}

export enum KnownRoles {
    APP_ADMIN = "app:admin",
    APP_USER = "app:user",
    GROUP_ADMIN = "group:admin",
    GROUP_USER = "group:user",
}

export enum KnownRoleOperations {
    GROUP_INVITE = "group:invite",
    GROUP_MANAGE = "group:manage",
    GROUP_DATA = "group:data",
}

export const EFLOW_ROLES: EflowRoles = {
    APP_ADMIN: {
        name: KnownRoles.APP_ADMIN,
        description: "Application Administrator",
    },
    APP_USER: {
        name: KnownRoles.APP_USER,
        description: "Application User",
    },
    GROUP_ADMIN: {
        name: KnownRoles.GROUP_ADMIN,
        description: "Group Administrator",
    },
    GROUP_USER: {
        name: KnownRoles.GROUP_USER,
        description: "Group User",
    },
};

export const EFLOW_ROLE_OPERATIONS: EflowRoleOperations = {
    GROUP_INVITE: {
        name: KnownRoleOperations.GROUP_INVITE,
        description: "Allow inviting users to groups",
    },
    GROUP_MANAGE: {
        name: KnownRoleOperations.GROUP_MANAGE,
        description: "Allow creating, updating and deleting groups",
    },
    GROUP_DATA: {
        name: KnownRoleOperations.GROUP_DATA,
        description: "Alow using group data",
    },
};
