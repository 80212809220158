import API from "../api";
import { TimeSeriesUploadType, UploadComplianceDataRequest } from "./types";
import { ComplianceData } from "types";

export default class ComplianceDataService {
    static async uploadComplianceData(
        reportingSiteId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<ComplianceData> {
        const request: UploadComplianceDataRequest = {
            azureId: blobId,
            fileName: fileName,
            type: type,
        };

        const complianceData = await API.post<ComplianceData>(
            `/reporting-sites/${reportingSiteId}/compliance-data/upload`,
            request
        );

        return complianceData;
    }

    static async uploadScenarioComplianceData(
        reportingSiteId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<ComplianceData> {
        const request: UploadComplianceDataRequest = {
            azureId: blobId,
            fileName: fileName,
            type: type,
        };

        const complianceData = await API.post<ComplianceData>(
            `/scenario-reporting-sites/${reportingSiteId}/compliance-data/upload`,
            request
        );

        return complianceData;
    }
}
