import { many, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_FLOW_RULE,
    ORM_SCENARIO_REPORTING_SITE,
    ID_ATTRIBUTE,
    ORM_SCENARIO_SYSTEM,
} from "Constants/constants";

export default class FlowRuleORM extends BaseORMModel {}

FlowRuleORM.modelName = ORM_FLOW_RULE;
FlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    startDay: attr(),
    endDay: attr(),
    startMonth: attr(),
    endMonth: attr(),
    reportingLevels: attr(),
    description: attr(),
    type: attr(),
    scenarioReportingSites: many({
        to: ORM_SCENARIO_REPORTING_SITE,
        relatedName: "flowRules",
    }),
    scenarioSystems: many({
        to: ORM_SCENARIO_SYSTEM,
        relatedName: "flowRules",
    }),
};
