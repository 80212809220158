import { store } from "../redux";
import generateORMActionName from "redux/reducers/orm.action.gen";
import {
    ORM_PARTIAL_SUCCESS_DATA,
    UPDATE_ACTION,
    ID_ATTRIBUTE,
} from "Constants/constants";

export const updateStoreOfSuccessTable = (rowId: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_PARTIAL_SUCCESS_DATA,
            actionName: UPDATE_ACTION,
        }),
        payload: {
            [ID_ATTRIBUTE]: rowId,
            ...(payload ?? {}),
        },
    });
};
