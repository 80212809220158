import React, { useState } from "react";
import { Accordian, Toast } from "Components";
import { ReportingSiteService, SystemService } from "../../services";
import {
    ClimateData,
    ComplianceData,
    FlowData,
    ReportingSite,
    System,
} from "types";
import SystemClimateUpload from "./SystemClimateUpload";
import AccordianReportingSite from "./AccordianReportingSite";
import OverlayButton from "../Button/OverlayButton";
import AccodianTitle from "./AccordianTitle";
import {
    CreateReportingSiteRequest,
    CreateSystemRequest,
} from "services/types";
import { EVENT_SPATIAL_SETUP_ENTITY_REMOVE } from "Constants/constants";
import { EventEmitter } from "Components/EventEmitter";

type AccordianSystemProps = {
    system: System;
    isParentSystem: boolean;
    onParentSystemRemoved?: (system: System) => void;
    onSubSystemRemoved?: (system: System) => void;
    updateSystemClimateData?: (id: string, climateData: ClimateData) => void;
};

const AccordianSystem = (props: AccordianSystemProps) => {
    const {
        system,
        isParentSystem,
        onParentSystemRemoved,
        onSubSystemRemoved,
        updateSystemClimateData,
    } = props;

    const systemId = system?.id;
    const [_system, setSystem] = useState<System>(system);
    const [subSystems, setSubSystems] = useState<System[]>([]);
    const [reportingSites, setReportingSites] = useState<ReportingSite[]>([]);

    const loadSystem = async (_systemId: string) => {
        const loadedSystem = await SystemService.getSystem(_systemId);

        setSystem(loadedSystem);

        if (loadedSystem?.subSystems?.length > 0) {
            setSubSystems(loadedSystem.subSystems);
        }

        if (loadedSystem.reportingSites?.length > 0) {
            setReportingSites(loadedSystem.reportingSites);
        }
    };

    const addSubSystem = async () => {
        try {
            const request: CreateSystemRequest = {
                name: "Untitled Sub-Catchment",
                regionId: system.regionId,
                parentSystemId: system.id,
            };

            const newSubSystem = await SystemService.createSystem(request);

            setSubSystems([...subSystems, newSubSystem]);

            Toast.success("Successfully created sub-catchment");
        } catch (error) {
            Toast.error("Error creating catchment");
        }
    };

    const updateSystemName = async (system: System, name: string) => {
        try {
            await SystemService.updateSystem(system.id, { name: name });
        } catch (error) {
            Toast.error("Error updating catchment");
        }
    };

    const doDelete = async (system: System) => {
        try {
            await SystemService.removeSystem(system.id);

            if (isParentSystem) {
                onParentSystemRemoved(system);
            } else {
                onSubSystemRemoved(system);
            }
        } catch (error) {
            Toast.error("Error deleting catchment");
        }
    };

    const removeSystem = (event: MouseEvent, system: System) => {
        event.stopPropagation();

        EventEmitter.dispatch(EVENT_SPATIAL_SETUP_ENTITY_REMOVE, {
            title: `Remove ${system.name}`,
            onDelete: async () => {
                await doDelete(system);
            },
        });
    };

    const removeSubSystem = (system: System) => {
        const next = subSystems.filter((x) => x.id !== system.id);

        setSubSystems(next);
    };

    const addReportingSite = async () => {
        try {
            const request: CreateReportingSiteRequest = {
                name: "Untitled Reporting Site",
                systemId: system.id,
                regionId: system.regionId,
            };

            const newReportingSite =
                await ReportingSiteService.createReportingSite(request);

            setReportingSites([...reportingSites, newReportingSite]);

            Toast.success("Successfully creating reporting site");
        } catch (error) {
            Toast.error("Error creating reporting site");
        }
    };

    const removeReportingSite = (rs: ReportingSite) => {
        const next = reportingSites.filter((x) => x.id !== rs.id);

        setReportingSites(next);
    };

    const updateFlowData = (id: string, flowData?: FlowData) => {
        const next = reportingSites.map((rs) => {
            if (rs.id === id) {
                return {
                    ...rs,
                    flowData: flowData,
                };
            }

            return rs;
        });

        setReportingSites(next);
    };

    const updateComplianceData = (id: string, cd?: ComplianceData) => {
        const next = reportingSites.map((rs) => {
            if (rs.id === id) {
                return {
                    ...rs,
                    complianceData: cd,
                };
            }

            return rs;
        });

        setReportingSites(next);
    };

    return (
        <Accordian
            key={_system.id}
            expandAtStart={false}
            stylingHeader={"region-header"}
            onSelect={async () => {
                loadSystem(systemId);
            }}
            title={
                <AccodianTitle
                    id={_system.id}
                    name={_system.name}
                    onUpdate={async (name) => {
                        await updateSystemName(system, name);
                    }}
                    onDelete={async (e) => {
                        removeSystem(e, _system);
                    }}
                />
            }
        >
            {isParentSystem && (
                <SystemClimateUpload
                    system={system}
                    updateSystem={updateSystemClimateData}
                />
            )}

            <OverlayButton
                className="mb-2 mr-2"
                tooltip="Add a new reporting site to the system"
                tooltipPosition="right"
                onClick={async () => {
                    await addReportingSite();
                }}
            >
                Add reporting site
            </OverlayButton>

            {isParentSystem && (
                <OverlayButton
                    className="mb-2 mr-2"
                    tooltip="Add a new sub-catchment to the system"
                    tooltipPosition="right"
                    onClick={async () => {
                        await addSubSystem();
                    }}
                >
                    Add sub-catchment
                </OverlayButton>
            )}

            {subSystems.map((subSystem) => {
                return (
                    <AccordianSystem
                        key={subSystem.id}
                        isParentSystem={false}
                        system={subSystem}
                        onSubSystemRemoved={removeSubSystem}
                    />
                );
            })}

            {reportingSites.map((rs) => {
                return (
                    <AccordianReportingSite
                        key={rs.id}
                        reportingSite={rs}
                        onRemoved={removeReportingSite}
                        onFlowDataUpdate={updateFlowData}
                        onComplianceDataUpdate={updateComplianceData}
                    />
                );
            })}
        </Accordian>
    );
};

export default AccordianSystem;
