import React, { useMemo } from "react";
import { PARTIAL_RULE_TABLE_DEFINITION } from "Constants/constants";
import BaseORMModel from "models/BaseORMModel";
import { FlowRule, PartialTable } from "types";
import RuleTable from "./RuleTable";

const getOrderedTableForRule = (flowRule: FlowRule): PartialTable[] => {
    if (!flowRule || !flowRule.tables) {
        return [];
    }

    const order = PARTIAL_RULE_TABLE_DEFINITION[flowRule.type];

    return order
        .map((t) => {
            const table = flowRule.tables.find(
                (x) => x.type.toLowerCase() === t.state.toLowerCase()
            );

            return table;
        })
        .filter((x) => x);
};

interface RuleTablesProps {
    flowRule: FlowRule;
    onTableUpdated: () => void;
}

const RuleTables = ({ flowRule, onTableUpdated }: RuleTablesProps) => {
    const tables = getOrderedTableForRule(flowRule);

    const scene = useMemo(() => {
        return (
            <>
                {tables.map((table, index) => {
                    return (
                        <RuleTable
                            key={index}
                            table={table}
                            onTableUpdated={onTableUpdated}
                        />
                    );
                })}
            </>
        );
    }, [BaseORMModel.getVersionId(tables)]);

    return scene;
};

export default RuleTables;
