import React from "react";
import Loadable from "react-loadable";
import { ReactComponent as Animiation } from "./truii-animated.svg";

type Positions = "left" | "center";

interface LoaderProps extends Loadable.LoadingComponentProps {
    message?: string;
    fullpage: boolean;
    width?: number;
    position?: Positions;
}

interface LoaderMessageProps {
    message?: string;
}

const LoaderMessage = ({ message }: LoaderMessageProps) => {
    if (!message) {
        return null;
    }

    return (
        <div className="col-12">
            <span>{message}</span>
        </div>
    );
};

const Loader: React.FC<LoaderProps> = ({
    message,
    fullpage = true,
    width = 125,
    position = "center",
}: LoaderProps) => {
    if (fullpage) {
        return (
            <div
                className="h-100 w-100 p-2"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    className="row justify-content-center text-center"
                    style={{ textDecoration: "none" }}
                >
                    <div className="col-12">
                        <Animiation width={width} />
                    </div>

                    <LoaderMessage message={message} />
                </div>
            </div>
        );
    }

    const getClassesForPosition = (position: Positions) => {
        switch (position) {
            case "center":
                return "justify-content-center text-center";
            case "left":
                return "text-left";
        }
    };

    const positionClasses = getClassesForPosition(position);

    return (
        <div
            className={`row p-2 ${positionClasses}`}
            style={{ textDecoration: "none" }}
        >
            <div className="col-12">
                <Animiation width={width} />
            </div>

            <LoaderMessage message={message} />
        </div>
    );
};
export default Loader;
