import { attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
    ORM_CUSTOM_FLOW_RULE,
} from "Constants/constants";

export default class CustomFlowRuleORM extends BaseORMModel {}

CustomFlowRuleORM.modelName = ORM_CUSTOM_FLOW_RULE;
CustomFlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRuleId: oneToOne({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "custom",
    }),
};
