import React, { useMemo } from "react";
import { CSVValidationError } from "types";
import { isNilOrEmpty } from "utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import styles from "./CSVValidationErrorDisplay.module.scss";

interface CSVValidationErrorProps {
    error: CSVValidationError;
}

const CSVValidationErrorDisplay = ({ error }: CSVValidationErrorProps) => {
    const label = useMemo(() => {
        if (isNilOrEmpty(error)) {
            return "";
        }

        switch (error) {
            case CSVValidationError.NON_CONTINUOUS_DATE:
                return "Dataset contains non continuous dates";
        }
    }, [error]);

    return (
        <div className={styles["csv-validation-error"]}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{label}</span>
        </div>
    );
};

export default CSVValidationErrorDisplay;
