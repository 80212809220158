import { Group, Membership } from "types";
import API from "../api";
import {
    GroupCreateRequest,
    GroupInviteRequest,
    GroupUpdateRequest,
} from "./types";
import { URL } from "../Constants/api";
import { KnownRoles } from "Constants/auth";

export default class GroupService {
    private static readonly url: string = URL.GROUP;

    public static async getMembers(groupId: string): Promise<Membership[]> {
        const members = await API.get<Membership[]>(
            `${this.url}/${groupId}/members`
        );

        return members;
    }

    public static async createGroup(
        request: GroupCreateRequest
    ): Promise<Group> {
        const group = await API.post<Group>(this.url, request);

        return group;
    }

    public static async updateGroup(
        id: string,
        request: GroupUpdateRequest
    ): Promise<Group> {
        const group = await API.patch<Group>(`${this.url}/${id}`, request);

        return group;
    }

    public static async invite(
        groupId: string,
        user: string,
        role: KnownRoles
    ): Promise<Membership> {
        const request: GroupInviteRequest = {
            user: user,
            role: role,
        };

        const membership = await API.post<Membership>(
            `${this.url}/${groupId}/invite`,
            request
        );

        return membership;
    }

    public static async removeMembership(
        groupId: string,
        userId: string
    ): Promise<void> {
        await API.delete<void>(`${this.url}/${groupId}/members/${userId}`);
    }
}
