import React from "react";
import { FileUpload } from "Components";
import { UPLOAD_FLOW_DATA } from "Constants/constants";
import { FlowData } from "types";
import { formatDate, isNilOrEmpty } from "utils/functions";
import CSVValidationErrorDisplay from "Components/CSVValidationErrorDisplay";

interface ReportingSiteFlowDataManualUploadProps {
    reportingSiteId: string;
    flowData?: Partial<FlowData>;
    flowDataUpdated: (id: string, flowData: FlowData) => void;
}

const ReportingSiteFlowDataManualUpload = ({
    reportingSiteId,
    flowData = {},
    flowDataUpdated,
}: ReportingSiteFlowDataManualUploadProps) => {
    return (
        <div>
            <div className={"pt-2"}>
                <b>Flow data</b>
            </div>

            <FileUpload
                onRemove={() => {
                    flowDataUpdated(reportingSiteId, null);
                }}
                onAppend={(fd: FlowData) => {
                    flowDataUpdated(reportingSiteId, fd);
                }}
                onOverwrite={(fd: FlowData) => {
                    flowDataUpdated(reportingSiteId, fd);
                }}
                fileAzureId={flowData.latestUpdatedFileAzureId}
                parentId={reportingSiteId}
                type={UPLOAD_FLOW_DATA}
                defaultValue={flowData.latestFileName}
            />

            <div>
                Record start: {formatDate(new Date(flowData.start))}
                &nbsp;&nbsp;Record end: {formatDate(new Date(flowData.end))}
            </div>

            {!isNilOrEmpty(flowData.validationError) && (
                <CSVValidationErrorDisplay error={flowData.validationError} />
            )}
        </div>
    );
};

export default ReportingSiteFlowDataManualUpload;
