import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ID_ATTRIBUTE, ORM_COMPLIANCE_DATA } from "Constants/constants";

export default class ComplianceDataORM extends BaseORMModel {}

ComplianceDataORM.modelName = ORM_COMPLIANCE_DATA;
ComplianceDataORM.fields = {
    [ID_ATTRIBUTE]: attr(),

    start: attr(),
    end: attr(),

    latestUpdatedFileAzureId: attr(),
    latestFileName: attr(),
};
