import React, { useState } from "react";
import styles from "./GlobalSuccessParams.module.scss";
import {
    EVENT_LOADER_DISPLAY,
    EVENT_RELOAD_SCENARIO,
} from "Constants/constants";
import { EventEmitter } from "Components/EventEmitter";
import { Modal } from "react-bootstrap";
import OverlayButton from "Components/Button/OverlayButton";
import Button from "Components/Button";
import ScenarioService from "services/scenario.service";

interface UpdateSuccessWarningProps {
    scenarioId: string;
}

const UpdateSuccessWarning = ({ scenarioId }: UpdateSuccessWarningProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updateSuccess = async () => {
        handleClose();

        EventEmitter.dispatch(EVENT_LOADER_DISPLAY, {
            show: true,
            message: `Please wait until all partial success tables have been updated`,
        });

        await ScenarioService.replaceFlowRulesTablesWithGlobalSetting(
            scenarioId
        );

        EventEmitter.dispatch(EVENT_LOADER_DISPLAY, {
            show: false,
        });

        EventEmitter.dispatch(EVENT_RELOAD_SCENARIO);
    };

    return (
        <>
            <OverlayButton
                tooltip="Overwrite rule level partial success paramaters with
                        default partial success paramaters"
                tooltipPosition="right"
                className={`${styles["update-button"]} btn mt-1 mb-3 mr-3`}
                onClick={handleShow}
            >
                Replace local parameters with default
            </OverlayButton>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    This will replace all customised partial success tables for
                    all flow rule types and cannot be undone.
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>

                    <Button variant="primary" onClick={updateSuccess}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UpdateSuccessWarning;
