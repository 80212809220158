import {
    ApplicationInsights,
    DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import Config, { EflowEnvironment } from "Config";

class EflowTelemetry {
    private client: ApplicationInsights = null;

    public start(): void {
        if (Config.environment === EflowEnvironment.DEVELOPMENT) {
            return;
        }

        this.client = new ApplicationInsights({
            config: {
                connectionString: Config.ai.connectionString,
                distributedTracingMode: DistributedTracingModes.AI,
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                disableCorrelationHeaders: false,
                enableResponseHeaderTracking: true,
                enableAutoRouteTracking: true,
                correlationHeaderExcludedDomains: [Config.auth0.audience],
            },
        });

        this.client.loadAppInsights();
    }

    public setUser(userId: string): void {
        this.client?.setAuthenticatedUserContext(userId, null, true);
    }

    public clearUser(): void {
        this.client?.clearAuthenticatedUserContext();
    }
}

export default new EflowTelemetry();
