import { fk, many, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_SUCCESS_TABLE,
    ORM_PARTIAL_SUCCESS_DATA,
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
} from "Constants/constants";

export default class SuccessTableORM extends BaseORMModel {}

SuccessTableORM.modelName = ORM_SUCCESS_TABLE;
SuccessTableORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    type: attr(),
    flowRuleId: fk({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "tables",
    }),
    records: many({
        to: ORM_PARTIAL_SUCCESS_DATA,
        relatedName: "tables",
    }),
};
