import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ORM_REGION, ID_ATTRIBUTE } from "Constants/constants";

export default class RegionORM extends BaseORMModel {}

RegionORM.modelName = ORM_REGION;
RegionORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    system: attr(),
    deleted: attr(),
};
