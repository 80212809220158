import React, { useContext } from "react";
import { useState } from "react";
import { Scenario } from "types";

interface ScenarioContextProps {
    scenario: Scenario;
    markDirty: (id: string, dirty: boolean) => void;
    hasPendingSave: boolean;
}

export const ScenarioContext = React.createContext<ScenarioContextProps | null>(
    null
);

export const useScenario = () => useContext(ScenarioContext);

interface ScenarioProviderOptions {
    children: React.ReactElement;
    scenario?: Scenario;
}

export const ScenarioProvider = ({
    scenario,
    children,
}: ScenarioProviderOptions) => {
    const [dirtyComponents, setDirtyComponents] = useState<string[]>([]);

    const markDirty = (id: string, dirty: boolean) => {
        if (dirty) {
            setDirtyComponents((state) => {
                const next = !state.includes(id) ? [...state, id] : state;

                return next;
            });
        } else {
            setDirtyComponents((state) => {
                const next = state.filter((s) => s !== id);

                return next;
            });
        }
    };

    return (
        <ScenarioContext.Provider
            value={{
                scenario: scenario,
                markDirty,
                hasPendingSave: dirtyComponents.length > 0,
            }}
        >
            {children}
        </ScenarioContext.Provider>
    );
};
