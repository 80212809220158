import React, { useState, useEffect } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faDownload } from "@fortawesome/pro-light-svg-icons";
import {
    UPLOAD_FLOW_DATA,
    UPLOAD_APPEND,
    UPLOAD_OVERWRITE,
    UPLOAD_COMPLIANCE,
    UPLOAD_CLIMATE,
    UPLOAD_SCENARIO_SYSTEM_CLIMATE,
    UPLOAD_SCENARIO_REPORTING_SITE_FLOW_DATA,
    UPLOAD_SCENARIO_REPORTING_SITE_COMPLIANCE_DATA,
} from "Constants/constants";
import styles from "./FileUpload.module.scss";
import { guid } from "utils/functions";
import AzureBlobService from "services/azure.blob.service";
import FlowDataService from "services/flow-data-service";
import ComplianceDataService from "services/compliance-data-service";
import {
    ClimateDataService,
    ReportingSiteService,
    SystemService,
} from "services";
import { ClimateData, ComplianceData, FlowData } from "types";
import { TimeSeriesUploadType } from "services/types";
import ScenarioService from "services/scenario.service";

interface FileUploadProps {
    type: string;
    fileAzureId: string;
    defaultValue: string;
    onRemove?: any;
    onAppend?: any;
    onOverwrite?: any;
    parentId: string;
}

const FileUpload = ({
    parentId,
    type,
    defaultValue,
    fileAzureId,
    onRemove = null,
    onAppend = null,
    onOverwrite = null,
}: FileUploadProps) => {
    const [showError, setShowError] = useState(false);
    const [file, setFile] = useState<Object>({});
    const [uploadPercent, SetUploadPercent] = useState(0);
    const [fileName, setFileName] = useState(defaultValue);
    const [sFileAzureId, setSFileAuzreId] = useState(fileAzureId);

    const [fileControl, setFileControl] = useState(guid());

    useEffect(() => {
        setSFileAuzreId(fileAzureId);
    }, [fileAzureId]);

    useEffect(() => {
        setFileName(defaultValue);
    }, [defaultValue]);

    const checkFileType = (e) => {
        setFile(e.target.files);

        if (e.target.files && e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        }
    };

    const handleRefresh = (event) => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
    };

    // Remove file from the file upload
    // The idName is the id of the file upload id
    const removeFile = async () => {
        switch (type) {
            case UPLOAD_FLOW_DATA:
                await ReportingSiteService.deleteFlowData(parentId);
                break;
            case UPLOAD_COMPLIANCE:
                await ReportingSiteService.deleteComplianceData(parentId);
                break;
            case UPLOAD_CLIMATE:
                await SystemService.deleteClimateData(parentId);
                break;
            case UPLOAD_SCENARIO_REPORTING_SITE_FLOW_DATA:
                await ScenarioService.deleteScenarioReportingSiteFlowData(
                    parentId
                );
                break;
            case UPLOAD_SCENARIO_REPORTING_SITE_COMPLIANCE_DATA:
                await ScenarioService.deleteScenarioReportingSiteComplianceData(
                    parentId
                );
                break;
            case UPLOAD_SCENARIO_SYSTEM_CLIMATE:
                await ScenarioService.deleteScenarioSystemClimateData(parentId);
                break;
        }

        setFileName("");

        setFileControl(guid());

        if (onRemove) {
            onRemove();
        }

        setFile([]);

        setShowError(false);
    };

    const downloadFile = async () => {
        await AzureBlobService.downloadBlob(sFileAzureId, fileName);
    };

    const UploadFile = async (
        method: string
    ): Promise<FlowData | ComplianceData | ClimateData> => {
        if (!file[0]) {
            return null;
        }

        let result: FlowData | ComplianceData | ClimateData = null;

        window.addEventListener("beforeunload", handleRefresh);

        const azureID = await AzureBlobService.uploadBlob(
            file[0],
            (progress) => {
                SetUploadPercent(Math.round(progress) * 0.9);
            }
        );

        window.removeEventListener("beforeunload", handleRefresh);

        try {
            switch (type) {
                case UPLOAD_FLOW_DATA: {
                    result = await FlowDataService.uploadFlowDataManual(
                        parentId,
                        azureID,
                        method as TimeSeriesUploadType,
                        file[0].name
                    );

                    SetUploadPercent(101);
                    setSFileAuzreId(azureID);

                    break;
                }

                case UPLOAD_COMPLIANCE: {
                    result = await ComplianceDataService.uploadComplianceData(
                        parentId,
                        azureID,
                        method as TimeSeriesUploadType,
                        file[0].name
                    );

                    SetUploadPercent(101);

                    setSFileAuzreId(azureID);

                    break;
                }

                case UPLOAD_CLIMATE: {
                    result = await ClimateDataService.uploadClimateData(
                        parentId,
                        azureID,
                        method as TimeSeriesUploadType,
                        file[0].name
                    );

                    SetUploadPercent(101);
                    setSFileAuzreId(azureID);

                    break;
                }

                case UPLOAD_SCENARIO_REPORTING_SITE_FLOW_DATA: {
                    result = await FlowDataService.uploadScenarioFlowData(
                        parentId,
                        azureID,
                        method as TimeSeriesUploadType,
                        file[0].name
                    );

                    SetUploadPercent(101);
                    setSFileAuzreId(azureID);

                    break;
                }

                case UPLOAD_SCENARIO_REPORTING_SITE_COMPLIANCE_DATA: {
                    result =
                        await ComplianceDataService.uploadScenarioComplianceData(
                            parentId,
                            azureID,
                            method as TimeSeriesUploadType,
                            file[0].name
                        );

                    SetUploadPercent(101);

                    setSFileAuzreId(azureID);

                    break;
                }

                case UPLOAD_SCENARIO_SYSTEM_CLIMATE: {
                    result = await ClimateDataService.uploadScenarioClimateData(
                        parentId,
                        azureID,
                        method as TimeSeriesUploadType,
                        file[0].name
                    );

                    SetUploadPercent(101);
                    setSFileAuzreId(azureID);

                    break;
                }
            }
        } catch (error) {
            console.error(error);
        }

        return result;
    };

    return (
        <Form>
            <Form.Group>
                <input
                    type="file"
                    id={`selectedFile-${type}-${parentId}`}
                    key={fileControl}
                    multiple={false}
                    accept={".csv"}
                    onChange={(e) => checkFileType(e)}
                    style={{ display: "none" }}
                />
                <input
                    type="button"
                    value="Choose file"
                    onClick={() =>
                        document
                            .getElementById(`selectedFile-${type}-${parentId}`)
                            .click()
                    }
                />

                <span className={styles["file-upload-file-name"]}>
                    {fileName}
                </span>

                {sFileAzureId && (
                    <>
                        <FontAwesomeIcon
                            className={styles["close-icon"]}
                            icon={faTimesCircle}
                            onClick={() => removeFile()}
                        />{" "}
                        &nbsp;
                    </>
                )}

                {sFileAzureId && (
                    <>
                        <FontAwesomeIcon
                            className={styles["close-icon"]}
                            icon={faDownload}
                            onClick={() => {
                                downloadFile();
                            }}
                        />{" "}
                        &nbsp;
                    </>
                )}

                {file && file[0] && (
                    <>
                        <span
                            className={`${styles["append-overwrite"]} p-2`}
                            onClick={async () => {
                                const result = await UploadFile(UPLOAD_APPEND);

                                onAppend && onAppend(result);
                            }}
                        >
                            Append
                        </span>
                        &nbsp;
                    </>
                )}

                {file && file[0] && (
                    <span
                        className={`${styles["append-overwrite"]} p-2`}
                        onClick={async () => {
                            const result = await UploadFile(UPLOAD_OVERWRITE);

                            onOverwrite && onOverwrite(result);
                        }}
                    >
                        Overwrite
                    </span>
                )}

                {showError && <div>Incorrect file type</div>}

                {uploadPercent > 0 && uploadPercent <= 100 && (
                    <div className={styles["file-upload-progress-bar"]}>
                        <ProgressBar animated now={uploadPercent} />
                    </div>
                )}
            </Form.Group>
        </Form>
    );
};

export default FileUpload;
