import React, { useState, MouseEvent, useEffect } from "react";
import { Accordian, Toast } from "Components";
import { EVENT_SPATIAL_SETUP_ENTITY_REMOVE } from "../../Constants/constants";
import { FlowDataService, RegionService } from "../../services";
import { CreateRegionRequest } from "../../services/types";
import { Region } from "types";
import AccordianSystems from "./AccordianSystems";
import OverlayButton from "../Button/OverlayButton";
import AccodianTitle from "./AccordianTitle";
import AccordianDeleteModal from "./AccordianDeleteModal";
import { EventEmitter } from "../EventEmitter";
import { runWithLoader } from "Components/FullScreenLoader/FullScreenLoader";

interface AccordianRegionsProps {
    groupId: string;
}

const AccordianRegions = (props: AccordianRegionsProps) => {
    const { groupId } = props;

    const [regions, setRegions] = useState<Region[]>([]);

    useEffect(() => {
        async function setUp() {
            try {
                if (!groupId) {
                    return;
                }

                const regions = await RegionService.getRegions(groupId);

                setRegions(regions);
            } catch (error) {
                console.error(error);
                Toast.error("Error getting system setup");
            }
        }
        setUp();
    }, [groupId]);

    const addRegion = async (): Promise<void> => {
        try {
            const request: CreateRegionRequest = {
                name: "Untitled Region",
            };

            const newRegion = await RegionService.createRegion(
                groupId,
                request
            );

            setRegions([...regions, newRegion]);

            Toast.success("Successfully created region");
        } catch (err) {
            Toast.error("Error creating region <br/>");
        }
    };

    const removeRegion = (event: MouseEvent, region: Region) => {
        event.stopPropagation();

        const doDelete = async () => {
            try {
                await RegionService.removeRegion(region.id);

                const next = regions.filter((x) => x.id !== region.id);

                setRegions(next);
            } catch (error) {
                Toast.error("Error deleting region");
            }
        };

        EventEmitter.dispatch(EVENT_SPATIAL_SETUP_ENTITY_REMOVE, {
            title: `Remove ${region.name}`,
            onDelete: doDelete,
        });
    };

    const updateRegionName = async (region: Region, name: string) => {
        try {
            await RegionService.updateRegion(region.id, { name: name });
        } catch (error) {
            Toast.error("Error updating region");
        }
    };

    const refreshFlowData = async () => {
        await Toast.wrap(
            async () => {
                await runWithLoader(async () => {
                    await FlowDataService.refreshAutomaticFlowData(groupId);
                    window.location.reload();
                }, "Please wait while we refresh all flow data. This could take a while.");
            },
            "Successfully refreshed flow data",
            "Failed to refresh flow data"
        );
    };

    return (
        <div>
            <AccordianDeleteModal />

            <div className="pt-2 pl-0 col-md-12">
                <OverlayButton
                    className="mb-2 mr-2"
                    tooltip="Add a new region to the system"
                    tooltipPosition="right"
                    onClick={async () => {
                        await addRegion();
                    }}
                >
                    Add region
                </OverlayButton>

                <OverlayButton
                    className="mb-2 mr-2"
                    tooltip="Refresh all flow data with automatic upload sources"
                    tooltipPosition="right"
                    onClick={refreshFlowData}
                >
                    Refresh All Automatic Flow Data
                </OverlayButton>
            </div>

            {regions?.map((region) => {
                return (
                    <Accordian
                        key={region.id}
                        expandAtStart={false}
                        stylingHeader={"region-header"}
                        title={
                            <AccodianTitle
                                id={region.id}
                                name={region.name}
                                onUpdate={async (name) => {
                                    await updateRegionName(region, name);
                                }}
                                onDelete={(e) => removeRegion(e, region)}
                            />
                        }
                    >
                        <AccordianSystems region={region} />
                    </Accordian>
                );
            })}
        </div>
    );
};

export default AccordianRegions;
