import { attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
    ORM_OVER_SUPPLY_FLOW_RULE,
} from "Constants/constants";

export default class OversupplyFlowRuleORM extends BaseORMModel {}

OversupplyFlowRuleORM.modelName = ORM_OVER_SUPPLY_FLOW_RULE;
OversupplyFlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRuleId: oneToOne({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "oversupply",
    }),
};
