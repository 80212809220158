import React from "react";
import { PartialTableData } from "types";
import EflowChart from "Components/Charts/EflowChart";
import { ChartDataset, ChartOptions } from "chart.js";
import { orderBy } from "lodash";
import { PARTIAL_SUCCESS_CHART_LABELS } from "Constants/constants";

interface PartialSuccessTableChartProps {
    tableName: string;
    records: PartialTableData[];
}

const PartialSuccessTableChart = ({
    tableName,
    records,
}: PartialSuccessTableChartProps) => {
    const orderedRecords = orderBy(records, (x) => x.target, "asc");

    const datasets: ChartDataset[] = [
        {
            label: tableName,
            data: orderedRecords.map((record) => {
                return {
                    x: record.target,
                    y: record.success,
                };
            }),
            borderColor: "#08bfdd",
            backgroundColor: "#08bfdd",
            clip: 5,
        },
    ];

    const chartOptions: ChartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                min: 0,
                max: 100,
                grid: { display: false, drawBorder: false },
                ticks: {
                    display: false,
                    stepSize: 20,
                },
                title: { text: "Target %", display: true },
            },
            y: {
                min: 0,
                max: 100,
                grid: { display: false, drawBorder: false },
                ticks: {
                    display: false,
                    stepSize: 20,
                    autoSkip: true,
                },
                title: { text: "Success %", display: true },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <EflowChart
            type="line"
            labels={PARTIAL_SUCCESS_CHART_LABELS}
            data={datasets}
            options={chartOptions}
            width={300}
        />
    );
};

export default PartialSuccessTableChart;
