import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_CUSTOM_PARAM,
    ORM_CUSTOM_FLOW_RULE,
} from "Constants/constants";

export default class CustomRuleParamORM extends BaseORMModel {}

CustomRuleParamORM.modelName = ORM_CUSTOM_PARAM;
CustomRuleParamORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    description: attr(),
    climate: attr(),
    flowRuleId: fk({
        to: ORM_CUSTOM_FLOW_RULE,
        as: "flowRule",
        relatedName: "params",
    }),
};
