import { attr, oneToOne } from "redux-orm";

import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
    ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
} from "Constants/constants";

export default class FreshesMultiYearFlowRuleORM extends BaseORMModel {}

FreshesMultiYearFlowRuleORM.modelName = ORM_FRESHES_MULTI_YEAR_FLOW_RULE;
FreshesMultiYearFlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRuleId: oneToOne({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "freshesMultiYear",
    }),
    maxTime: attr(),
};
