import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavTab.module.scss";

const NavTab = () => {
    return (
        <div className={`${styles["header-tab"]} row`}>
            <NavLink
                activeClassName={styles["selected"]}
                style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                }}
                to={"/system-setup"}
            >
                <div className={`col-4 ${styles["unselected"]}`}>
                    <div>System Setup</div>
                </div>
            </NavLink>

            <NavLink
                activeClassName={styles["selected"]}
                style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                }}
                to={"/configure-scenario"}
            >
                <div className={`col-4 ${styles["unselected"]}`}>
                    <div>Configure Scenario</div>
                </div>
            </NavLink>

            <NavLink
                activeClassName={styles["selected"]}
                style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                }}
                to={"/scenario-explore"}
            >
                <div className={`col-4 ${styles["unselected"]}`}>
                    <div>Explore results</div>
                </div>
            </NavLink>

            <NavLink
                activeClassName={styles["selected"]}
                style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                }}
                to={"/scenario-library"}
                isActive={(match, location) => {
                    if (
                        location.pathname === "/scenario-library" ||
                        location.pathname === "/"
                    ) {
                        return true;
                    }
                }}
            >
                <div className={`col-4 ${styles["unselected"]}`}>
                    <div>Scenario Library</div>
                </div>
            </NavLink>
        </div>
    );
};

export default NavTab;
