interface BaseEntity {
    id: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface Region extends BaseEntity {
    name: string;
    description: string;
    systems?: Array<System>;
    deleted: boolean;
}

export interface System extends BaseEntity {
    name: string;
    regionId: string;
    description: string;
    type: string;
    parentSystemId: string;
    deleted: boolean;
    subSystems: Array<System>;
    reportingSites: Array<ReportingSite>;
    climateId: string;
    climateData?: ClimateData;
}

export interface ReportingSite extends BaseEntity {
    name: string;
    description: string;
    gaugeNumber?: string;
    lag?: number;
    systemId?: string;
    deleted?: boolean;
    flowData?: FlowData;
    complianceData?: ComplianceData;
}

export interface ScenarioResult extends BaseEntity {
    resultAzureFileId?: string;
    scenarioId: string;
    resultMetaData: any;
}

export interface GlobalSetting extends BaseEntity {
    description?: string;
    scenarioId: string;
    flowRules: any[];
}

export interface RuleParam extends BaseEntity {
    flowRuleId: string;
    climate: ClimateType;
}

export interface CustomRuleParam extends RuleParam {
    description?: string;
}

export interface LowflowParam extends RuleParam {
    threshold?: number;
    duration?: number;
    durationType?: string;
}

export interface OversupplyParam extends RuleParam {
    threshold?: number;
    duration?: number;
    durationType?: string;
}

export interface FreshesMultiYearParam extends RuleParam {
    threshold?: number;
    minDuration?: number;
    numEvents?: number;
    independence?: number;
}

export interface FreshesParam extends RuleParam {
    threshold?: number;
    minDuration?: number;
    numEvents?: number;
    independence?: number;
}

export interface CustomFlowRule extends BaseEntity {
    flowRuleId: string;
    params: CustomRuleParam[];
}

export interface LowflowFlowRule extends BaseEntity {
    flowRuleId: string;
    params: LowflowParam[];
}

export interface OversupplyFlowRule extends BaseEntity {
    flowRuleId: string;
    params: OversupplyParam[];
}

export interface FreshesMultiYearFlowRule extends BaseEntity {
    flowRuleId: string;
    maxTime?: number;
    params: FreshesMultiYearParam[];
}

export interface FreshesFlowRule extends BaseEntity {
    flowRuleId: string;
    params: FreshesParam[];
}

export interface FlowRule extends BaseEntity {
    startDay: number;
    endDay: number;
    startMonth: Month;
    endMonth: Month;
    reportingLevels?: string[];
    name?: string;
    description?: string;
    type: FlowRuleType;
    returnPeriod?: number;
    custom?: CustomFlowRule;
    freshes?: FreshesFlowRule;
    freshesMultiYear?: FreshesMultiYearFlowRule;
    lowflow?: LowflowFlowRule;
    oversupply?: OversupplyFlowRule;
    tables: PartialTable[];
}

export interface ScenarioReportingSite extends BaseEntity {
    scenarioSystemId: string;
    reportingSiteId: string;
    reportingSite?: ReportingSite;
    scenarioId: string;
    flowRules: FlowRule[];
    flowData?: FlowData;
    complianceData?: ComplianceData;
}

export interface ScenarioSystem extends BaseEntity {
    scenarioRegionId: string;
    parentSystemId?: string;
    systemId: string;
    system?: System;
    scenarioId: string;
    scenarioSubSystems: ScenarioSystem[];
    scenarioReportingSites: ScenarioReportingSite[];
    climateData?: ClimateData;
    flowRules?: FlowRule[];
}

export interface ScenarioRegion extends BaseEntity {
    scenarioId: string;
    regionId: string;
    region?: Region;
    scenarioSystems?: ScenarioSystem[];
}

export interface Scenario extends BaseEntity {
    name: string;
    groupId: string;
    author: string;
    description: string;
    startMonth?: Month;
    startYear?: number;
    endYear?: number;
    globalSettingId: string;
    globalSetting: GlobalSetting;
    result?: ScenarioResult;
    scenarioRegions?: ScenarioRegion[];
    scenarioSystems?: ScenarioSystem[];
    scenarioReportingSites?: ScenarioReportingSite[];
}

export enum Month {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export enum ClimateType {
    WET = "wet",
    AVERAGE = "avg",
    DRY = "dry",
    VERY_DRY = "vdry",
    DROUGHT = "drought",
}

export interface ClimaticCondition extends BaseEntity {
    startMonth: Month;
    climate: ClimateType;
}

export enum FlowRuleType {
    CUSTOM = "custom",
    FRESHES = "freshes",
    FRESHES_MULTI_YEAR = "freshesMultiYear",
    LOW_FLOW = "lowflow",
    OVERSUPPLY = "oversupply",
}

export enum PartialSuccessTableType {
    MAGNITUDE = "magnitude",
    DURATION = "duration",
    COUNT = "count",
    INDEPENDENCE = "independence",
    MAX_TIME = "MaxTime",
}

export enum CSVValidationError {
    NON_CONTINUOUS_DATE = "non_continuous_date",
}

export interface PartialTableData extends BaseEntity {
    target: number;
    success: number;
}

export interface PartialTable extends BaseEntity {
    name: string;
    sourceTableId?: string;
    type: PartialSuccessTableType;
    records: PartialTableData[];
}

export interface AxiosResponse<T = any> {
    data: T;
}

export enum DataBankType {
    Compliance = "compliance",
    Flow = "flow",
}

export interface DataBank extends BaseEntity {
    time: number;
    timeInDate: string;
    timeSpan: number;
    key: DataBankType;
    value: any;
}

export interface BaseTimeSeriesData extends BaseEntity {
    start?: string;
    end?: string;
    latestUpdatedFileAzureId?: string;
    latestFileName?: string;
    records?: DataBank[];
}

export interface FlowData extends BaseTimeSeriesData {
    uploadType: FlowDataUploadType;
    uploadSource?: FlowDataUploadSource;
    validationError?: CSVValidationError;
}

export interface ComplianceData extends BaseTimeSeriesData {
    validationError?: CSVValidationError;
}

export interface ClimateData extends BaseTimeSeriesData {}

export interface Group extends BaseEntity {
    name: string;
    description?: string;
    memberships: Membership[];
}

export interface RoleOperation extends BaseEntity {
    name: string;
    description: string;
}

export interface Role extends BaseEntity {
    name: string;
    description: string;
    operations: RoleOperation[];
}

export interface Membership extends BaseEntity {
    groupId: string;
    group: Group;
    userId: string;
    user?: AppUser;
    roles?: Role[];
}

export interface AppUser extends BaseEntity {
    firstName: string;
    lastName: string;
    email: string;
    auth0UserId: string;
    roles: Role[];
    memberships?: Membership[];
    activeMembershipId?: string;
}

export enum TokenType {
    REGISTER = "register",
}

export enum ReportingLevel {
    TIER_1A = "tier_1a",
    TIER_1B = "tier_1b",
    TIER_2 = "tier_2",
    EFLOW_REC = "eflow",
}

export enum FlowDataUploadType {
    MANUAL = "manual",
    AUTOMATIC = "automatic",
}

export enum FlowDataUploadSource {
    BOM = "bom",
    QLD = "qld",
    VIC = "vic",
    NSW = "nsw",
}

export enum ComputationTaskStatus {
    RUNNING = "running",
    COMPLETED = "completed",
    FAILED = "failed",
}

export interface ComputationTaskError {
    code: string;
    message: string;
}

export interface ComputationTaskModel {
    id: string;
    modelId: string;
    status: ComputationTaskStatus;
    success: boolean;
    error?: ComputationTaskError;
    lastRunAt: Date;
}

export interface ComputationResult {
    task: ComputationTaskModel;
    success: boolean;
    failure: ComputationFailure;
}

interface ComputationFailure {
    code: string;
    message: string;
}