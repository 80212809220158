import React, { useState } from "react";
import { Modal } from "Components";
import { useEvent } from "hooks/useEvent";
import { EVENT_SPATIAL_SETUP_ENTITY_REMOVE } from "Constants/constants";

type ModalDeleteEventInfo = {
    title: string;
    onDelete: () => void;
};

const AccordianDeleteModal = () => {
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState<ModalDeleteEventInfo>(null);

    useEvent(
        EVENT_SPATIAL_SETUP_ENTITY_REMOVE,
        (info: ModalDeleteEventInfo) => {
            setInfo(info);
            setShow(true);
        }
    );

    return (
        <Modal
            show={show}
            onCancel={() => {
                setShow(false);
            }}
            title={info?.title}
            onComplete={() => {
                info?.onDelete();

                setInfo(null);
                setShow(false);
            }}
        />
    );
};

export default AccordianDeleteModal;
