import { attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
    ORM_FRESHES_FLOW_RULE,
} from "Constants/constants";

export default class FreshesFlowRuleORM extends BaseORMModel {}

FreshesFlowRuleORM.modelName = ORM_FRESHES_FLOW_RULE;
FreshesFlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRuleId: oneToOne({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "freshes",
    }),
};
