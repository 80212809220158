import Tooltip from "Components/Tooltip";
import React from "react";
import { Placement } from "react-bootstrap/Overlay";
import Button from ".";

type OverlayButtonProps = {
    tooltip: string;
    tooltipPosition?: Placement;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const OverlayButton = (props: OverlayButtonProps) => {
    const { tooltip, tooltipPosition = "bottom", ...rest } = props;

    return (
        <Tooltip text={tooltip} placement={tooltipPosition}>
            <Button {...rest} />
        </Tooltip>
    );
};

export default OverlayButton;
