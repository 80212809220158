import React from "react";
import { FlowData, FlowDataUploadSource } from "types";
import styles from "./SystemSetupReportingSite.module.scss";
import { useState } from "react";
import Button from "Components/Button";
import {
    AzureBlobService,
    FlowDataService,
    ReportingSiteService,
} from "services";
import { isNil } from "lodash";
import { formatDate, isNilOrEmpty } from "utils/functions";
import { Toast } from "Components";
import Loader from "Components/Loader/Loader";
import CSVValidationErrorDisplay from "Components/CSVValidationErrorDisplay";

interface ReportingSiteFlowDataAutomaticUploadProps {
    reportingSiteId: string;
    gaugeNumber: string;
    flowData?: Partial<FlowData>;
    flowDataUpdated: (id: string, flowData: FlowData) => void;
}

const ReportingSiteFlowDataAutomaticUpload = ({
    reportingSiteId,
    gaugeNumber,
    flowData,
    flowDataUpdated,
}: ReportingSiteFlowDataAutomaticUploadProps) => {
    const hasFlowData = !isNil(flowData?.id);
    const hasGaugeNumber = !isNil(gaugeNumber) && gaugeNumber.length > 0;

    const [loading, setLoading] = useState<boolean>(false);

    const handleUpload = async () => {
        try {
            setLoading(true);

            const fd = await FlowDataService.uploadFlowDataAutomatic(
                reportingSiteId,
                FlowDataUploadSource.BOM,
            );

            if (isNil(fd)) {
                Toast.warn("Sorry we could not find any data for this site.");
                return;
            }

            flowDataUpdated(reportingSiteId, fd);

            Toast.success("Succesfully uploaded data from source");
        } catch (error) {
            Toast.error("Failed to upload data from source");
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        await AzureBlobService.downloadBlob(
            flowData.latestUpdatedFileAzureId,
            flowData.latestFileName,
        );
    };

    const handleDelete = async () => {
        try {
            await ReportingSiteService.deleteFlowData(reportingSiteId);

            flowDataUpdated(reportingSiteId, null);

            Toast.success("Succesfully removed flow data");
        } catch (error) {
            Toast.success("Failed to remove flow data");
        }
    };

    return (
        <div className={styles["automatic-upload"]}>
            {!hasGaugeNumber && <div>Enter a gauge number above</div>}

            {hasGaugeNumber && (
                <>
                    <div className={styles["source-select"]}>
                        <Button disabled={loading} onClick={handleUpload}>
                            {!hasFlowData ? "Fetch" : "Refresh"}
                        </Button>

                        <div className={styles["source-select--actions"]}>
                            {hasFlowData && (
                                <>
                                    <Button
                                        disabled={loading}
                                        onClick={handleDownload}
                                    >
                                        Download
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        onClick={handleDelete}
                                    >
                                        Remove
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>

                    {hasFlowData && !loading && (
                        <div>
                            <span>{flowData?.latestFileName}</span>

                            <div>
                                Record start:{" "}
                                {formatDate(new Date(flowData?.start))}
                                &nbsp;&nbsp;Record end:{" "}
                                {formatDate(new Date(flowData?.end))}
                            </div>

                            {!isNilOrEmpty(flowData?.validationError) && (
                                <CSVValidationErrorDisplay
                                    error={flowData?.validationError}
                                />
                            )}
                        </div>
                    )}

                    {loading && (
                        <Loader fullpage={false} width={35} position="left" />
                    )}
                </>
            )}
        </div>
    );
};

export default ReportingSiteFlowDataAutomaticUpload;