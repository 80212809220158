export const SYSTEMS_FILTER = {
    include: [
        {
            relation: "subSystems",
            scope: {
                where: {
                    deleted: false,
                },
            },
        },
        {
            relation: "reportingSites",
            scope: {
                where: {
                    deleted: false,
                },
                include: [
                    {
                        relation: "flowData",
                    },
                    {
                        relation: "complianceData",
                    },
                ],
            },
        },
        { relation: "climateData" },
    ],
};
