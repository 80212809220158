import { store } from "../redux";
import generateORMActionName from "redux/reducers/orm.action.gen";
import {
    CREATE_ACTION,
    ORM_SCENARIO,
    ID_ATTRIBUTE,
    PARTIAL_RULE_TABLE_DEFINITION,
    FLOW_RULE_DEFAULT,
    CLIMATE_VALS,
    FLOW_RULE_MAP,
    FLOW_RULES,
    REPORTING_LEVELS,
    DELETE_ADVANCE_ACTION,
    DEFAULT_FLOW_RULE_NAME,
    DEFAULT_CUSTOM_FLOW_RULE_NAME,
    ORM_SUCCESS_TABLE,
    ORM_PARTIAL_SUCCESS_DATA,
    ORM_FLOW_RULE,
    ORM_CUSTOM_FLOW_RULE,
    ORM_CUSTOM_PARAM,
    ORM_FRESHES_FLOW_RULE,
    ORM_FRESHES_PARAM,
    ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
    ORM_FRESHES_MULTI_YEAR_PARAM,
    ORM_LOW_FLOW_FLOW_RULE,
    ORM_LOW_FLOW_PARAM,
    ORM_OVER_SUPPLY_PARAM,
    ORM_OVER_SUPPLY_FLOW_RULE,
    ORM_GLOBAL_SETTING,
    ORM_SCENARIO_REGION,
    ORM_SCENARIO_SYSTEM,
    ORM_SCENARIO_REPORTING_SITE,
} from "Constants/constants";
import _, { isNil } from "lodash";
import ScenarioService from "../services/scenario.service";
import {
    ClimateType,
    FlowRule,
    FlowRuleType,
    FreshesFlowRule,
    FreshesMultiYearFlowRule,
    LowflowFlowRule,
    OversupplyFlowRule,
    PartialTable,
    RuleParam,
    Scenario,
} from "types";

export const formatDate = (date: Date | string) => {
    if (!date) {
        return "";
    }

    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = "0" + month;
    }

    if (day.length < 2) {
        day = "0" + day;
    }

    if (day === "NaN") {
        return "";
    } else {
        return [day, month, year].join("/");
    }
};

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
    );
};

export const successTableGen = (rule: FlowRuleType): PartialTable[] => {
    return (PARTIAL_RULE_TABLE_DEFINITION[rule] ?? []).map((nameSettings) => {
        return {
            [ID_ATTRIBUTE]: guid(),
            name: nameSettings.label,
            type: nameSettings.state,
            records: [0, 25, 50, 75, 100].map((v) => {
                return {
                    [ID_ATTRIBUTE]: guid(),
                    success: v,
                    target: v,
                };
            }),
        };
    });
};

export const ruleGen = (
    rule: FlowRuleType,
    climate: ClimateType
): RuleParam => {
    return {
        [ID_ATTRIBUTE]: guid(),
        climate,
        ...(!FLOW_RULE_MAP[rule].default ? {} : FLOW_RULE_MAP[rule].default),
    };
};

export const ruleParamsGen = (
    flowRuleId: string,
    rule: FlowRuleType
):
    | LowflowFlowRule
    | FreshesFlowRule
    | OversupplyFlowRule
    | FreshesMultiYearFlowRule => {
    return {
        [ID_ATTRIBUTE]: guid(),
        flowRuleId: flowRuleId,
        params: CLIMATE_VALS.map((climate) => {
            return ruleGen(rule, climate.state);
        }),
    };
};

export const constructScenarioRegions = (scenario: Scenario): Scenario => {
    let {
        scenarioRegions = [],
        scenarioSystems = [],
        scenarioReportingSites = [],
        ...others
    } = scenario;

    scenarioRegions = scenarioRegions.filter((sRegion) => sRegion.region);
    scenarioSystems = scenarioSystems.filter((sSystem) => sSystem.system);
    scenarioReportingSites = scenarioReportingSites.filter(
        (srs) => srs.reportingSite
    );

    let scenarioRegionMap = _.keyBy(scenarioRegions, (d) => d[ID_ATTRIBUTE]);
    let scenarioSystemMap = _.keyBy(scenarioSystems, (d) => d[ID_ATTRIBUTE]);

    let regionSystemMap =
        _.groupBy(
            scenarioSystems.filter(
                (sSystem) =>
                    sSystem.parentSystemId === null ||
                    sSystem.parentSystemId === undefined ||
                    typeof sSystem.parentSystemId !== "string"
            ),
            (d) => d.scenarioRegionId
        ) ?? {};

    let parentSystemMap =
        _.groupBy(
            scenarioSystems.filter(
                (sSystem) =>
                    sSystem.parentSystemId !== null &&
                    sSystem.parentSystemId !== undefined &&
                    typeof sSystem.parentSystemId === "string"
            ),
            (d) => d.parentSystemId
        ) ?? {};

    let systemReportingSiteMap =
        _.groupBy(scenarioReportingSites, (d) => d.scenarioSystemId) ?? {};

    _.keys(scenarioRegionMap).forEach((regionId) => {
        const sRegion = scenarioRegionMap[regionId];

        if (sRegion && regionSystemMap[regionId]) {
            sRegion.scenarioSystems = regionSystemMap[regionId] ?? [];
        }
    });

    _.keys(scenarioSystemMap).forEach((systemId) => {
        const sSystem = scenarioSystemMap[systemId];

        if (sSystem && parentSystemMap[systemId]) {
            sSystem.scenarioSubSystems = parentSystemMap[systemId] ?? [];
        }

        if (sSystem && systemReportingSiteMap[systemId]) {
            sSystem.scenarioReportingSites =
                systemReportingSiteMap[systemId] ?? [];
        }
    });

    return {
        ...others,
        scenarioRegions,
    } as Scenario;
};

export const clearScenarioStores = () => {
    const slices = [
        ORM_SCENARIO,
        ORM_GLOBAL_SETTING,
        ORM_SCENARIO_REGION,
        ORM_SCENARIO_SYSTEM,
        ORM_SCENARIO_REPORTING_SITE,
        ORM_SUCCESS_TABLE,
        ORM_PARTIAL_SUCCESS_DATA,
        ORM_FLOW_RULE,
        ORM_CUSTOM_FLOW_RULE,
        ORM_CUSTOM_PARAM,
        ORM_FRESHES_FLOW_RULE,
        ORM_FRESHES_PARAM,
        ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
        ORM_FRESHES_MULTI_YEAR_PARAM,
        ORM_LOW_FLOW_FLOW_RULE,
        ORM_LOW_FLOW_PARAM,
        ORM_OVER_SUPPLY_FLOW_RULE,
        ORM_OVER_SUPPLY_PARAM,
    ];

    slices.forEach((slice) => {
        store.dispatch({
            type: generateORMActionName({
                slice: slice,
                actionName: DELETE_ADVANCE_ACTION,
            }),
            payload: {
                filter: {},
                cascade: true,
            },
        });
    });
};

export const loadScenario = async (scenarioId: string) => {
    clearScenarioStores();

    let scenario = await ScenarioService.getScenario(scenarioId);

    scenario = constructScenarioRegions(scenario);

    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO,
            actionName: CREATE_ACTION,
        }),
        payload: scenario ?? {},
    });

    return scenario;
};

export function isNilOrEmpty<T>(val: string | T[]) {
    return isNil(val) || val.length === 0;
}
