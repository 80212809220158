import React from "react";
import { Accordian } from "Components";
import styles from "./GlobalSuccessParams.module.scss";
import RuleTables from "./RuleTables";
import { FlowRule } from "types";
import Button from "Components/Button";
import { useState } from "react";
import { useScenario } from "Scenes/ConfigureScenario/ScenarioContext";

interface GlobalSuccessParamProps {
    label: string;
    flowRule: FlowRule;
    onFlowRuleUpdated: (flowRule: FlowRule) => void;
}

const GlobalSuccessParam = ({
    label,
    flowRule,
    onFlowRuleUpdated,
}: GlobalSuccessParamProps) => {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const { markDirty } = useScenario();

    const handleGlobalSettingUpdate = () => {
        onFlowRuleUpdated(flowRule);

        markDirty(flowRule.id, false);
        setIsDirty(false);
    };

    const handleTableUpdated = () => {
        markDirty(flowRule.id, true);

        setIsDirty(true);
    };

    return (
        <div className={`pr-0 ${styles["padding-right"]} col-12 mb-2`}>
            <Accordian
                expandAtStart={false}
                title={
                    <div
                        className={`d-flex flex-grow-1 ${styles["gsp--accordian-header"]}`}
                    >
                        <h4 className={styles["gsp--header-title"]}>{label}</h4>

                        {isDirty && (
                            <Button
                                className={styles["gsp--save-button"]}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    handleGlobalSettingUpdate();
                                }}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                }
                content={
                    <div className="row col-12 mr-0">
                        <RuleTables
                            flowRule={flowRule}
                            onTableUpdated={handleTableUpdated}
                        ></RuleTables>
                    </div>
                }
                styling={flowRule.type}
                stylingBody={`${flowRule.type}-body`}
                stylingHeader={`${flowRule.type}-header`}
            />
        </div>
    );
};

export default GlobalSuccessParam;
