import { URL } from "../Constants/api";
import API from "../api";
import {
    FlowDataReportingYearRange,
    RefreshAutomaticFlowDataRequest,
    TimeSeriesAutomaticUploadRequest,
    TimeSeriesManualUploadRequest,
    TimeSeriesUploadType,
    UploadFlowDataRequest,
} from "./types";
import { FlowData, FlowDataUploadSource, FlowDataUploadType } from "types";

export default class FlowDataService {
    static async uploadFlowDataManual(
        reportingSiteId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<FlowData> {
        const request: UploadFlowDataRequest<TimeSeriesManualUploadRequest> = {
            type: FlowDataUploadType.MANUAL,
            metadata: {
                azureId: blobId,
                fileName: fileName,
                type: type,
            },
        };

        const flowData = await API.post<FlowData>(
            `${URL.REPORTING_SITES}/${reportingSiteId}/flow-data/upload`,
            request
        );

        return flowData;
    }

    static async uploadFlowDataAutomatic(
        reportingSiteId: string,
        source: FlowDataUploadSource
    ): Promise<FlowData | null> {
        try {
            const request: UploadFlowDataRequest<TimeSeriesAutomaticUploadRequest> =
                {
                    type: FlowDataUploadType.AUTOMATIC,
                    metadata: {
                        source: source,
                    },
                };

            const flowData = await API.post<FlowData>(
                `${URL.REPORTING_SITES}/${reportingSiteId}/flow-data/upload`,
                request
            );

            return flowData;
        } catch (error) {
            if (
                API.isAxiosError(error) &&
                error.response?.data?.error?.code === "NO_RESULT"
            ) {
                return null;
            }

            throw error;
        }
    }

    static async refreshAutomaticFlowData(groupId: string): Promise<void> {
        const request: RefreshAutomaticFlowDataRequest = {
            groupId: groupId,
        };

        await API.post<void>(
            `${URL.REPORTING_SITES}/flow-data/refresh`,
            request
        );
    }

    static async uploadScenarioFlowData(
        reportingSiteId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<FlowData> {
        const request: UploadFlowDataRequest<TimeSeriesManualUploadRequest> = {
            type: FlowDataUploadType.MANUAL,
            metadata: {
                azureId: blobId,
                fileName: fileName,
                type: type,
            },
        };

        const flowData = await API.post<FlowData>(
            `/scenario-reporting-sites/${reportingSiteId}/flow-data/upload`,
            request
        );

        return flowData;
    }

    static async getReportingYearRange(
        groupId: string,
        scenarioId?: string
    ): Promise<string[]> {
        const response = await API.get<FlowDataReportingYearRange>(
            `${URL.GROUP}/${groupId}/flow-data/reporting-years?scenarioId=${
                scenarioId ?? ""
            }`
        );

        const ranges = [];

        if (!response.startYear || !response.endYear) {
            return ranges;
        }

        for (let i = response.startYear; i <= response.endYear; i++) {
            ranges.push(i.toString());
        }

        return ranges;
    }
}
