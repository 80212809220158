import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_SYSTEM,
    ORM_SCENARIO_SYSTEM,
    ORM_SCENARIO_REGION,
    ID_ATTRIBUTE,
    ORM_SCENARIO,
} from "Constants/constants";

export default class ScenarioSystemORM extends BaseORMModel {}

ScenarioSystemORM.modelName = ORM_SCENARIO_SYSTEM;
ScenarioSystemORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    scenarioRegionId: fk({
        to: ORM_SCENARIO_REGION,
        as: "scenarioRegion",
        relatedName: "scenarioSystems",
    }),
    scenarioId: fk({
        to: ORM_SCENARIO,
        as: "scenario",
        relatedName: "scenarioSystems",
    }),
    parentSystemId: fk({
        to: ORM_SCENARIO_SYSTEM,
        as: "scenarioSystem",
        relatedName: "scenarioSubSystems",
    }),
    systemId: fk({
        to: ORM_SYSTEM,
        as: "system",
        relatedName: "scenarioSystems",
    }),
};
