export const SCENARIO_COMPLETE_FILTER = {
    include: [
        {
            relation: "globalSetting",
            scope: {
                include: [
                    {
                        relation: "flowRules",
                        scope: {
                            include: [
                                {
                                    relation: "tables",
                                    scope: {
                                        include: [
                                            {
                                                relation: "records",
                                                scope: {
                                                    order: "target",
                                                },
                                            },
                                        ],
                                        order: "name",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            relation: "scenarioRegions",
            scope: {
                include: [
                    {
                        relation: "region",
                    },
                ],
            },
        },
        {
            relation: "scenarioSystems",
            scope: {
                include: [
                    { relation: "flowRules" },
                    { relation: "system" },
                    { relation: "climateData" },
                ],
            },
        },
        {
            relation: "scenarioReportingSites",
            scope: {
                include: [
                    {
                        relation: "reportingSite",
                        scope: {
                            include: [
                                {
                                    relation: "flowData",
                                },
                                {
                                    relation: "complianceData",
                                },
                            ],
                        },
                    },
                    {
                        relation: "flowData",
                    },
                    {
                        relation: "complianceData",
                    },
                ],
            },
        },
    ],
};

export const SCENARIO_LIBRARY_FILTER = {
    include: ["result"],
};

export const SCENARIO_REPORTING_SITE_FILTER = {
    include: [
        {
            relation: "flowRules",
        },
    ],
};

export const SCENARIO_FLOW_RULE_RELATIONS = [
    {
        relation: "freshes",
        scope: {
            include: [{ relation: "params" }],
        },
    },
    {
        relation: "lowflow",
        scope: {
            include: [{ relation: "params" }],
        },
    },
    {
        relation: "oversupply",
        scope: {
            include: [{ relation: "params" }],
        },
    },
    {
        relation: "freshesMultiYear",
        scope: {
            include: [{ relation: "params" }],
        },
    },
    {
        relation: "custom",
        scope: {
            include: [{ relation: "params" }],
        },
    },
    {
        relation: "tables",
        scope: {
            include: [{ relation: "records", scope: { order: "target" } }],
            order: "name",
        },
    },
];

export const SCENARIO_FLOW_RULE_FILTER = {
    include: SCENARIO_FLOW_RULE_RELATIONS,
};

export const SCENARIO_REPORTING_SITE_FLOW_RULES_FILTER = {
    include: [
        {
            relation: "scenarioReportingSites",
            scope: {
                include: [
                    {
                        relation: "flowRules",
                        scope: {
                            include: SCENARIO_FLOW_RULE_RELATIONS,
                        },
                    },
                ],
            },
        },
    ],
};

export const SCENARIO_CUSTOM_FLOW_RULES_FILTER = {
    include: [
        {
            relation: "scenarioSystems",
            scope: {
                include: [
                    {
                        relation: "flowRules",
                        scope: {
                            include: [
                                {
                                    relation: "custom",
                                    scope: {
                                        include: [{ relation: "params" }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
