import React, { useState, useEffect, useRef, FunctionComponent } from "react";
import styles from "./Editable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

interface EditableProps {
    item?: any;
    setItemName?(value: string): void;
    props?: any;
    editName?(name: string): void;
    format?(name: string): any;
    type?: string;
    id?: string;
}

const Editable: FunctionComponent<EditableProps> = (props: EditableProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(props.item);

    useEffect(() => {
        setName(props.item);
    }, [props.item]);

    const inputEl = useRef(null);

    useEffect(() => {
        if (isEditing) {
            inputEl.current.focus();
        }
    }, [isEditing]);

    if (isEditing) {
        return (
            <span onClick={(e) => e.stopPropagation()}>
                <input
                    ref={inputEl}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <FontAwesomeIcon
                    className={styles["save-edit"]}
                    icon={faCheckCircle}
                    onClick={() => {
                        let _name = name;

                        if (props.format) {
                            _name = props.format(name);
                        }

                        props.editName(_name);

                        setName(_name);

                        setIsEditing(false);
                    }}
                />
            </span>
        );
    }

    return (
        <span
            className={styles["tag"]}
            onClick={(e) => {
                setIsEditing(true);
                e.stopPropagation();
            }}
        >
            <span data-tip data-for="editable" className={styles["underline"]}>
                {name}
            </span>
        </span>
    );
};

export default Editable;
