import { TokenType } from "types";
import API from "../api";
import {
    TokenParseRequest,
    TokenParseResult,
    UserRegistrationJWTPayload,
} from "./types";

export default class TokenService {
    public static async parse(
        token: string,
        type: TokenType
    ): Promise<TokenParseResult<UserRegistrationJWTPayload>> {
        const request: TokenParseRequest = {
            token: token,
            type: type,
        };

        const user = await API.post<
            TokenParseResult<UserRegistrationJWTPayload>
        >(`/tokens/parse`, request);

        return user;
    }
}
