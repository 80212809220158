import React from "react";
import { useEffect, useRef } from "react";
import { EventHanlders, EventEmitter } from "Components/EventEmitter";

export function useEvent(
    eventKey: string,
    eventHandler: (...arg: any) => any,
    deps: React.DependencyList = []
) {
    const eventHandlers = useRef(new EventHanlders());

    useEffect(() => {
        const current = eventHandlers.current;

        current.addEvents(eventKey, eventHandler);

        EventEmitter.subscribe(eventKey, current.events[eventKey]);

        return () => {
            console.log("useEvent | cleanup |", eventKey);
            EventEmitter.off(eventKey, current.events[eventKey]);
        };
    }, deps);
}
