import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <div className={`row justify-content-center ${styles.footer}`}>
            Created by Truii Pty Ltd
        </div>
    );
};

export default Footer;
