import Loadable from "react-loadable";
import Loader from "Components/Loader/Loader";
import { EflowRouteMap } from "./types";

const AppRoutes: EflowRouteMap = Object.freeze({
    ManageAccount: {
        Route: "/manage-account",
        Permissions: {},
        Scene: Loadable({
            loader: () => import("Scenes/ManageAccount/ManageAccount"),
            loading: Loader,
        }),
        authenticatedRoute: true,
        isDefault: false,
    },
    Register: {
        Route: "/register",
        Permissions: {},
        Scene: Loadable({
            loader: () => import("Scenes/Register/Register"),
            loading: Loader,
        }),
        authenticatedRoute: false,
        isDefault: false,
    },
    Home: {
        Route: "/",
        Permissions: {},
        Scene: Loadable({
            loader: () => import("Scenes/Home/Home"),
            loading: Loader,
        }),
        authenticatedRoute: true,
        isDefault: true,
    },
});

export default AppRoutes;
