import React from "react";
import ReactDOM from "react-dom";
import App from "./Scenes/App";

import * as serviceWorker from "./serviceWorker";
import { unregister } from "./serviceWorker";
import flat from "array.prototype.flat";
import { Auth0Provider } from "./auth/react-auth0-spa";
import { Chart, registerables } from "chart.js";
import history from "./utils/history";
import EflowTelemetry from "./telemetry/telemetry";

import "bootstrap/dist/css/bootstrap.min.css";
import "./global-styles.scss";
import "./fonts.scss";

Chart.register(...registerables);

EflowTelemetry.start();

interface AppState {
    targetUrl: string;
}

const onRedirectCallback = (appState?: AppState): void => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const app = (
    // eslint-disable-next-line react/jsx-filename-extension
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_AUDENCE_API}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={process.env.REACT_APP_AUTH0_CALLBACK_URI}
        onRedirectCallback={onRedirectCallback}
    >
        <App />
    </Auth0Provider>
);

serviceWorker.unregister();

ReactDOM.render(app, document.getElementById("root"));
// The poly fills
flat.shim();

unregister();

export default App;
