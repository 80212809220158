import { System } from "../types";
import { URL } from "../Constants/api";
import API from "../api";
import { CreateSystemRequest, UpdateSystemRequest } from "./types";
import { SYSTEMS_FILTER } from "./filters/system.filter";

export default class SystemService {
    private static readonly url: string = URL.SYSTEMS;

    static async getSystem(id: string): Promise<System> {
        const reportingSite = await API.query<System>(
            `${this.url}/${id}`,
            SYSTEMS_FILTER
        );

        return reportingSite;
    }

    static async updateSystem(
        id: string,
        request: Partial<UpdateSystemRequest>
    ): Promise<System> {
        request.id = id;

        const updatedSystem = await API.patch<System>(
            `${this.url}/${id}`,
            request
        );

        return updatedSystem;
    }

    static async createSystem(request: CreateSystemRequest): Promise<System> {
        const system = await API.post<System>(this.url, request);

        return system;
    }

    static async removeSystem(id: string): Promise<void> {
        await API.delete<void>(`${this.url}/${id}`);
    }

    static async deleteClimateData(systemId: string): Promise<void> {
        await API.delete(`${this.url}/${systemId}/climate-data`);
    }
}
