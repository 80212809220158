import { store } from "../redux";
import generateORMActionName from "redux/reducers/orm.action.gen";
import {
    CREATE_ACTION,
    DELETE_BATCH_ACTION,
    DELETE_ALL_ACTION,
    UPDATE_ACTION,
    ID_ATTRIBUTE,
    UPDATE_BATCH_ACTION,
    ORM_SCENARIO_SYSTEM,
    ORM_FLOW_RULE,
    DELETE_ADVANCE_ACTION,
    ORM_FRESHES_FLOW_RULE,
    FLOW_RULES,
    ORM_LOW_FLOW_FLOW_RULE,
    ORM_OVER_SUPPLY_FLOW_RULE,
    FLOW_RULE_MAP,
    ORM_SUCCESS_TABLE,
    ORM_SCENARIO_REPORTING_SITE,
    ORM_OVER_SUPPLY_PARAM,
    ORM_FRESHES_PARAM,
    ORM_LOW_FLOW_PARAM,
    ORM_PARTIAL_SUCCESS_DATA,
    ORM_SLICE,
    ORM_CUSTOM_PARAM,
    ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
    ORM_FRESHES_MULTI_YEAR_PARAM,
    DELETE_ACTION,
} from "Constants/constants";
import { ruleParamsGen, successTableGen, guid } from "../utils/functions";
import _ from "lodash";
import orm from "models/orm.register";
import ScenarioService from "services/scenario.service";
import {
    ClimateData,
    ComplianceData,
    FlowData,
    FlowRule,
    FlowRuleType,
    PartialTable,
    PartialTableData,
    ScenarioReportingSite,
} from "types";

const getSliceForFlowRuleType = (type: FlowRuleType) => {
    let ruleSlice = null;
    let paramSlice = null;

    switch (type) {
        case FLOW_RULES.FRESHES.state:
            ruleSlice = ORM_FRESHES_FLOW_RULE;
            paramSlice = ORM_FRESHES_PARAM;
            break;
        case FLOW_RULES.LOW_FLOW.state:
            ruleSlice = ORM_LOW_FLOW_FLOW_RULE;
            paramSlice = ORM_LOW_FLOW_PARAM;
            break;
        case FLOW_RULES.OVERSUPPLY.state:
            ruleSlice = ORM_OVER_SUPPLY_FLOW_RULE;
            paramSlice = ORM_OVER_SUPPLY_PARAM;
            break;
        case FLOW_RULES.FRESHES_MULTI_YEAR.state:
            ruleSlice = ORM_FRESHES_MULTI_YEAR_FLOW_RULE;
            paramSlice = ORM_FRESHES_MULTI_YEAR_PARAM;
            break;
    }

    return { ruleSlice, paramSlice };
};

export const getFlowRuleById = (id: string): FlowRule => {
    if (store && orm) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const _flowRule = session[ORM_FLOW_RULE].select(session, {
            include: [
                {
                    freshes: ["params"],
                },
                {
                    freshesMultiYear: ["params"],
                },
                {
                    oversupply: ["params"],
                },
                {
                    lowflow: ["params"],
                },
                {
                    tables: ["records"],
                },
            ],
            filter: {
                [ID_ATTRIBUTE]: id,
            },
        })[0];

        return _flowRule;
    }

    return null;
};

export const getRSWithFilter = (filter: any): ScenarioReportingSite => {
    if (store && orm) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const rs = session[ORM_SCENARIO_REPORTING_SITE].select(
            session,
            filter ?? {}
        );

        return rs;
    }

    return null;
};

export const getFlowRuleWithFilter = (filter: any): FlowRule => {
    if (store && orm) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const rs = session[ORM_FLOW_RULE].select(session, filter ?? {});

        return rs;
    }

    return null;
};

export const changeFlowRuleType = (
    flowRule: FlowRule,
    oldType: FlowRuleType,
    newType: FlowRuleType,
    tables: PartialTable[] = null
) => {
    const { ruleSlice: oldSlice } = getSliceForFlowRuleType(oldType);
    const { ruleSlice: newSlice } = getSliceForFlowRuleType(newType);

    flowRule = getFlowRuleById(flowRule[ID_ATTRIBUTE]);

    if (
        !flowRule ||
        oldType === newType ||
        oldSlice == null ||
        newSlice == null ||
        !FLOW_RULE_MAP[oldType] ||
        !FLOW_RULE_MAP[newType]
    )
        return;

    let nTables = tables;

    if (tables && tables.length !== 0) {
        nTables = cloneTables(tables);
    } else {
        nTables = successTableGen(newType);
    }

    const oldRule = flowRule[oldType];

    if (oldRule) {
        store.dispatch({
            type: generateORMActionName({
                slice: oldSlice,
                actionName: `${DELETE_ADVANCE_ACTION}`,
            }),
            payload: {
                cascade: true,
                filter: {
                    [ID_ATTRIBUTE]: oldRule[ID_ATTRIBUTE],
                },
            },
        });
    }

    // delete tables
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SUCCESS_TABLE,
            actionName: `${DELETE_BATCH_ACTION}`,
        }),
        payload: flowRule.tables ?? [],
    });

    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FLOW_RULE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: flowRule[ID_ATTRIBUTE],
            type: newType,
            tables: nTables,
            ...(FLOW_RULE_MAP[newType]
                ? {
                      [newType]: {
                          ...ruleParamsGen(flowRule[ID_ATTRIBUTE], newType),
                      },
                  }
                : {}),
        },
    });
};

export const deleteFlowRuleRelations = (flowRule: FlowRule) => {
    const rule = flowRule[flowRule.type];
    const params = rule?.params ?? [];

    const { ruleSlice, paramSlice } = getSliceForFlowRuleType(flowRule.type);

    if (rule) {
        store.dispatch({
            type: generateORMActionName({
                slice: ruleSlice,
                actionName: `${DELETE_ACTION}`,
            }),
            payload: {
                id: rule.id,
            },
        });
    }

    params.forEach((p: any) => {
        store.dispatch({
            type: generateORMActionName({
                slice: paramSlice,
                actionName: `${DELETE_ACTION}`,
            }),
            payload: {
                id: p.id,
            },
        });
    });

    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SUCCESS_TABLE,
            actionName: `${DELETE_BATCH_ACTION}`,
        }),
        payload: flowRule.tables ?? [],
    });
};

export const replaceFlowRuleTables = (
    flowRuleId: string,
    currentTables: PartialTable[] = [],
    newTables: PartialTable[] = []
) => {
    if (flowRuleId && currentTables.length === 0) {
        const flowRule = getFlowRuleById(flowRuleId);

        currentTables = flowRule.tables ?? [];

        // there are no data being loaded yet, create data, otherwise, change the data
        if (currentTables.length === 0) {
            store.dispatch({
                type: generateORMActionName({
                    slice: ORM_FLOW_RULE,
                    actionName: `${UPDATE_ACTION}`,
                }),
                payload: {
                    [ID_ATTRIBUTE]: flowRuleId,
                    tables: (newTables ?? []).map((table) => {
                        return {
                            id: guid(),
                            name: table.name,
                            type: table.type,
                            records: (table.records ?? []).map((record) => {
                                return {
                                    id: guid(),
                                    success: record.success,
                                    target: record.target,
                                };
                            }),
                        };
                    }),
                },
            });

            return;
        }
    }

    let newTablesMapByType = _.keyBy(newTables ?? [], (table) => table.type);

    // assuming current table length and new table length is the same
    currentTables.forEach((table) => {
        const newTable = newTablesMapByType[(table ?? {}).type];

        if (newTable?.records?.length !== 0 && table?.records?.length !== 0) {
            const batchUpdate = (
                newRecords: PartialTableData[] = [],
                oldRecords: PartialTableData[] = []
            ) => {
                if (
                    !newRecords ||
                    !oldRecords ||
                    newRecords.length !== oldRecords.length
                )
                    return;

                store.dispatch({
                    type: generateORMActionName({
                        slice: ORM_PARTIAL_SUCCESS_DATA,
                        actionName: `${UPDATE_BATCH_ACTION}`,
                    }),
                    payload: oldRecords.map((record, index) => {
                        return {
                            ...record,
                            target: newRecords[index].target,
                            success: newRecords[index].success,
                        };
                    }),
                });
            };

            // if oldTable has larger amount of records
            // delete some records
            // batch update of records
            if (table.records.length > newTable.records.length) {
                let diff = table.records.length - newTable.records.length;

                store.dispatch({
                    type: generateORMActionName({
                        slice: ORM_SUCCESS_TABLE,
                        actionName: `${DELETE_BATCH_ACTION}_records`,
                    }),
                    payload: {
                        [ID_ATTRIBUTE]: table[ID_ATTRIBUTE],
                        records: table.records.filter(
                            (record, index) => index < diff
                        ),
                    },
                });

                table.records = table.records.filter(
                    (record, index) => index >= diff
                );
            }

            // if (newTable and oldTable has the same amount of records)
            // batch update of records
            if (table.records.length === newTable.records.length) {
                batchUpdate(newTable.records, table.records);
            }

            // if (oldTable has less records)
            // update and create by indexes
            if (table.records.length < newTable.records.length) {
                store.dispatch({
                    type: generateORMActionName({
                        slice: ORM_SUCCESS_TABLE,
                        actionName: `${DELETE_ALL_ACTION}_records`,
                    }),
                    payload: {
                        [ID_ATTRIBUTE]: table[ID_ATTRIBUTE],
                    },
                });
                store.dispatch({
                    type: generateORMActionName({
                        slice: ORM_SUCCESS_TABLE,
                        actionName: `${CREATE_ACTION}`,
                    }),
                    payload: {
                        [ID_ATTRIBUTE]: table[ID_ATTRIBUTE],
                        records: (newTable.records ?? []).map((record) => {
                            return {
                                ...(record ?? {}),
                                [ID_ATTRIBUTE]: guid(),
                            };
                        }),
                    },
                });
            }
        }
    });
};

export const updateStoreOfFlowRule = (id: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FLOW_RULE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfOversupplyParam = (id: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_OVER_SUPPLY_PARAM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfCustomParam = (id: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_CUSTOM_PARAM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfFreshesParam = (id: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FRESHES_PARAM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfFreshesMultiYearFlowRule = (
    id: string,
    payload: any
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfFreshesMultiYearParam = (
    id: string,
    payload: any
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FRESHES_MULTI_YEAR_PARAM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const updateStoreOfLowFlow = (id: string, payload: any) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_LOW_FLOW_PARAM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: id,
            ...(payload ?? {}),
        },
    });
};

export const deleteStoreOfFlowRule = (id: string) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_FLOW_RULE,
            actionName: `${DELETE_ADVANCE_ACTION}`,
        }),
        payload: {
            cascade: true,
            filter: {
                [ID_ATTRIBUTE]: id,
            },
        },
    });
};

export const createStoreOfSSCustomFlowRule = (
    systemId: string,
    flowRule: FlowRule
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_SYSTEM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: systemId,
            flowRules: [flowRule],
        },
    });
};

export const loadFlowRules = async (scenarioReportingSiteId: string) => {
    const storeRs = (getRSWithFilter({
        include: ["flowRules"],
        filter: {
            [ID_ATTRIBUTE]: scenarioReportingSiteId,
        },
    }) ?? [])[0];

    // // rs is not created yet, should not do any querying
    if (
        !storeRs ||
        !storeRs.createdAt ||
        (storeRs.flowRules ?? []).filter((flowRule) => flowRule.createdAt)
            .length > 0 ||
        storeRs.flowRulesLoaded
    ) {
        !storeRs.flowRulesLoaded &&
            store.dispatch({
                type: generateORMActionName({
                    slice: ORM_SCENARIO_REPORTING_SITE,
                    actionName: `${UPDATE_ACTION}`,
                }),
                payload: {
                    [ID_ATTRIBUTE]: scenarioReportingSiteId,
                    flowRulesLoaded: true,
                },
            });
        return;
    }

    const rs = await ScenarioService.getScenarioReportingSite(
        scenarioReportingSiteId
    );

    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_REPORTING_SITE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: scenarioReportingSiteId,
            flowRules: (rs ?? {}).flowRules ?? [],
        },
    });

    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_REPORTING_SITE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: scenarioReportingSiteId,
            flowRulesLoaded: true,
        },
    });
};

export const loadFlowRuleParamsAndTables = async (
    parentId: string,
    flowRuleId: string,
    type: FlowRuleType
) => {
    const flowRule = (getFlowRuleWithFilter({
        include: [
            {
                [type]: ["params"],
            },
            {
                tables: ["records"],
            },
            {
                scenarioReportingSites: [],
            },
        ],
        filter: {
            [ID_ATTRIBUTE]: flowRuleId,
        },
    }) ?? [])[0];

    if (!flowRule || !flowRule.createdAt) {
        return;
    }

    const paramDoesExist = flowRule && flowRule[flowRule.type];
    const tableDoesExist =
        flowRule && flowRule.tables && flowRule.tables.length > 0;

    let dbFlowRule: FlowRule = null;

    if (type !== FlowRuleType.CUSTOM) {
        dbFlowRule = await ScenarioService.getFlowRule(parentId, flowRule.id);
    } else {
        dbFlowRule = await ScenarioService.getCustomFlowRule(
            parentId,
            flowRule.id
        );
    }

    if (dbFlowRule && !paramDoesExist) {
        store.dispatch({
            type: generateORMActionName({
                slice: ORM_FLOW_RULE,
                actionName: `${UPDATE_ACTION}`,
            }),
            payload: {
                [ID_ATTRIBUTE]: flowRule[ID_ATTRIBUTE],
                [flowRule.type]: dbFlowRule[flowRule.type],
            },
        });
    }

    if (dbFlowRule && !tableDoesExist) {
        store.dispatch({
            type: generateORMActionName({
                slice: ORM_FLOW_RULE,
                actionName: `${UPDATE_ACTION}`,
            }),
            payload: {
                [ID_ATTRIBUTE]: flowRule[ID_ATTRIBUTE],
                tables: dbFlowRule.tables,
            },
        });
    }
};

const cloneTables = (tables: PartialTable[] = []) => {
    return (tables ?? []).map((table) => {
        return {
            ...table,
            [ID_ATTRIBUTE]: guid(),
            records: (table.records ?? []).map((record) => {
                return {
                    ...record,
                    [ID_ATTRIBUTE]: guid(),
                };
            }),
        };
    });
};

export const createStoreOfFlowRuleWithTables = (
    siteId: string,
    flowRule: FlowRule
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_REPORTING_SITE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: siteId,
            flowRules: [flowRule],
        },
    });
};

export const updateScenarioSystemClimateData = (
    systemId: string,
    climateData: ClimateData
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_SYSTEM,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: systemId,
            climateData: climateData,
        },
    });
};

export const updateScenarioReportingSiteFlowData = (
    reportingSiteId: string,
    flowData: FlowData
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_REPORTING_SITE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: reportingSiteId,
            flowData: flowData,
        },
    });
};

export const updateScenarioReportingSiteComplianceData = (
    reportingSiteId: string,
    complianceData: ComplianceData
) => {
    store.dispatch({
        type: generateORMActionName({
            slice: ORM_SCENARIO_REPORTING_SITE,
            actionName: `${UPDATE_ACTION}`,
        }),
        payload: {
            [ID_ATTRIBUTE]: reportingSiteId,
            complianceData: complianceData,
        },
    });
};
