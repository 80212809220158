import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./Modal.module.scss";

interface ModalProps {
    show: boolean;
    onCancel: any;
    onComplete: any;
    title: string;
    callBack?: void;
}

const TheModal = ({ show, onCancel, title, onComplete }: ModalProps) => {
    const handleClose = () => {
        onCancel && onCancel();
    };

    const handleProceed = () => {
        onComplete && onComplete();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you wish to proceed with this action? This
                    cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className={styles["cancel-button"]}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={styles["proceed-button"]}
                        onClick={() => handleProceed()}
                    >
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TheModal;
