import React, { useState } from "react";
import _ from "lodash";
import { ClimateData, Region, System } from "types";
import AccordianSystem from "./AccordianSystem";
import OverlayButton from "../Button/OverlayButton";
import { Toast } from "Components";
import { CreateSystemRequest } from "services/types";
import { SystemService } from "services";

type AccodianSystemsProps = {
    region: Region;
};

const AccordianSystems = ({ region }: AccodianSystemsProps) => {
    const regionSystems =
        region.systems?.filter((x) => _.isNil(x.parentSystemId)) ?? [];

    const [systems, setSystems] = useState<System[]>(regionSystems);

    const addSystem = async () => {
        try {
            const request: CreateSystemRequest = {
                name: "Untitled Catchment",
                regionId: region.id,
            };

            const newSystem = await SystemService.createSystem(request);

            setSystems([...systems, newSystem]);

            Toast.success("Successfully created catchment");
        } catch (error) {
            Toast.error("Error creating catchment");
        }
    };

    const removeParentSystem = (system: System) => {
        const next = systems.filter((x) => x.id !== system.id);

        setSystems(next);
    };

    const updateSystemClimateData = (id: string, climateData: ClimateData) => {
        const next = systems.map((sys) => {
            if (sys.id === id) {
                return {
                    ...sys,
                    climateData: climateData,
                };
            }

            return sys;
        });

        setSystems(next);
    };

    return (
        <>
            <OverlayButton
                className="mb-2 mr-2"
                tooltip="Add a new catchment to the region"
                tooltipPosition="right"
                onClick={async () => {
                    await addSystem();
                }}
            >
                Add catchment
            </OverlayButton>

            {systems.map((system) => {
                return (
                    <AccordianSystem
                        key={system.id}
                        isParentSystem={true}
                        system={system}
                        onParentSystemRemoved={removeParentSystem}
                        updateSystemClimateData={updateSystemClimateData}
                    ></AccordianSystem>
                );
            })}
        </>
    );
};

export default AccordianSystems;
