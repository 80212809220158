import BaseReducerHandler from "./BaseReducerHandler";
import { ERROR_CHANNEL } from "Constants/constants";

export default class BaseORMReducerHandler extends BaseReducerHandler {
    /*
    
        { initState, slice, createAction, updateAction, deleteAction, deleteAllAction }
        slice is required
    */
    constructor({
        initState = {},
        slice,
        orm,
        errorChannel = ERROR_CHANNEL.GENERAL,
        fireErrorOnSuccess = true,
    }) {
        super({ initState, slice, errorChannel, fireErrorOnSuccess });

        if (!orm) throw new Error("ORM definition does not exist");

        this.orm = orm;

        // action data requires type and payload
        this.registerAction({
            actionName: "",
            actionFn: (state, action) => {
                const session = this.orm.session(
                    state || this.orm.getEmptyState()
                );
                session.sessionBoundModels.forEach((modelClass) => {
                    if (typeof modelClass.reducer === "function") {
                        modelClass.reducer(action, modelClass, session);
                    }
                });

                return session.state;
            },
        });
    }
}
