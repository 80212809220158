import React from "react";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { guid } from "utils/functions";

type TooltipProps = {
    children: React.ReactNode;
    text: string;
    placement?: Placement;
};

const Tooltip = ({ children, placement, text }: TooltipProps) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<ReactTooltip id={guid()}>{text}</ReactTooltip>}
        >
            {children}
        </OverlayTrigger>
    );
};

export default Tooltip;
