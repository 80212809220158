import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ORM_PARTIAL_SUCCESS_DATA, ID_ATTRIBUTE } from "Constants/constants";

export default class PartialSuccessDataORM extends BaseORMModel {}

PartialSuccessDataORM.modelName = ORM_PARTIAL_SUCCESS_DATA;
PartialSuccessDataORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    target: attr(),
    success: attr(),
};
