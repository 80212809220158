import { combineReducers, ReducersMapObject } from 'redux';


import { reducers } from './reducers';

let root: ReducersMapObject<any, any> = {};

(reducers ?? []).forEach(reducer => {
    root[reducer.slice] = reducer.reducer();
});

const rootReducer = combineReducers(root);
export default rootReducer;