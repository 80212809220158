import { Region } from "../types";
import { URL } from "../Constants/api";
import { REGION } from "../Constants/constants";
import API from "../api";
import { CreateRegionRequest, UpdateRegionRequest } from "./types";
import {
    REGIONS_FILTER,
    SCENARIO_REGIONS_FILTER,
} from "./filters/region.filter";

export default class RegionService {
    private static readonly url: string = URL.REGIONS;

    static async getRegions(groupId: string): Promise<Region[]> {
        const regions = await API.query<Region[]>(
            `${URL.GROUP}/${groupId}/regions`,
            {
                ...REGIONS_FILTER,
                where: {
                    deleted: false,
                },
            }
        );

        return regions;
    }

    static async getRegionsForScenarioGeneration(
        groupId: string
    ): Promise<Region[]> {
        const regions = await API.query<Region[]>(
            `${URL.GROUP}/${groupId}/regions`,
            {
                ...SCENARIO_REGIONS_FILTER,
                where: {
                    deleted: false,
                },
            }
        );

        return regions;
    }

    static async createRegion(
        groupId: string,
        request: CreateRegionRequest
    ): Promise<Region> {
        const region = await API.post<Region>(
            `${URL.GROUP}/${groupId}/regions`,
            request
        );

        return {
            ...(region ?? {}),
            type: REGION,
            children: [],
            parents: [],
        } as Region;
    }

    static async updateRegion(
        id: string,
        request: Partial<UpdateRegionRequest>
    ): Promise<Region> {
        request.id = id;

        const updatedRegion = await API.patch<Region>(
            `${this.url}/${id}`,
            request
        );

        return updatedRegion;
    }

    static async removeRegion(id: string): Promise<void> {
        await API.delete<void>(`${this.url}/${id}`);
    }
}
