import { Chart, ChartData, ChartDataset, ChartOptions } from "chart.js";
import { EVENT_TOGGLE_DATA_SET_VISIBILITY } from "Constants/constants";
import { useEvent } from "hooks/useEvent";
import { isNil, merge } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { guid } from "utils/functions";

interface EflowChartProps {
    type: "bar" | "line" | "scatter";
    data: Array<ChartDataset>;
    labels: Array<string | string[] | number | number[] | Date | Date[]>;
    height?: number | string;
    width?: number | string;
    options?: ChartOptions;
}

const defaultTickFontSize = 14;
const defaultLegendFontSize = 14;
const defaultFontFamily = "'Source Sans Pro', sans-serif";

Chart.defaults.font.family = defaultFontFamily;

export const EflowChart = ({
    type,
    data,
    labels,
    height = "auto",
    width = "auto",
    options = {},
}: EflowChartProps) => {
    const [chart, setChart] = useState<Chart>();
    const canvas = useRef<HTMLCanvasElement>(null);

    const chartData: ChartData = { labels: labels, datasets: data };

    const chartOpts = merge<ChartOptions, ChartOptions>(
        {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: defaultTickFontSize,
                        },
                    },
                },
                y: {
                    ticks: {
                        font: {
                            size: defaultTickFontSize,
                        },
                    },
                },
            },
            plugins: {
                tooltip: {},
                legend: {
                    position: "right",
                    labels: {
                        font: {
                            size: defaultLegendFontSize,
                        },
                    },
                },
            },
        },
        options
    );

    useEffect(() => {
        const ctx = canvas.current?.getContext("2d");

        if (ctx === null || ctx === undefined) {
            return;
        }

        const _chart = new Chart(ctx, {
            type,
            data: chartData,
            options: chartOpts,
        });

        setChart(_chart);
    }, []);

    useEffect(() => {
        // the data or options has changed
        // so we need to update the chart
        if (chart === undefined) {
            return;
        }

        chart.data = chartData;
        chart.options = chartOpts;
        chart.update();
    }, [chartData, chartOpts, labels]);

    useEvent(
        EVENT_TOGGLE_DATA_SET_VISIBILITY,
        (data) => {
            if (isNil(chart)) {
                return;
            }

            chart.setDatasetVisibility(data.datasetIndex, data.show);
            chart.update();
        },
        [chart]
    );

    return (
        <div
            className="chart-container"
            style={{ height: height, width: width }}
        >
            <canvas id={guid()} ref={canvas} />
        </div>
    );
};

export default EflowChart;
