import React from "react";
import toaster from "toasted-notes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";
import "toasted-notes/src/styles.css";
import { isNil } from "lodash";

export default class Toast {
    static success(text: string): void {
        toaster.notify(
            <>
                <span>
                    <FontAwesomeIcon
                        style={{ color: "green" }}
                        icon={faCheckCircle}
                    />
                    &nbsp;
                </span>
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </>,
            {
                position: "top-right",
            }
        );
    }

    static error(text: string): void {
        toaster.notify(
            <>
                <span>
                    <FontAwesomeIcon
                        style={{ color: "red" }}
                        icon={faCheckCircle}
                    />
                    &nbsp;
                </span>
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </>,
            {
                position: "top-right",
            }
        );
    }

    static warn(text: string): void {
        toaster.notify(
            <span>
                <FontAwesomeIcon
                    style={{ color: "orange" }}
                    icon={faExclamationTriangle}
                />
                &nbsp;{text}{" "}
            </span>,
            {
                position: "top-right",
            }
        );
    }

    static async wrap<T>(
        action: () => Promise<T>,
        successText?: string,
        errorText?: string
    ): Promise<T> {
        try {
            const result = await action();

            if (!isNil(successText)) {
                this.success(successText);
            }

            return result;
        } catch (error) {
            if (!isNil(errorText)) {
                this.error(errorText);
            }
        }
    }
}
