export enum EflowEnvironment {
    DEVELOPMENT = "development",
    RELEASE = "release",
    PRODUCTION = "master",
}

class Config {
    public readonly environment: EflowEnvironment;
    public readonly ai: ApplicationInsightsConfig;
    public readonly auth0: Auth0Config;

    constructor() {
        this.environment = process.env
            .REACT_APP_EFLOW_ENVIRONMENT as EflowEnvironment;
        this.ai = new ApplicationInsightsConfig();
        this.auth0 = new Auth0Config();
    }
}

class ApplicationInsightsConfig {
    public readonly connectionString: string;

    constructor() {
        this.connectionString = process.env.REACT_APP_AI_CONNECTION_STRING;
    }
}

class Auth0Config {
    public readonly audience: string;
    public readonly clientId: string;
    public readonly redirectUri: string;

    constructor() {
        this.audience = process.env.REACT_APP_AUTH0_AUDENCE_API;
        this.clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        this.redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URI;
    }
}

export default new Config();
