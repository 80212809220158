import clsx from "clsx";
import React from "react";
import "./Button.scss";

type ButtonProps = {
    variant?: "primary" | "secondary";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: ButtonProps) => {
    const { type, variant = "primary", className, children, ...rest } = props;

    return (
        <button
            type={type ?? "button"}
            className={clsx(`eflow-button btn`, className, {
                primary: variant === "primary",
                secondary: variant === "secondary",
            })}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;
