import React from "react";
import { FileUpload } from "Components";
import { UPLOAD_COMPLIANCE } from "Constants/constants";
import { ComplianceData } from "types";
import { formatDate, isNilOrEmpty } from "utils/functions";
import CSVValidationErrorDisplay from "Components/CSVValidationErrorDisplay";

interface ReportingSiteFlowDataManualUploadProps {
    reportingSiteId: string;
    complianceData?: Partial<ComplianceData>;
    complianceDataUpdated: (id: string, flowData: ComplianceData) => void;
}

const ReportingSiteComplianceDataManualUpload = ({
    reportingSiteId,
    complianceData = {},
    complianceDataUpdated,
}: ReportingSiteFlowDataManualUploadProps) => {
    return (
        <div>
            <div className={"pt-2"}>
                <b>Environmental release data</b>
            </div>

            <FileUpload
                onRemove={() => {
                    complianceDataUpdated(reportingSiteId, null);
                }}
                onAppend={(cd: ComplianceData) => {
                    complianceDataUpdated(reportingSiteId, cd);
                }}
                onOverwrite={(cd: ComplianceData) => {
                    complianceDataUpdated(reportingSiteId, cd);
                }}
                fileAzureId={complianceData.latestUpdatedFileAzureId}
                parentId={reportingSiteId}
                type={UPLOAD_COMPLIANCE}
                defaultValue={complianceData.latestFileName}
            />

            <div>
                Record start: {formatDate(new Date(complianceData.start))}
                &nbsp;&nbsp;Record end:{" "}
                {formatDate(new Date(complianceData.end))}
            </div>

            {!isNilOrEmpty(complianceData.validationError) && (
                <CSVValidationErrorDisplay
                    error={complianceData.validationError}
                />
            )}
        </div>
    );
};

export default ReportingSiteComplianceDataManualUpload;
