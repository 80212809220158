import React from "react";
import styles from "./RadioButton.module.scss";
import clsx from "clsx";

export type RadioButtonProps = {
    label?: string;
    onChecked?: () => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const RadioButton = ({
    label,
    checked,
    onChecked,
    className,
    ...rest
}: RadioButtonProps) => {
    return (
        <>
            <input
                type="radio"
                className={clsx(styles["eflow-radio-button"], className)}
                checked={checked}
                onChange={() => {
                    if (onChecked) {
                        onChecked();
                    }
                }}
                {...rest}
            ></input>

            {label && (
                <span className={styles["eflow-radio-button--label"]}>
                    {label}
                </span>
            )}
        </>
    );
};

export default RadioButton;
