import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_SCENARIO_REPORTING_SITE,
    ORM_SCENARIO_SYSTEM,
    ORM_REPORTING_SITE,
    ID_ATTRIBUTE,
    ORM_SCENARIO,
} from "Constants/constants";

export default class ScenarioReportingSiteORM extends BaseORMModel {}

ScenarioReportingSiteORM.modelName = ORM_SCENARIO_REPORTING_SITE;
ScenarioReportingSiteORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRulesLoaded: attr(),

    // by default null or undefined or empty array
    globalSettingFlowRules: attr(),

    scenarioSystemId: fk({
        to: ORM_SCENARIO_SYSTEM,
        as: "scenarioSystem",
        relatedName: "scenarioReportingSites",
    }),

    scenarioId: fk({
        to: ORM_SCENARIO,
        as: "scenario",
        relatedName: "scenarioReportingSites",
    }),

    reportingSiteId: fk({
        to: ORM_REPORTING_SITE,
        as: "reportingSite",
        relatedName: "scenarioReportingSites",
    }),
};
