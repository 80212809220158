import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_SCENARIO_REGION,
    ORM_REGION,
    ORM_SCENARIO,
    ID_ATTRIBUTE,
} from "Constants/constants";

export default class ScenarioRegionORM extends BaseORMModel {}

ScenarioRegionORM.modelName = ORM_SCENARIO_REGION;
ScenarioRegionORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    scenarioId: fk({
        to: ORM_SCENARIO,
        as: "scenario",
        relatedName: "scenarioRegions",
    }),
    regionId: fk({
        to: ORM_REGION,
        as: "region",
        relatedName: "scenarioRegions",
    }),
};
