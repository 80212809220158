import React from "react";
import { Editable } from "Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

type AccodianTitleProps = {
    id: string;
    name: string;
    onUpdate: (name: string) => void;
    onDelete: (e: any) => void;
};

const AccodianTitle = (props: AccodianTitleProps) => {
    const { id, name, onUpdate, onDelete } = props;

    return (
        <>
            <Editable
                id={id}
                editName={(n) => {
                    onUpdate(n);
                }}
                item={name}
            />

            <FontAwesomeIcon
                className="ml-2"
                icon={faTrashAlt}
                onClick={onDelete}
            />
        </>
    );
};

export default AccodianTitle;
