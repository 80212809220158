import React, { useMemo } from "react";
import styles from "./RuleTable.module.scss";
import { updateStoreOfSuccessTable } from "stores/successTable.store";
import { ID_ATTRIBUTE, SOFT_DELETE_ATTRIBUTE } from "Constants/constants";
import BaseORMModel from "models/BaseORMModel";
import { createSelector } from "redux-orm";
import { useSelector } from "react-redux";
import orm from "models/orm.register";
import { PartialTable, PartialTableData } from "types";
import PartialSuccessTable from "./Table/PartialSuccessTable";

interface _RuleTableProps {
    table: PartialTable;
    onTableUpdated: () => void;
}

const _RuleTable = ({ table, onTableUpdated }: _RuleTableProps) => {
    const handleCellUpdate = (
        record: PartialTableData,
        newValue: string,
        type: "target" | "success"
    ) => {
        if (!record) {
            return;
        }

        updateStoreOfSuccessTable(record.id, {
            ...record,
            [type]: newValue,
        });

        if (onTableUpdated) {
            onTableUpdated();
        }
    };

    const scene = useMemo(() => {
        return (
            table && (
                <div
                    className={`${styles["rule-table"]} mt-3 mb-4 text-decoration-none text-center col-md-12 col-lg-6`}
                >
                    <div className={styles["rule-table--title"]}>
                        <h4>{table.name}</h4>
                    </div>

                    <div className="d-flex justify-content-center">
                        <PartialSuccessTable
                            table={table}
                            onCellUpdate={handleCellUpdate}
                        />
                    </div>
                </div>
            )
        );
    }, [BaseORMModel.getVersionId(table ?? {})]);

    return scene;
};

const __RuleTable = React.memo(
    _RuleTable,
    ({ table: prevTable }, { table: nextTable }) => {
        return (
            BaseORMModel.getVersionId(prevTable ?? {}) ===
            BaseORMModel.getVersionId(nextTable ?? {})
        );
    }
);

const tableSelector = (tableId: string) => {
    return createSelector(orm, (session) => {
        if (tableId === null || tableId === undefined) return null;

        const { SuccessTable } = session;

        const table = SuccessTable.select(session, {
            include: ["records"],
            filter: {
                [SOFT_DELETE_ATTRIBUTE]: false,
                [ID_ATTRIBUTE]: tableId,
            },
        });

        return (table ?? [])[0];
    });
};

interface RuleTableProps {
    table: PartialTable;
    onTableUpdated: () => void;
}

const RuleTable = ({ table, onTableUpdated }: RuleTableProps) => {
    const tableWithRecords = useSelector(tableSelector(table[ID_ATTRIBUTE]));

    return (
        <__RuleTable table={tableWithRecords} onTableUpdated={onTableUpdated} />
    );
};

export default RuleTable;
