import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_COMPLIANCE_DATA,
    ORM_FLOW_DATA,
    ORM_REPORTING_SITE,
    ID_ATTRIBUTE,
} from "Constants/constants";

export default class ReportingSiteORM extends BaseORMModel {}

ReportingSiteORM.modelName = ORM_REPORTING_SITE;
ReportingSiteORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    gaugeName: attr(),
    lag: attr(),
    deleted: attr(),
    flowId: fk({
        to: ORM_FLOW_DATA,
        as: "flowData",
        relatedName: "reportingSites",
    }),
    complianceDataId: fk({
        to: ORM_COMPLIANCE_DATA,
        as: "complianceData",
        relatedName: "reportingSites",
    }),
};
