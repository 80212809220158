import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ORM_SYSTEM, ID_ATTRIBUTE } from "Constants/constants";

export default class SystemORM extends BaseORMModel {}

SystemORM.modelName = ORM_SYSTEM;
SystemORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    reporting: attr(),
    deleted: attr(),
};
