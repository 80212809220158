import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_LOW_FLOW_FLOW_RULE,
    ID_ATTRIBUTE,
    ORM_LOW_FLOW_PARAM,
} from "Constants/constants";

export default class LowFlowParamORM extends BaseORMModel {}

LowFlowParamORM.modelName = ORM_LOW_FLOW_PARAM;
LowFlowParamORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    threshold: attr(),
    duration: attr(),
    durationType: attr(),
    climate: attr(),
    flowRuleId: fk({
        to: ORM_LOW_FLOW_FLOW_RULE,
        as: "flowRule",
        relatedName: "params",
    }),
};
