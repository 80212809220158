import React, { useState } from "react";
import { EVENT_LOADER_DISPLAY } from "Constants/constants";
import { Loader } from "Components";
import { Modal } from "react-bootstrap";
import { useEvent } from "hooks/useEvent";
import { EventEmitter } from "Components/EventEmitter";

export const runWithLoader = async (
    work: () => Promise<void>,
    message: string
): Promise<void> => {
    try {
        EventEmitter.dispatch(EVENT_LOADER_DISPLAY, {
            show: true,
            message: message,
        });

        await work();
    } finally {
        EventEmitter.dispatch(EVENT_LOADER_DISPLAY, {
            show: false,
        });
    }
};

const FullScreenLoader = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState(undefined);

    const handleClose = () => setShow(false);

    useEvent(EVENT_LOADER_DISPLAY, (event) => {
        if (event) {
            setShow(event.show ?? true);
            setMessage(event.message ?? "");
        }
    });

    return (
        <Modal
            show={show}
            className="fullscreen-loader"
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Loader fullpage={false} message={message} />
        </Modal>
    );
};

export default FullScreenLoader;
