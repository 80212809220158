/* eslint-disable react/prop-types */

import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, {
    Auth0Client,
    Auth0ClientOptions,
    getIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    PopupLoginOptions,
    RedirectLoginOptions,
    RedirectLoginResult,
} from "@auth0/auth0-spa-js";
import { UserService } from "services";
import EflowUser from "./eflow-user";
import { Membership } from "types";
import API from "../api";
import EflowTelemetry from "../telemetry/telemetry";

export interface Auth0RedirectState {
    targetUrl?: string;
}

export interface Auth0User extends Omit<IdToken, "__raw"> {}

interface Auth0ContextProps {
    user?: Auth0User;
    eflowUser?: EflowUser;
    isAuthenticated: boolean;
    isPopupOpen: boolean;
    loading: boolean;
    userClaim?: IdToken;
    loginWithPopup(o?: PopupLoginOptions): Promise<void>;
    handleRedirectCallback(): Promise<RedirectLoginResult>;
    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>;
    loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
    getTokenWithPopup(
        o?: GetTokenWithPopupOptions
    ): Promise<string | undefined>;
    logout(o?: LogoutOptions): void;
    refreshMembership: () => Promise<void>;
    setActiveMembership: (membership: Membership) => Promise<void>;
}

interface Auth0ProviderOptions {
    children: React.ReactElement;
    onRedirectCallback(appState?: any): void;
}

const DEFAULT_REDIRECT_CALLBACK = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

export const Auth0Context = React.createContext<Auth0ContextProps | null>(null);

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<Auth0User>();
    const [userClaim, setUserClaim] = useState<IdToken>(undefined);
    const [auth0Client, setAuth0] = useState<Auth0Client>();
    const [loading, setLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [eflowUser, setEflowUser] = useState<EflowUser>(null);

    async function getMe() {
        try {
            const me = await UserService.me();

            EflowTelemetry.setUser(me.auth0UserId);

            setEflowUser(new EflowUser(me));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        const initAuth0 = async () => {
            try {
                const auth0FromHook = await createAuth0Client(initOptions);

                setAuth0(auth0FromHook);

                if (
                    window.location.search.includes("code=") &&
                    window.location.search.includes("state=")
                ) {
                    try {
                        const { appState } =
                            await auth0FromHook.handleRedirectCallback();

                        onRedirectCallback(appState);
                    } catch (ex) {
                        console.log("invalid app state", ex);
                    }
                }

                const isAuthenticated = await auth0FromHook.isAuthenticated();

                if (isAuthenticated) {
                    const user = await auth0FromHook.getUser();
                    const claim = await auth0FromHook.getIdTokenClaims();

                    API.setToken(claim.__raw);

                    setUser(user);
                    setUserClaim(claim);
                }

                setIsAuthenticated(isAuthenticated);

                setLoading(false);
            } catch (error) {
                console.log(error);

                throw error;
            }
        };

        initAuth0();
    }, []);

    useEffect(() => {
        if (isAuthenticated && user) {
            getMe();
        }
    }, [isAuthenticated]);

    const refreshMembership = async () => {
        if (isAuthenticated && user) {
            await getMe();
        }
    };

    const setActiveMembership = async (
        membership: Membership
    ): Promise<void> => {
        await UserService.setActiveMembership(membership);

        eflowUser.setActiveMembership(membership);

        setEflowUser(eflowUser.copy());
    };

    const loginWithPopup = async (params = {}) => {
        try {
            setIsPopupOpen(true);

            try {
                await auth0Client.loginWithPopup(params);
            } catch (error) {
                console.error(error);
            } finally {
                setIsPopupOpen(false);
            }

            const user = await auth0Client.getUser();

            setUser(user);
            setIsAuthenticated(true);
        } catch (error) {
            console.log(error);

            throw error;
        }
    };

    const handleRedirectCallback = async () => {
        try {
            setLoading(true);

            const result = await auth0Client.handleRedirectCallback();

            const user = await auth0Client.getUser();

            setLoading(false);
            setIsAuthenticated(true);
            setUser(user);

            return result;
        } catch (error) {
            console.log(error);

            throw error;
        }
    };

    const loginWithRedirect = (options?: RedirectLoginOptions) =>
        auth0Client.loginWithRedirect(options);

    const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
        auth0Client.getTokenSilently(options);

    const logout = (options?: LogoutOptions) => {
        API.setToken(null);

        EflowTelemetry.clearUser();

        auth0Client.logout(options);
    };

    const getIdTokenClaims = (options?: getIdTokenClaimsOptions) =>
        auth0Client.getIdTokenClaims(options);

    const getTokenWithPopup = (options?: GetTokenWithPopupOptions) =>
        auth0Client.getTokenWithPopup(options);

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                eflowUser,
                loading,
                isPopupOpen,
                loginWithPopup,
                handleRedirectCallback,
                userClaim,
                getIdTokenClaims: getIdTokenClaims,
                loginWithRedirect: loginWithRedirect,
                getTokenSilently: getTokenSilently,
                getTokenWithPopup,
                logout: logout,
                refreshMembership: refreshMembership,
                setActiveMembership: setActiveMembership,
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
