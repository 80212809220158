import React from "react";
import { Editable } from "Components";
import styles from "./PartialSuccessTable.module.scss";
import { PartialTable, PartialTableData } from "types";
import { guid } from "utils/functions";
import PartialSuccessTableChart from "./PartialSuccessTableChart";

interface PartialTableCompomentProps {
    table: PartialTable;
    onCellUpdate: (
        record: PartialTableData,
        newValue: string,
        type: "target" | "success"
    ) => void;
}

const PartialSuccessTable = ({
    table,
    onCellUpdate,
}: PartialTableCompomentProps) => {
    const format = (value: string) => {
        let t = 0;

        try {
            t = parseFloat(value);

            if (isNaN(t)) {
                t = 0;
            }
        } catch (ex) {
            console.log(ex);
        }

        if (t >= 100) {
            t = 100;
        } else if (t < 0) {
            t = 0;
        }

        return t;
    };

    const records = table.records ?? [];

    return (
        <div className={styles["pst-root"]}>
            <table className={styles["partial-success-table"]}>
                <thead>
                    <tr>
                        <th>Target (%)</th>
                        <th>Success (%)</th>
                    </tr>
                </thead>

                <tbody>
                    {records.map((record) => {
                        return (
                            <tr key={guid()}>
                                <td>
                                    <Editable
                                        item={record.target}
                                        format={format}
                                        editName={(value) => {
                                            onCellUpdate(
                                                record,
                                                value,
                                                "target"
                                            );
                                        }}
                                    />
                                </td>

                                <td>
                                    <Editable
                                        item={record.success}
                                        format={format}
                                        editName={(value) => {
                                            onCellUpdate(
                                                record,
                                                value,
                                                "success"
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <PartialSuccessTableChart
                tableName={table.name}
                records={records}
            />
        </div>
    );
};

export default PartialSuccessTable;
