import _ from "lodash";
import {
    ClimateType,
    ClimaticCondition,
    FlowRuleType,
    Month,
    PartialSuccessTableType,
} from "types";

//Reporting System
export const REGION = "regions";
export const SYSTEM = "systems";
export const SUB_SYSTEM = "subSystem";
export const REPORTING_SITE = "reportingSites";
export const FLOW_RULE = "flowRule";

//Upload Constants
export const UPLOAD_COMPLIANCE = "uploadComplianceData";
export const UPLOAD_FLOW_DATA = "uploadFlowData";
export const UPLOAD_CLIMATE = "uploadClimate";
export const UPLOAD_SCENARIO_SYSTEM_CLIMATE = "uploadScenarioSystemClimate";
export const UPLOAD_SCENARIO_REPORTING_SITE_FLOW_DATA =
    "uploadScenarioReportingSiteFlowData";
export const UPLOAD_SCENARIO_REPORTING_SITE_COMPLIANCE_DATA =
    "uploadScenarioReportingSiteComplianceData";

export const UPLOAD_APPEND = "append";
export const UPLOAD_OVERWRITE = "overwrite";

export const Url = Object.freeze({
    ScenarioLibrary: "/scenario-library",
    ConfigureScenario: "/configure-scenario",
    SystemSetup: "/system-setup",
});

let days = [];

for (var i = 1; i < 32; i++) {
    days.push(i);
}

export const DAYS = days;

export const RETURN_PERIOD_YEARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface MonthConstants {
    [key: string]: { label: string; state: Month };
}

export const MONTHS: MonthConstants = {
    JANUARY: {
        label: "January",
        state: Month.January,
    },
    FEBRUARY: {
        label: "February",
        state: Month.February,
    },
    MARCH: {
        label: "March",
        state: Month.March,
    },
    APRIL: {
        label: "April",
        state: Month.April,
    },
    MAY: {
        label: "May",
        state: Month.May,
    },
    JUNE: {
        label: "June",
        state: Month.June,
    },
    JULY: {
        label: "July",
        state: Month.July,
    },
    AUGUST: {
        label: "August",
        state: Month.August,
    },
    SEPTEMBER: {
        label: "September",
        state: Month.September,
    },
    OCTOBER: {
        label: "October",
        state: Month.October,
    },
    NOVEMBER: {
        label: "November",
        state: Month.November,
    },
    DECEMBER: {
        label: "December",
        state: Month.December,
    },
};

export const MONTH_MAP = _.keyBy(_.values(MONTHS ?? {}) ?? [], (d) => d.state);

export const MONTH_VALS = [
    MONTHS.JANUARY,
    MONTHS.FEBRUARY,
    MONTHS.MARCH,
    MONTHS.APRIL,
    MONTHS.MAY,
    MONTHS.JUNE,
    MONTHS.JULY,
    MONTHS.AUGUST,
    MONTHS.SEPTEMBER,
    MONTHS.OCTOBER,
    MONTHS.NOVEMBER,
    MONTHS.DECEMBER,
];

export const MONTH_VALUES = [
    MONTHS.JANUARY,
    MONTHS.FEBRUARY,
    MONTHS.MARCH,
    MONTHS.APRIL,
    MONTHS.MAY,
    MONTHS.JUNE,
    MONTHS.JULY,
    MONTHS.AUGUST,
    MONTHS.SEPTEMBER,
    MONTHS.OCTOBER,
    MONTHS.NOVEMBER,
    MONTHS.DECEMBER,
].map((d) => ({ month: d.label, id: d.state }));

export const REPORTING_LEVELS = {
    TIER_1A: { label: "Level 1", state: "tier_1a" },
    TIER_1B: { label: "Level 2", state: "tier_1b" },
    TIER_2: { label: "Level 3", state: "tier_2" },
    EFLOW_REC: { label: "Level 4", state: "eflow" },
};

export const DEFAULT_REPORTING_LEVEL = REPORTING_LEVELS.TIER_1A;

export const REPORTING_LEVEL_VALS = [
    REPORTING_LEVELS.TIER_1A,
    REPORTING_LEVELS.TIER_1B,
    REPORTING_LEVELS.TIER_2,
    REPORTING_LEVELS.EFLOW_REC,
];
export const REPORTING_LEVEL_MAP = _.keyBy(
    _.values(REPORTING_LEVELS ?? {}) ?? [],
    (d) => d.state
);

export const DEFAULT_REPORTING_YEAR = "2019 - 2020";

export const REPORTING_YEARS = [
    "2009 - 2010",
    "2010 - 2011",
    "2011 - 2012",
    "2012 - 2013",
    "2013 - 2014",
    "2014 - 2015",
    "2015 - 2016",
    "2016 - 2017",
    "2017 - 2018",
    "2018 - 2019",
    "2019 - 2020",
    "2020 - 2021",
    "2021 - 2022",
    "2022 - 2023",
    "2023 - 2024",
    "2024 - 2025",
    "2025 - 2026",
    "2026 - 2027",
    "2027 - 2028",
    "2028 - 2029",
    "2029 - 2030",
];

export const CLIMATES: {
    [key: string]: { label: string; state: ClimateType };
} = {
    WET: {
        label: "Wet",
        state: ClimateType.WET,
    },
    AVERAGE: {
        label: "Average",
        state: ClimateType.AVERAGE,
    },
    DRY: {
        label: "Dry",
        state: ClimateType.DRY,
    },
    VERY_DRY: {
        label: "Very dry",
        state: ClimateType.VERY_DRY,
    },
    DROUGHT: {
        label: "Drought",
        state: ClimateType.DROUGHT,
    },
};

export const CLIMATE_VALS = [
    CLIMATES.DROUGHT,
    CLIMATES.VERY_DRY,
    CLIMATES.DRY,
    CLIMATES.AVERAGE,
    CLIMATES.WET,
];

export const CLIMATE_MAP = _.keyBy(
    _.values(CLIMATES ?? {}) ?? [],
    (d) => d.state
);

export const ERROR_CHANNEL = {
    GENERAL: "GENERAL",
};

export const ORM_SLICE = "orm";

export const UPDATE_ACTION = "UPDATE_ACTION";
export const CREATE_ACTION = "CREATE_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";
export const DELETE_ADVANCE_ACTION = "DELETE_ADVANCE_ACTION";
export const DELETE_SOFT_ACTION = "DELETE_SOFT_ACTION";

export const CREATE_BATCH_ACTION = "CREATE_BATCH_ACTION";
export const UPDATE_BATCH_ACTION = "UPDATE_BATCH_ACTION";
export const DELETE_BATCH_ACTION = "DELETE_BATCH_ACTION";
export const DELETE_SOFT_BATCH_ACTION = "DELETE_SOFT_BATCH_ACTION";

export const DELETE_ALL_ACTION = "DELETE_ALL_ACTION";
export const DELETE_SOFT_ALL_ACTION = "DELETE_SOFT_ALL_ACTION";

export const ID_ATTRIBUTE = "id";
export const VERSION_ATTRIBUTE = "_____version";
export const QUERY_VERSION_ATTRIBUTE = "_____query____version";
export const SOFT_DELETE_ATTRIBUTE = "___deleted";

export const ORM_SCENARIO = "Scenario";
export const ORM_GLOBAL_SETTING = "GlobalSetting";
export const ORM_SUCCESS_TABLE = "SuccessTable";
export const ORM_CLIMATIC_CONDITION = "ClimaticCondition";
export const ORM_PARTIAL_SUCCESS_DATA = "PartialSuccessData";
export const ORM_SCENARIO_REGION = "ScenarioRegion";
export const ORM_REGION = "Region";
export const ORM_SCENARIO_SYSTEM = "ScenarioSystem";
export const ORM_SYSTEM = "System";
export const ORM_SCENARIO_REPORTING_SITE = "ScenarioReportingSite";
export const ORM_REPORTING_SITE = "ReportingSite";
export const ORM_LOW_FLOW_FLOW_RULE = "LowFlowFlowRule";
export const ORM_FRESHES_FLOW_RULE = "FreshesFlowRule";
export const ORM_OVER_SUPPLY_FLOW_RULE = "OversupplyFlowRule";
export const ORM_CUSTOM_FLOW_RULE = "CustomFlowRule";
export const ORM_FRESHES_MULTI_YEAR_FLOW_RULE = "FreshesMultiYearFlowRule";

export const ORM_CUSTOM_PARAM = "CustomParam";
export const ORM_LOW_FLOW_PARAM = "LowFlowParam";
export const ORM_FRESHES_PARAM = "FreshesParam";
export const ORM_FRESHES_MULTI_YEAR_PARAM = "FreshesMultiYearParam";
export const ORM_OVER_SUPPLY_PARAM = "OversupplyParam";
export const ORM_FLOW_DATA = "FlowData";
export const ORM_COMPLIANCE_DATA = "ComplianceData";
export const ORM_FLOW_RULE = "FlowRule";

export const SCENARIO_WIPE_SLICES = [
    ORM_SCENARIO,
    ORM_GLOBAL_SETTING,
    ORM_SUCCESS_TABLE,
    ORM_CLIMATIC_CONDITION,
    ORM_PARTIAL_SUCCESS_DATA,
    ORM_SCENARIO_REGION,
    ORM_REGION,
    ORM_SCENARIO_SYSTEM,
    ORM_SYSTEM,
    ORM_SCENARIO_REPORTING_SITE,
    ORM_REPORTING_SITE,
    ORM_LOW_FLOW_FLOW_RULE,
    ORM_OVER_SUPPLY_FLOW_RULE,
    ORM_FRESHES_FLOW_RULE,
    ORM_FRESHES_MULTI_YEAR_FLOW_RULE,
    ORM_LOW_FLOW_PARAM,
    ORM_FRESHES_PARAM,
    ORM_FRESHES_MULTI_YEAR_PARAM,
    ORM_OVER_SUPPLY_PARAM,
    ORM_FLOW_DATA,
    ORM_COMPLIANCE_DATA,
    ORM_FLOW_RULE,
];

export const LOW_FLOW_DURATION_TYPES = {
    DAYS: {
        label: "Days",
        state: "days",
    },
    PERCENT_OF_SEASON: {
        label: "Percent of season",
        state: "percent_of_seasons",
    },
};
export const LOW_FLOW_DURATION_VALS = [
    LOW_FLOW_DURATION_TYPES.DAYS,
    LOW_FLOW_DURATION_TYPES.PERCENT_OF_SEASON,
];

export const OVERSUPPLY_DURATION_TYPES = {
    DAYS: {
        label: "Days",
        state: "days",
    },
    PERCENT_OF_SEASON: {
        label: "Percent of season",
        state: "percent_of_seasons",
    },
};

export const OVERSUPPLY_DURATION_VALS = [
    OVERSUPPLY_DURATION_TYPES.DAYS,
    OVERSUPPLY_DURATION_TYPES.PERCENT_OF_SEASON,
];

export const LOW_FLOW_DEFAULT = {
    threshold: null,
    duration: null,
    durationType: LOW_FLOW_DURATION_TYPES.DAYS.state,
};

export const FRESHES_DEFAULT = {
    threshold: null,
    minDuration: null,
    numEvents: null,
    independence: null,
};

export const FRESHES_MULTI_YEAR_DEFAULT = {
    threshold: null,
    minDuration: null,
    numEvents: null,
    independence: null,
};

export const OVERSUPPLY_DEFAULT = {
    threshold: null,
    duration: null,
    durationType: OVERSUPPLY_DURATION_TYPES.DAYS.state,
};

export const CUSTOM_RULE_DEFAULT = {
    description: null,
};

interface FlowRulesMap {
    [key: string]: { state: FlowRuleType; label: string; default: any };
}

export const FLOW_RULES: FlowRulesMap = {
    FRESHES: {
        state: FlowRuleType.FRESHES,
        label: "Freshes",
        default: FRESHES_DEFAULT,
    },
    LOW_FLOW: {
        state: FlowRuleType.LOW_FLOW,
        label: "Low flow",
        default: LOW_FLOW_DEFAULT,
    },
    OVERSUPPLY: {
        state: FlowRuleType.OVERSUPPLY,
        label: "Oversupply",
        default: OVERSUPPLY_DEFAULT,
    },
    FRESHES_MULTI_YEAR: {
        state: FlowRuleType.FRESHES_MULTI_YEAR,
        label: "Multi-Year Freshes",
        default: FRESHES_MULTI_YEAR_DEFAULT,
    },
    CUSTOM: {
        state: FlowRuleType.CUSTOM,
        label: "Custom",
        default: CUSTOM_RULE_DEFAULT,
    },
};

export const FLOW_RULE_VALS = [
    FLOW_RULES.FRESHES,
    FLOW_RULES.LOW_FLOW,
    FLOW_RULES.OVERSUPPLY,
    FLOW_RULES.FRESHES_MULTI_YEAR,
];

export const FLOW_RULE_MAP = _.keyBy(
    _.values(FLOW_RULES ?? {}) ?? [],
    (d) => d.state
);

export const SUCCESS_TABLE: {
    [key: string]: {
        state: PartialSuccessTableType;
        label: string;
    };
} = {
    MAGNITUDE: {
        state: PartialSuccessTableType.MAGNITUDE,
        label: "Magnitude",
    },
    DURATION: {
        state: PartialSuccessTableType.DURATION,
        label: "Duration",
    },
    COUNT: {
        state: PartialSuccessTableType.COUNT,
        label: "Count",
    },
    INDEPENDENCE: {
        state: PartialSuccessTableType.INDEPENDENCE,
        label: "Independence",
    },
    MAX_TIME: {
        state: PartialSuccessTableType.MAX_TIME,
        label: "Max Time",
    },
};

export const PARTIAL_RULE_TABLE_DEFINITION = {
    [FLOW_RULES.OVERSUPPLY.state]: [SUCCESS_TABLE.DURATION],
    [FLOW_RULES.LOW_FLOW.state]: [
        SUCCESS_TABLE.MAGNITUDE,
        SUCCESS_TABLE.DURATION,
    ],
    [FLOW_RULES.FRESHES.state]: [
        SUCCESS_TABLE.MAGNITUDE,
        SUCCESS_TABLE.DURATION,
        SUCCESS_TABLE.COUNT,
        SUCCESS_TABLE.INDEPENDENCE,
    ],
    [FLOW_RULES.FRESHES_MULTI_YEAR.state]: [
        SUCCESS_TABLE.MAGNITUDE,
        SUCCESS_TABLE.DURATION,
        SUCCESS_TABLE.COUNT,
        SUCCESS_TABLE.INDEPENDENCE,
        SUCCESS_TABLE.MAX_TIME,
    ],
};

export const SUCCESS_TABLE_MAP = _.keyBy(
    _.values(SUCCESS_TABLE ?? {}) ?? [],
    (d) => d.state
);

export const CLIMATIC_CONDITION_DEFAULTS: Array<Partial<ClimaticCondition>> = [
    { startMonth: MONTHS.JULY.state, climate: CLIMATES.AVERAGE.state },
];

export const FLOW_RULE_DEFAULT = {
    startDay: 1,
    startMonth: MONTHS.JANUARY.state,
    endDay: 1,
    endMonth: MONTHS.JANUARY.state,
    reportingLevels: [REPORTING_LEVELS.TIER_1A],
    description: "",
};

export const PARTIAL_SUCCESS_CHART_LABELS = [];

for (let i = 0; i <= 100; i++) {
    PARTIAL_SUCCESS_CHART_LABELS.push(i);
}

export const DEFAULT_FLOW_RULE_NAME = "Flow rule";
export const DEFAULT_CUSTOM_FLOW_RULE_NAME = "Non hydrological objective";

export const EVENT_LOCAL_GLOBAL_FLOW_RULES_UPDATE =
    "EVENT_LOCAL_GLOBAL_FLOW_RULES_UPDATE";
export const EVENT_LOCAL_GLOBAL_CLIMATES_UPDATE =
    "EVENT_LOCAL_GLOBAL_CLIMATES_UPDATE";
export const EVENT_LOCAL_GLOBAL_UPDATE = "EVENT_LOCAL_GLOBAL_UPDATE";
export const EVENT_FLOW_RULE_SWITCH_PREPARE = "EVENT_FLOW_RULE_SWITCH_PREPARE";
export const EVENT_SCENARIO_CREATING = "EVENT_SCENARIO_CREATING";
export const EVENT_SCENARIO_CREATED = "EVENT_SCENARIO_CREATED";
export const EVENT_SCENARIO_DELETE = "EVENT_SCENARIO_DELETE";
export const EVENT_SCENARIO_MESSAGE = "EVENT_SCENARIO_MESSAGE";
export const EVENT_LOADER_DISPLAY = "EVENT_LOADER_DISPLAY";
export const EVENT_REFRESH_REPORTING_YEAR_RANGE =
    "EVENT_REFRESH_REPORTING_YEAR_RANGE";
export const EVENT_SPATIAL_SETUP_ENTITY_REMOVE =
    "EVENT_SPATIAL_SETUP_ENTITY_REMOVE";
export const EVENT_RELOAD_SCENARIO = "EVENT_RELOAD_SCENARIO";
export const EVENT_TOGGLE_DATA_SET_VISIBILITY =
    "EVENT_TOGGLE_DATA_SET_VISIBILITY";
