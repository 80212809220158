import axios, { AxiosError } from "axios";

export const API_URI = process.env.REACT_APP_API_BACKEND_URI;

class API {
    private readonly axios = axios.create({ baseURL: API_URI });
    private _token: string;

    setToken(token: string): void {
        this._token = token;
    }

    private transformHeaders(headers: Record<string, string> = {}) {
        const newHeaders = {
            ...headers,
        };

        if (this._token) {
            newHeaders.Authorization = `Bearer ${this._token}`;
        }

        return newHeaders;
    }

    async query<T>(
        url: string,
        filter: any,
        headers: Record<string, string> = {}
    ): Promise<T> {
        const serializedFilter = JSON.stringify(filter);

        const response = await this.axios.get(
            `${url}?filter=${serializedFilter}`,
            { headers: this.transformHeaders(headers) }
        );

        return response.data;
    }

    async get<T>(
        url: string,
        headers: Record<string, string> = {}
    ): Promise<T> {
        const response = await this.axios.get(url, {
            headers: this.transformHeaders(headers),
        });

        return response.data;
    }

    async post<T>(
        url: string,
        data?: any,
        headers: Record<string, string> = {}
    ): Promise<T> {
        const response = await this.axios.post(url, data, {
            headers: this.transformHeaders(headers),
        });

        return response.data;
    }

    async patch<T>(
        url: string,
        data?: any,
        headers: Record<string, string> = {}
    ): Promise<T> {
        const response = await this.axios.patch(url, data, {
            headers: this.transformHeaders(headers),
        });

        return response.data;
    }

    async delete<T>(
        url: string,
        headers: Record<string, string> = {}
    ): Promise<T> {
        const response = await this.axios.delete(url, {
            headers: this.transformHeaders(headers),
        });

        return response.data;
    }

    isAxiosError(error: Error): error is AxiosError {
        return axios.isAxiosError(error);
    }
}

export default new API();
