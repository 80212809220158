import { ORM } from "redux-orm";
import { ORM_SLICE } from "Constants/constants";
import GlobalSettingORM from "./GlobalSettingORM";
import ClimaticConditionORM from "./ClimaticConditionORM";
import ScenarioORM from "./ScenarioORM";
import SuccessTableORM from "./SuccessTableORM";
import PartialSuccessDataORM from "./PartialSuccessDataORM";
import ScenarioRegionORM from "./ScenarioRegionORM";
import RegionORM from "./RegionORM";
import ScenarioSystemORM from "./ScenarioSystemORM";
import SystemORM from "./SystemORM";
import ScenarioReportingSiteORM from "./ScenarioReportingSiteORM";
import ReportingSiteORM from "./ReportingSiteORM";
import FlowRuleORM from "./FlowRuleORM";
import ComplianceDataORM from "./ComplianceDataORM";
import FlowDataORM from "./FlowDataORM";
import FreshesParamORM from "./FreshesParamORM";
import FreshesMultiYearParamORM from "./FreshesMultiYearParamORM";
import OversupplyParamORM from "./OversupplyParamORM";
import LowFlowParamORM from "./LowFlowParamORM";
import FreshesFlowRuleORM from "./FreshesFlowRuleORM";
import FreshesMultiYearFlowRuleORM from "./FreshesMultiYearFlowRuleORM";
import OversupplyFlowRuleORM from "./OversupplyFlowRuleORM";
import LowFlowFlowRuleORM from "./LowFlowFlowRuleORM";
import CustomRuleParamORM from "./CustomRuleParamORM";
import CustomFlowRuleORM from "./CustomFlowRuleORM";

const orm = new ORM({
    stateSelector: (state) => state[ORM_SLICE],
});

orm.register(
    ScenarioORM,
    GlobalSettingORM,
    FreshesParamORM,
    FreshesMultiYearParamORM,
    OversupplyParamORM,
    LowFlowParamORM,
    FreshesFlowRuleORM,
    FreshesMultiYearFlowRuleORM,
    OversupplyFlowRuleORM,
    LowFlowFlowRuleORM,
    ClimaticConditionORM,
    SuccessTableORM,
    PartialSuccessDataORM,
    ScenarioRegionORM,
    RegionORM,
    ScenarioSystemORM,
    SystemORM,
    ScenarioReportingSiteORM,
    ReportingSiteORM,
    ComplianceDataORM,
    FlowDataORM,
    FlowRuleORM,
    CustomRuleParamORM,
    CustomFlowRuleORM
);
export default orm;
