import React from "react";
import { FileUpload } from "Components";
import { UPLOAD_CLIMATE } from "Constants/constants";
import { ClimateData, System } from "types";

type SystemClimateUploadProps = {
    system: System;
    updateSystem: (id: string, climateData: ClimateData) => void;
};

const SystemClimateUpload = (props: SystemClimateUploadProps) => {
    const { system, updateSystem } = props;

    if (!system) {
        return null;
    }

    const climateData: Partial<ClimateData> = system.climateData ?? {};

    return (
        <div>
            <h6>
                <b>Prevailing climate conditions</b>
            </h6>

            <FileUpload
                onRemove={() => {
                    updateSystem(system.id, null);
                }}
                onAppend={(climateData: ClimateData) => {
                    updateSystem(system.id, climateData);
                }}
                onOverwrite={(climateData: ClimateData) => {
                    updateSystem(system.id, climateData);
                }}
                fileAzureId={climateData.latestUpdatedFileAzureId}
                parentId={system.id}
                type={UPLOAD_CLIMATE}
                defaultValue={climateData.latestFileName}
            />
        </div>
    );
};

export default SystemClimateUpload;
