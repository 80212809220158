import React, { useState, useEffect } from "react";
import Input from "Components/Input";
import { isNilOrEmpty } from "utils/utils";

interface NumericInputProps {
    name: string;
    className: string;
    value: number;
    defaultValue: number;
    requirePositive?: boolean;
    wholeNumber?: boolean;
    onBlur: (value: number) => void;
}

const NumericInput = ({
    name,
    className,
    value,
    defaultValue,
    requirePositive = true,
    wholeNumber = false,
    onBlur,
    ...rest
}: NumericInputProps) => {
    const [textValue, setTextValue] = useState<string>(value?.toString());

    const handleBlur = () => {
        let numberValue: number = !isNilOrEmpty(textValue)
            ? parseFloat(textValue)
            : null;

        if (isNaN(numberValue)) {
            numberValue = defaultValue;
        }

        if (requirePositive && numberValue < 0) {
            numberValue = defaultValue;
        }

        if (wholeNumber && !Number.isSafeInteger(numberValue)) {
            numberValue = defaultValue;
        }

        setTextValue(numberValue?.toString() ?? "");

        if (onBlur && numberValue !== value) {
            onBlur(numberValue);
        }
    };

    useEffect(() => {
        setTextValue(value?.toString() ?? "");
    }, [value]);

    return (
        <Input
            name={name}
            type="text"
            value={textValue}
            onBlur={handleBlur}
            onChange={(e) => setTextValue(e.target.value)}
            className={className}
            {...rest}
        />
    );
};

export default NumericInput;
