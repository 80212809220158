import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_FRESHES_PARAM,
    ID_ATTRIBUTE,
    ORM_FRESHES_FLOW_RULE,
} from "Constants/constants";

export default class FreshesParamORM extends BaseORMModel {}

FreshesParamORM.modelName = ORM_FRESHES_PARAM;
FreshesParamORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    climate: attr(),
    minDuration: attr(),
    numEvents: attr(),
    independence: attr(),
    flowRuleId: fk({
        to: ORM_FRESHES_FLOW_RULE,
        as: "flowRule",
        relatedName: "params",
    }),
};
