import { many, attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_GLOBAL_SETTING,
    ORM_SCENARIO,
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
} from "Constants/constants";

export default class GlobalSettingORM extends BaseORMModel {}

GlobalSettingORM.modelName = ORM_GLOBAL_SETTING;
GlobalSettingORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    description: attr(),
    flowRules: many({
        to: ORM_FLOW_RULE,
        relatedName: "globalSettings",
    }),
    scenarioId: oneToOne({
        to: ORM_SCENARIO,
        as: "scenario",
        relatedName: "globalSetting",
    }),
};
