import { fk, attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ID_ATTRIBUTE,
    ORM_OVER_SUPPLY_PARAM,
    ORM_OVER_SUPPLY_FLOW_RULE,
} from "Constants/constants";

export default class OversupplyParamORM extends BaseORMModel {}

OversupplyParamORM.modelName = ORM_OVER_SUPPLY_PARAM;
OversupplyParamORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    climate: attr(),
    threshold: attr(),
    duration: attr(),
    durationType: attr(),
    flowRuleId: fk({
        to: ORM_OVER_SUPPLY_FLOW_RULE,
        as: "flowRule",
        relatedName: "params",
    }),
};
