import API from "../api";
import { TimeSeriesUploadType, UploadClimateDataRequest } from "./types";
import { ClimateData } from "types";

export default class ClimateDataService {
    static async uploadClimateData(
        systemId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<ClimateData> {
        const request: UploadClimateDataRequest = {
            azureId: blobId,
            fileName: fileName,
            type: type,
        };

        const flowData = await API.post<ClimateData>(
            `/systems/${systemId}/climate-data/upload`,
            request
        );

        return flowData;
    }

    static async uploadScenarioClimateData(
        systemId: string,
        blobId: string,
        type: TimeSeriesUploadType,
        fileName: string
    ): Promise<ClimateData> {
        const request: UploadClimateDataRequest = {
            azureId: blobId,
            fileName: fileName,
            type: type,
        };

        const flowData = await API.post<ClimateData>(
            `/scenario-systems/${systemId}/climate-data/upload`,
            request
        );

        return flowData;
    }
}
