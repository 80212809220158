import React from "react";
import { FlowData, FlowDataUploadType } from "types";
import RadioButtonGroup from "Components/RadioButtonGroup";
import { useState } from "react";
import ReportingSiteFlowDataManualUpload from "./ReportingSiteFlowDataManualUpload";
import styles from "./SystemSetupReportingSite.module.scss";
import ReportingSiteFlowDataAutomaticUpload from "./ReportingSiteFlowDataAutomaticUpload";

interface SystemSetupReportingSiteFlowDataUploadProps {
    reportingSiteId: string;
    gaugeNumber: string;
    flowData?: Partial<FlowData>;
    flowDataUpdated: (id: string, flowData: FlowData) => void;
}

interface FlowDataUploadTypeOption {
    value: FlowDataUploadType;
    label: string;
}

const SystemSetupReportingSiteFlowDataUpload = ({
    reportingSiteId,
    gaugeNumber,
    flowData = {},
    flowDataUpdated,
}: SystemSetupReportingSiteFlowDataUploadProps) => {
    const initialType = flowData?.uploadType ?? FlowDataUploadType.MANUAL;
    const [source, setSource] = useState<FlowDataUploadType>(initialType);

    const options: FlowDataUploadTypeOption[] = [
        { label: "Manual", value: FlowDataUploadType.MANUAL },
        { label: "Automatic", value: FlowDataUploadType.AUTOMATIC },
    ];

    return (
        <div className={styles["reporting-site-flow-data-upload"]}>
            <div className={styles["upload-type-picker"]}>
                <label>Upload Type</label>

                <RadioButtonGroup
                    initialValue={initialType}
                    options={options}
                    onOptionSelected={(option: FlowDataUploadTypeOption) => {
                        setSource(option.value);
                    }}
                />
            </div>

            {source === FlowDataUploadType.MANUAL && (
                <ReportingSiteFlowDataManualUpload
                    reportingSiteId={reportingSiteId}
                    flowData={flowData}
                    flowDataUpdated={flowDataUpdated}
                />
            )}

            {source === FlowDataUploadType.AUTOMATIC && (
                <ReportingSiteFlowDataAutomaticUpload
                    reportingSiteId={reportingSiteId}
                    gaugeNumber={gaugeNumber}
                    flowData={flowData}
                    flowDataUpdated={flowDataUpdated}
                />
            )}
        </div>
    );
};

export default SystemSetupReportingSiteFlowDataUpload;
