import React from "react";
import styles from "./RadioButtonGroup.module.scss";
import RadioButton from "Components/RadioButton";
import { guid } from "utils/functions";
import { useState } from "react";
import clsx from "clsx";
import { useEffect } from "react";

export interface RadioButtonOption {
    label: string;
    value: string;
}

export type RadioButtonGroupProps = {
    initialValue?: string;
    options: RadioButtonOption[];
    className?: string;
    onOptionSelected: (option: RadioButtonOption) => void;
};

const RadioButtonGroup = ({
    options = [],
    initialValue,
    onOptionSelected,
    className,
}: RadioButtonGroupProps) => {
    const initialOption =
        options.find((x) => x.value === initialValue) ?? options[0];

    const [selectedOption, setSelectedOption] =
        useState<RadioButtonOption>(initialOption);

    useEffect(() => {
        if (initialValue !== selectedOption.value) {
            setSelectedOption(options.find((x) => x.value === initialValue));
        }
    }, [initialValue]);

    const handleOptionSelected = (opt: RadioButtonOption): void => {
        setSelectedOption(opt);
        onOptionSelected(opt);
    };

    return (
        <div className={clsx(styles["eflow-radio-button-group"], className)}>
            {options.map((option) => {
                return (
                    <div key={guid()}>
                        <RadioButton
                            value={option.value}
                            label={option.label}
                            checked={selectedOption?.value === option.value}
                            onChecked={() => {
                                handleOptionSelected(option);
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default RadioButtonGroup;
