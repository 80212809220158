import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import "./Input.scss";
import clsx from "clsx";

export type InputProps = {
    register?: UseFormRegisterReturn;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = (props: InputProps) => {
    const { className, ...rest } = props;

    return (
        <input
            className={clsx("form-control eflow-input", className)}
            {...props.register}
            {...rest}
        ></input>
    );
};

export default Input;
