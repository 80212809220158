import React, { useState, FunctionComponent, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import SelectableContext from "react-bootstrap/SelectableContext";
import { guid } from "utils/functions";

import "./Dropdown.scss";

interface DropDownProps {
    resizeDropdown?: string;
    initialValue: string;
    buttonStyle: string;
    dropdownValues: Array<any>;
    dropdownData?: Array<any>;
    borderColor: string;
    autoSetDropdownValue?: boolean;
    callback?: (values: string, data?: any) => void;
    renderToggle?: React.ReactNode;
    menuClass?: string;
    menuAlignRight?: boolean;
}

const DropdownBox: FunctionComponent<DropDownProps> = ({
    initialValue,
    buttonStyle,
    dropdownValues,
    dropdownData,
    borderColor,
    resizeDropdown,
    callback,
    menuClass,
    menuAlignRight,
    autoSetDropdownValue = true,
    renderToggle = null,
}: DropDownProps) => {
    const [dropdownToggle, setDropdownToggle] = useState(initialValue);

    useEffect(() => {
        setDropdownToggle(initialValue);
    }, [initialValue]);

    const dropdownRender = dropdownValues.map((values, index) => (
        <Dropdown.Item
            onClick={() => {
                autoSetDropdownValue && setDropdownToggle(values);
                callback(values, !dropdownData ? null : dropdownData[index]);
            }}
            key={index}
            className={"drop-item"}
        >
            {values}
        </Dropdown.Item>
    ));

    return (
        <SelectableContext.Provider value={false}>
            <Dropdown
                className={`${borderColor}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Dropdown.Toggle
                    className={`rounded-0 ${buttonStyle} ${resizeDropdown}`}
                    id={`dropdown-basic-${guid()}`}
                >
                    {renderToggle ? renderToggle : dropdownToggle}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className={menuClass}
                    alignRight={menuAlignRight}
                >
                    {dropdownRender}
                </Dropdown.Menu>
            </Dropdown>
        </SelectableContext.Provider>
    );
};

export default DropdownBox;
