import { ReportingSite } from "../types";
import { URL } from "../Constants/api";
import { REPORTING_SITE } from "../Constants/constants";
import API from "../api";
import {
    CreateReportingSiteRequest,
    UpdateReportingSiteRequest,
} from "./types";
import { REPORTING_SITES_FILTER } from "./filters/reporting-site.filter";

export default class ReportingSiteService {
    private static readonly url: string = URL.REPORTING_SITES;

    static async getReportingSite(id: string): Promise<ReportingSite> {
        const reportingSite = await API.query<ReportingSite>(
            `${this.url}/${id}`,
            {
                include: ["flowData", "complianceData"],
            }
        );

        return reportingSite;
    }

    static async createReportingSite(
        request: CreateReportingSiteRequest
    ): Promise<ReportingSite> {
        const reportingSite = await API.post<ReportingSite>(this.url, request);

        const newReportingSite = {
            ...(reportingSite ?? {}),
            type: REPORTING_SITE,
            children: [],
            parents: [request.systemId],
        } as ReportingSite;

        return newReportingSite;
    }

    static async updateReportingSite(
        id: string,
        request: Partial<UpdateReportingSiteRequest>
    ): Promise<ReportingSite> {
        request.id = id;

        const updatedReportingSite = await API.patch<ReportingSite>(
            `${this.url}/${id}`,
            request
        );

        return updatedReportingSite;
    }

    static async removeReportingSite(id: string): Promise<void> {
        await API.delete<void>(`${this.url}/${id}`);
    }

    static async deleteFlowData(reportingSiteId: string): Promise<void> {
        await API.delete(`${this.url}/${reportingSiteId}/flow-data`);
    }

    static async deleteComplianceData(reportingSiteId: string): Promise<void> {
        await API.delete(`${this.url}/${reportingSiteId}/compliance-data`);
    }
}
