import React from "react";
import { faMinusSquare, faPlusSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AccordianExpandIconProps {
    isExpanded: boolean;
}

const AccordianExpandIcon = ({ isExpanded }: AccordianExpandIconProps) => {
    const icon = isExpanded ? faMinusSquare : faPlusSquare;

    return <FontAwesomeIcon className="accordian-expansion-icon" icon={icon} />;
};

export default AccordianExpandIcon;
