class MiddleWare {
    constructor(...props) {
        this.props = props;
        this.pipes = [];
    }

    use(pipe) {
        this.pipes.push(pipe);
    }

    async exec() {
        const next = async (index, err, fn) => {
            index++;
            return await fn(index, err);
        };

        const fn = async (index, err) => {
            if (index >= this.pipes.length) return;

            if (this.pipes[index] && typeof this.pipes[index] === "function") {
                try {
                    return await this.pipes[index](
                        ...this.props,
                        err,
                        async (err) => {
                            if (err) {
                                console.log(err);
                            }

                            return await next(index, err, fn);
                        }
                    );
                } catch (err) {
                    return await next(index, err, fn);
                }
            }
        };

        return await fn(0);
    }

    execSync() {
        const next = (index, err, fn) => {
            index++;

            const ret = fn(index, err);

            return ret;
        };

        const fn = (index, err) => {
            if (index >= this.pipes.length) return;

            if (this.pipes[index] && typeof this.pipes[index] === "function") {
                try {
                    const b = this.pipes[index](...this.props, err, (err) => {
                        if (err) {
                            console.log(err);
                        }

                        return next(index, err, fn);
                    });

                    return b;
                } catch (err) {
                    console.log(err);
                    return next(index, err, fn);
                }
            }
        };

        return fn(0);
    }

    async execWithAsync() {
        const next = async (index, err, fn) => {
            index++;
            return await fn(index, err);
        };

        const fn = async (index, err) => {
            if (index >= this.pipes.length) {
                if (err) throw err;

                return;
            }

            if (this.pipes[index] && typeof this.pipes[index] === "function") {
                try {
                    return await this.pipes[index](
                        ...this.props,
                        err,
                        async (err) => {
                            if (err) {
                                console.log(err);
                            }

                            return await next(index, err, fn);
                        }
                    );
                } catch (err) {
                    console.log(err);
                    return await next(index, err, fn);
                }
            }
        };

        return await fn(0);
    }
}
export default MiddleWare;
