import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ORM_SCENARIO, ID_ATTRIBUTE } from "Constants/constants";

export default class ScenarioORM extends BaseORMModel {}

ScenarioORM.modelName = ORM_SCENARIO;
ScenarioORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    name: attr(),
    description: attr(),
    startMonth: attr(),
    startYear: attr(),
    endYear: attr(),
    clearRun: attr(),
    reportingLevel: attr(),
    author: attr(),
    resultAzureFileId: attr(),
};
