export const REGIONS_FILTER = {
    include: [
        {
            relation: "systems",
            scope: {
                include: [{ relation: "climateData" }],
                where: { deleted: false },
            },
        },
    ],
};

export const SCENARIO_REGIONS_FILTER = {
    include: [
        {
            relation: "systems",
            scope: {
                include: [
                    {
                        relation: "subSystems",
                        scope: {
                            where: { deleted: false },
                            include: [
                                {
                                    relation: "reportingSites",
                                    scope: { where: { deleted: false } },
                                },
                            ],
                        },
                    },

                    {
                        relation: "reportingSites",
                        scope: {
                            where: { deleted: false },
                        },
                    },
                ],
                where: { deleted: false },
            },
        },
    ],
};
