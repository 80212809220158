import * as React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Switch } from "react-router-dom";
import { Footer, SecureRoute, Header } from "../../Components";
import { Provider } from "react-redux";
import { store } from "../../redux";
import AppRoutes from "../../Routes/app-routes";
import FullScreenLoader from "../../Components/FullScreenLoader/FullScreenLoader";

const App: Function = () => {
    return (
        <Provider store={store}>
            <FullScreenLoader />

            <Helmet
                titleTemplate="%s - eflow Projector"
                defaultTitle=" - eflow Projector"
            >
                <meta charSet="utf-8" />
                <link rel="canonical" href="" />
                <meta name="description" content="" />
                <meta name="theme-color" content="#F9F7F1" />
            </Helmet>

            <BrowserRouter>
                <Header />

                <div className="content">
                    <Switch>
                        {Object.keys(AppRoutes).map((routeKey) => {
                            const route = AppRoutes[routeKey];

                            return (
                                <SecureRoute
                                    key={routeKey}
                                    path={route.Route}
                                    Component={route.Scene}
                                    authenticatedRoute={
                                        route.authenticatedRoute
                                    }
                                    isDefault={route.isDefault}
                                />
                            );
                        })}
                    </Switch>
                </div>
            </BrowserRouter>

            <Footer />
        </Provider>
    );
};

export default App;
