import {
    ComputationResult,
    ComputationTaskModel,
    FlowRule,
    FlowRuleType,
    Scenario,
    ScenarioReportingSite,
    ScenarioResult,
} from "../types";
import { URL } from "../Constants/api";
import API from "../api";
import {
    SCENARIO_COMPLETE_FILTER,
    SCENARIO_LIBRARY_FILTER,
    SCENARIO_REPORTING_SITE_FILTER,
} from "./filters/scenario.filter";
import {
    ComputeScenarioRequest,
    CreateFlowRuleRequest,
    ScenarioResultParseResult,
    UpdateCustomFlowRuleRequest,
    UpdateFlowRuleRequest,
    UpdateFlowRuleTableRecordRequest,
    UpdateGlobalSettingFlowRuleTablesRequest,
    UpdateScenarioRequest,
} from "./types";
import { orderTypes } from "Scenes/ScenarioExplorer/utils";
import { isNil } from "lodash";

export default class ScenarioService {
    private static readonly url: string = URL.SCENARIOS;

    static async computeScenario(
        scenarioId: string
    ): Promise<ComputationResult> {
        try {
            const request: ComputeScenarioRequest = {
                id: scenarioId,
            };

            const task = await API.post<ComputationTaskModel>(
                `${this.url}/compute`,
                request
            );

            return { task: task, success: true, failure: null };
        } catch (error) {
            if (API.isAxiosError(error)) {
                if (error.response.status === 400) {
                    const apiError = error.response.data.error;

                    return {
                        task: null,
                        success: false,
                        failure: {
                            code: apiError.code,
                            message: apiError.message,
                        },
                    };
                }
            } else {
                throw error;
            }
        }
    }

    public static async getComputationTask(
        id: string
    ): Promise<ComputationTaskModel> {
        const status = await API.get<ComputationTaskModel>(
            `${this.url}/${id}/computation/status`
        );
        return status;
    }

    static async getScenarioLibrary(groupId: string): Promise<Scenario[]> {
        const scenarios = await API.query<Scenario[]>(
            `${URL.GROUP}/${groupId}/scenarios`,
            {
                ...SCENARIO_LIBRARY_FILTER,
            }
        );

        if (!scenarios) {
            return [];
        }

        return scenarios;
    }

    static async getScenarios(groupId: string): Promise<Scenario[]> {
        const scenarios = await API.query<Scenario[]>(
            `${URL.GROUP}/${groupId}/scenarios`,
            {
                where: { deleted: { neq: true } },
            }
        );

        if (!scenarios) {
            return [];
        }

        return scenarios;
    }

    static async getScenariosWithResults(groupId: string): Promise<Scenario[]> {
        const scenarios = await API.get<Scenario[]>(
            `${URL.GROUP}/${groupId}/scenarios/results`
        );

        return scenarios ?? [];
    }

    static async generateScenario(groupId: string): Promise<Scenario> {
        const scenario = await API.post<Scenario>(
            `${URL.GROUP}/${groupId}/scenarios`,
            {}
        );

        return scenario;
    }

    static async updateScenario(
        scenarioId: string,
        scenario: Partial<Scenario>
    ): Promise<Scenario> {
        const request: UpdateScenarioRequest = {
            name: scenario.name,
            description: scenario.description,
            startMonth: scenario.startMonth,
            startYear: scenario.startYear,
            endYear: scenario.endYear,
        };

        const updatedScenario = await API.patch<Scenario>(
            `${URL.SCENARIOS}/${scenarioId}`,
            request
        );

        return updatedScenario;
    }

    static async getScenario(scenarioId: string): Promise<Scenario> {
        const scenario = await API.query<Scenario>(
            `${this.url}/${scenarioId}`,
            SCENARIO_COMPLETE_FILTER
        );

        return scenario;
    }

    static async getScenarioReportingSite(
        scenarioReportingSiteId: string
    ): Promise<ScenarioReportingSite> {
        const scenarioReportingSite = await API.query<ScenarioReportingSite>(
            `/scenario-reporting-sites/${scenarioReportingSiteId}`,
            SCENARIO_REPORTING_SITE_FILTER
        );

        return scenarioReportingSite;
    }

    static async getFlowRule(
        scenarioReportingSiteId: string,
        flowRuleId: string
    ): Promise<FlowRule> {
        const flowRule = await API.get<FlowRule>(
            `/scenario-reporting-sites/${scenarioReportingSiteId}/flow-rules/${flowRuleId}`
        );

        return flowRule;
    }

    static async getCustomFlowRule(
        scenarioSystemId: string,
        flowRuleId: string
    ): Promise<FlowRule> {
        const flowRule = await API.get<FlowRule>(
            `/scenario-systems/${scenarioSystemId}/flow-rules/${flowRuleId}`
        );

        return flowRule;
    }

    static async deleteScenario(scenarioId: string): Promise<void> {
        await API.delete(`${this.url}/${scenarioId}`);
    }

    static async cloneScenario(scenarioId: string): Promise<Scenario> {
        const scenario = await API.post<Scenario>("/scenarios/clone", {
            id: scenarioId,
        });

        return scenario;
    }

    static async synchronizeScenarioLocations(
        scenarioId: string
    ): Promise<Scenario> {
        const scenario = await API.post<Scenario>("/scenarios/sync", {
            id: scenarioId,
        });

        return scenario;
    }

    static async deleteScenarioSystemClimateData(
        systemId: string
    ): Promise<void> {
        await API.delete(`scenario-systems/${systemId}/climate-data`);
    }

    static async deleteScenarioReportingSiteFlowData(
        reportingSiteId: string
    ): Promise<void> {
        await API.delete(
            `scenario-reporting-sites/${reportingSiteId}/flow-data`
        );
    }

    static async deleteScenarioReportingSiteComplianceData(
        reportingSiteId: string
    ): Promise<void> {
        await API.delete(
            `scenario-reporting-sites/${reportingSiteId}/compliance-data`
        );
    }

    static async createCustomFlowRule(
        scenarioSystemId: string
    ): Promise<FlowRule> {
        const flowRule = await API.post<FlowRule>(
            `/scenario-systems/${scenarioSystemId}/flow-rules/custom`
        );

        return flowRule;
    }

    static async updateCustomFlowRule(
        scenarioSystemId: string,
        flowRule: FlowRule
    ): Promise<FlowRule> {
        const request: UpdateCustomFlowRuleRequest = {
            name: flowRule.name,
            description: flowRule.description,
            reportingLevels: flowRule.reportingLevels,
            params:
                flowRule.custom?.params?.map((param) => {
                    return {
                        id: param.id,
                        description: param.description,
                    };
                }) ?? [],
        };

        const updatedFlowRule = await API.patch<FlowRule>(
            `/scenario-systems/${scenarioSystemId}/flow-rules/${flowRule.id}`,
            request
        );

        return updatedFlowRule;
    }

    static async deleteCustomFlowRule(
        scenarioSystemId: string,
        flowRuleId: string
    ): Promise<FlowRule> {
        const flowRule = await API.delete<FlowRule>(
            `/scenario-systems/${scenarioSystemId}/flow-rules/${flowRuleId}`
        );

        return flowRule;
    }

    static async createFlowRule(
        scenarioReportingSiteId: string
    ): Promise<FlowRule> {
        const request: CreateFlowRuleRequest = {
            type: FlowRuleType.FRESHES,
        };

        const flowRule = await API.post<FlowRule>(
            `/scenario-reporting-sites/${scenarioReportingSiteId}/flow-rules`,
            request
        );

        return flowRule;
    }

    static async updateFlowRule(
        scenarioReportingSiteId: string,
        flowRule: FlowRule,
        hasChangedType: boolean
    ): Promise<FlowRule> {
        const request: UpdateFlowRuleRequest = {
            name: flowRule.name,
            description: flowRule.description,
            reportingLevels: flowRule.reportingLevels,
            startDay: flowRule.startDay,
            startMonth: flowRule.startMonth,
            endDay: flowRule.endDay,
            endMonth: flowRule.endMonth,
            returnPeriod: flowRule.returnPeriod,
            type: flowRule.type,
            maxTime: flowRule?.freshesMultiYear?.maxTime,
            tables: flowRule.tables?.map((table) => {
                return {
                    id: table.id,
                    name: table.name,
                    type: table.type,
                    records: table.records?.map((r) => {
                        const record: UpdateFlowRuleTableRecordRequest = {
                            id: r.id,
                            success: r.success,
                            target: r.target,
                        };

                        return record;
                    }),
                };
            }),
            recreate: hasChangedType,
        };

        switch (flowRule.type) {
            case FlowRuleType.LOW_FLOW: {
                request.lowflow = flowRule.lowflow?.params?.map((param) => {
                    return {
                        id: param.id,
                        climate: param.climate,
                        duration: param.duration,
                        durationType: param.durationType,
                        threshold: param.threshold,
                    };
                });
                break;
            }

            case FlowRuleType.FRESHES: {
                request.freshes = flowRule.freshes?.params?.map((param) => {
                    return {
                        id: param.id,
                        climate: param.climate,
                        threshold: param.threshold,
                        minDuration: param.minDuration,
                        numEvents: param.numEvents,
                        independence: param.independence,
                    };
                });
                break;
            }

            case FlowRuleType.FRESHES_MULTI_YEAR: {
                request.freshesMultiYear =
                    flowRule.freshesMultiYear?.params?.map((param) => {
                        return {
                            id: param.id,
                            climate: param.climate,
                            threshold: param.threshold,
                            minDuration: param.minDuration,
                            numEvents: param.numEvents,
                            independence: param.independence,
                        };
                    });
                break;
            }

            case FlowRuleType.OVERSUPPLY: {
                request.oversupply = flowRule.oversupply?.params?.map(
                    (param) => {
                        return {
                            id: param.id,
                            climate: param.climate,
                            duration: param.duration,
                            durationType: param.durationType,
                            threshold: param.threshold,
                        };
                    }
                );
                break;
            }
        }

        const updatedFlowRule = await API.patch<FlowRule>(
            `/scenario-reporting-sites/${scenarioReportingSiteId}/flow-rules/${flowRule.id}`,
            request
        );

        return updatedFlowRule;
    }

    static async deleteFlowRule(
        scenarioReportingSiteId: string,
        flowRuleId: string
    ): Promise<FlowRule> {
        const flowRule = await API.delete<FlowRule>(
            `/scenario-reporting-sites/${scenarioReportingSiteId}/flow-rules/${flowRuleId}`
        );

        return flowRule;
    }

    static async updateGlobalSettingFlowRuleTables(
        scenarioId: string,
        flowRuleId: string,
        request: UpdateGlobalSettingFlowRuleTablesRequest
    ): Promise<FlowRule> {
        const flowRule = await API.patch<FlowRule>(
            `/scenarios/${scenarioId}/global-setting/flow-rules/${flowRuleId}/tables`,
            request
        );

        return flowRule;
    }

    static async replaceFlowRulesTablesWithGlobalSetting(
        scenarioId: string
    ): Promise<void> {
        await API.post<void>(
            `/scenarios/${scenarioId}/global-setting/flow-rules/override`
        );
    }

    static async parseScenarioResult(
        scenarioResultId: string
    ): Promise<ScenarioResultParseResult> {
        const result = await API.get<ScenarioResultParseResult>(
            `/scenario-results/${scenarioResultId}/parse`
        );

        if (!isNil(result)) {
            //Force ordering of flow rules types to an order that the table will understand
            result.types = orderTypes(result.types);
        }

        return result;
    }
}
