import BaseORMReducerHandler from "./BaseORMReducerHandler";
import orm from "../../models/orm.register";
import { ORM_SLICE } from "../../Constants/constants";

let _reducers = [];

_reducers = _reducers.concat([
    new BaseORMReducerHandler({
        slice: ORM_SLICE,
        orm: orm,
        initState: orm.getEmptyState(),
    }),
]);

export const reducers = _reducers;
