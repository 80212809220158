import { FlowRuleType } from "types";

export const getScoreClass = (score: number): string => {
    if (score >= 0 && score <= 20) {
        return "vlow";
    }

    if (score >= 21 && score <= 40) {
        return "low";
    }

    if (score >= 41 && score <= 60) {
        return "average";
    }

    if (score >= 61 && score <= 80) {
        return "high";
    }

    return "vhigh";
};

export function orderTypes(types: FlowRuleType[]): FlowRuleType[] {
    const orderedTypes = [
        FlowRuleType.FRESHES,
        FlowRuleType.LOW_FLOW,
        FlowRuleType.OVERSUPPLY,
        FlowRuleType.FRESHES_MULTI_YEAR,
        FlowRuleType.CUSTOM,
    ];

    return types.sort(
        (a, b) => orderedTypes.indexOf(a) - orderedTypes.indexOf(b)
    );
}
