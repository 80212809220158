import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ID_ATTRIBUTE, ORM_CLIMATIC_CONDITION } from "Constants/constants";

export default class ClimaticConditionORM extends BaseORMModel {}

ClimaticConditionORM.modelName = ORM_CLIMATIC_CONDITION;
ClimaticConditionORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    startMonth: attr(),
    climate: attr(),
};
