import styles from "./HeaderBar.module.scss";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Membership } from "types";

type HeaderBarDropdownMenuMembershipItemProps = {
    membership: Membership;
    isActive: boolean;
    onSelected: () => void;
};

const HeaderBarDropdownMenuMembershipItem = (
    props: HeaderBarDropdownMenuMembershipItemProps
) => {
    const { membership, isActive, onSelected } = props;

    return (
        <>
            <Dropdown.Item
                as="div"
                className={`${styles["drop-item"]} ${styles["group-item"]} ${
                    styles["group-name"]
                } ${isActive ? styles["active"] : ""}`}
                onClick={onSelected}
            >
                {membership.group.name}
            </Dropdown.Item>
        </>
    );
};

export default HeaderBarDropdownMenuMembershipItem;
