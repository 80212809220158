import { URL } from "../Constants/api";
import API from "../api";
import {
    GenerateBlobDownloadRequest,
    GenerateBlobDownloadResponse,
    GenerateBlobUploadRequest,
    GenerateBlobUploadResponse,
} from "./types";
import axios from "axios";
import uuid from "react-uuid";
import { BlockBlobClient } from "@azure/storage-blob";

export default class AzureBlobService {
    private static readonly url: string = URL.AZURE;

    static async generateBlobUploadUrl(fileName: string): Promise<string> {
        const request: GenerateBlobUploadRequest = {
            fileName: fileName,
        };

        const url = await API.post<GenerateBlobUploadResponse>(
            `${this.url}/generate/blob-upload-url`,
            request
        );

        return url.url;
    }

    static async generateBlobDownloadUrl(fileName: string): Promise<string> {
        const request: GenerateBlobDownloadRequest = {
            fileName: fileName,
        };

        const url = await API.post<GenerateBlobDownloadResponse>(
            `${this.url}/generate/blob-download-url`,
            request
        );

        return url.url;
    }

    static async downloadBlob(
        resultAzureFileId: string,
        name: string
    ): Promise<void> {
        try {
            const downloadUrl = await this.generateBlobDownloadUrl(
                resultAzureFileId
            );

            const response = await axios({
                url: downloadUrl,
                method: "GET",
                responseType: "blob",
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `${name}`);

            document.body.appendChild(link);

            link.click();
        } catch (error) {
            console.error(error.response.data);
        }
    }

    static async uploadBlob(
        file: File,
        onProgress: (progress: number) => void
    ) {
        const azureID = uuid();

        const url = await AzureBlobService.generateBlobUploadUrl(azureID);

        if (url) {
            const blockBlobUrl = new BlockBlobClient(url);

            await blockBlobUrl.uploadBrowserData(file, {
                blobHTTPHeaders: {
                    blobContentType: file.type,
                },
                onProgress: (progressObject) => {
                    const progress =
                        (progressObject.loadedBytes / file.size) * 100;

                    if (onProgress) {
                        onProgress(progress);
                    }
                },
            });
        }

        return azureID;
    }
}
