import styles from "./HeaderBar.module.scss";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import EflowUser from "../../auth/eflow-user";
import { Link } from "react-router-dom";
import { Membership } from "types";
import HeaderBarDropdownMenuMembershipItem from "./HeaderBarDropdownMenuMembershipItem";

interface HeaderBarDropdownMenuProps {
    user: EflowUser;
    logout: () => void;
    membershipSelected: (membership: Membership) => void;
}

const HeaderBarDropdownMenu = ({
    user,
    logout,
    membershipSelected,
}: HeaderBarDropdownMenuProps) => {
    const memberships = user.getViewableMemberships();

    return (
        <Dropdown className={styles["user-menu"]}>
            <Dropdown.Toggle
                className={`rounded-0 ${styles["button-style"]}`}
                id="dropdown-basic"
            >
                <FontAwesomeIcon icon={faUser} /> &nbsp;
                <b>{user.fullName}</b>
            </Dropdown.Toggle>

            <Dropdown.Menu
                alignRight={true}
                className={styles["dropdown-menu-container"]}
            >
                <Dropdown.Header>Your Systems</Dropdown.Header>

                <div
                    className={styles["dropdown-menu-container--group-section"]}
                >
                    {memberships.map((membership, index) => {
                        return (
                            <HeaderBarDropdownMenuMembershipItem
                                key={index}
                                membership={membership}
                                isActive={
                                    membership.id === user.activeMembership?.id
                                }
                                onSelected={() => {
                                    if (
                                        membership.id !==
                                        user.activeMembership?.id
                                    ) {
                                        membershipSelected(membership);
                                    }
                                }}
                            />
                        );
                    })}
                </div>

                <Dropdown.Divider className={styles["thin-divider"]} />

                <Dropdown.Item as="div" className={` ${styles["drop-item"]}`}>
                    <Link
                        className={`${styles["manage-account-link"]} ${styles["menu-item"]}`}
                        to={"/manage-account"}
                    >
                        Manage Account
                    </Link>
                </Dropdown.Item>

                <Dropdown.Item
                    onClick={logout}
                    className={`${styles["drop-item"]} ${styles["menu-item"]}`}
                >
                    Log out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default HeaderBarDropdownMenu;
