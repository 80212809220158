import MiddleWare from "../../utils/middleware";
import { EventEmitter } from "../../Components/EventEmitter";
import { ERROR_CHANNEL } from "Constants/constants";

export default class BaseReducerHandler {
    /*
    
        { initState, slice }
        slice is required
    */
    constructor({
        initState,
        slice,
        errorChannel = ERROR_CHANNEL.GENERAL,
        fireErrorOnSuccess = true,
    }) {
        this.initState = initState;
        this.slice = slice;
        this.errorChannel = errorChannel ?? ERROR_CHANNEL.GENERAL;
        this.fireErrorOnSuccess = fireErrorOnSuccess ?? true;
        this.pipes = [];
    }

    // actionParams should contain state and action
    generatePipe({ actionFn, actionParams }) {
        actionParams = actionParams ?? [];

        return (d, error, next) => {
            if (error) {
                throw error;
            }

            if (!actionFn) throw new Error("Action function does not exist");

            const pipeRet = actionFn(...actionParams, next);

            return pipeRet;
        };
    }

    generateErrorPipe({ errorChannel, fireErrorOnSuccess }) {
        return (d, error) => {
            if (error) {
                EventEmitter.dispatch(errorChannel, {
                    message: error.message,
                });
            } else {
                fireErrorOnSuccess &&
                    EventEmitter.dispatch(
                        errorChannel ?? ERROR_CHANNEL.GENERAL,
                        null
                    );
            }

            return this.initState;
        };
    }

    registerAction({ actionName, actionFn }) {
        this.pipes.push({
            actionName,
            actionFn,
            actionParams: [],
        });
    }

    reducer() {
        return (state = this.initState, action) => {
            let middleware = new MiddleWare({});

            const actionDataInjectedPipes = this.pipes.map((pipe) => {
                pipe.actionParams = [state, action];

                return pipe;
            });

            // data flows through the pipe here
            (actionDataInjectedPipes ?? []).forEach((pipe) => {
                middleware.use(this.generatePipe(pipe));
            });

            // default case
            middleware.use((d, error) => {
                if (error) {
                    throw error;
                }

                return this.initState;
            });

            middleware.use(
                this.generateErrorPipe({
                    errorChannel: this.errorChannel,
                    fireErrorOnSuccess: this.fireErrorOnSuccess,
                })
            );
            const ret = middleware.execSync();

            return ret;
        };
    }
}
