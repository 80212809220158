import React, { ReactFragment, FunctionComponent } from "react";
import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import AccordianExpandIcon from "./AccordianExpandIcon";
import "./Accordian.scss";

interface AccordianProps {
    title?: string | ReactFragment;
    content?: ReactFragment;
    styling?: string;
    className?: string;
    stylingHeader?: string;
    stylingBody?: string;
    children?: ReactFragment;
    onSelect?: any;
    expandAtStart?: boolean;
    field?: string;
}

const Accordian: FunctionComponent<AccordianProps> = ({
    title,
    content,
    styling,
    stylingHeader,
    stylingBody,
    children,
    className,
    onSelect,
    expandAtStart = true,
}: AccordianProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(expandAtStart);

    return (
        <Accordion
            className={`accordian-root ${styling} ${className ?? ""}`}
            defaultActiveKey={!expandAtStart ? "1" : "0"}
            onSelect={(e) => {
                setIsExpanded(e == 0);

                onSelect && onSelect(e);
            }}
        >
            <Card className={`${styling}`}>
                <Accordion.Toggle
                    className={`accordian-toggle noselect ${stylingHeader}`}
                    as={Card.Header}
                    eventKey="0"
                >
                    <div className="accordian-header-container">
                        <div className="accordian-header-container--content">
                            {title}
                        </div>

                        <AccordianExpandIcon isExpanded={isExpanded} />
                    </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                    <Card.Body className={`pr-0 ${stylingBody}`}>
                        {content}
                        {children}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default Accordian;
