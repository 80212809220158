type Callback = (...args: any) => void | Promise<void>;

type Events = Record<string, Callback>;

interface IEventEmitter {
    events: Events;
    dispatch: (event: string, data?: any) => void;
    subscribe: (event: string, callback: Callback) => void;
    off: (event: string, callback: Callback) => void;
}

const EventEmitter: IEventEmitter = {
    events: {},

    dispatch: function (event: string, data?: any): void {
        if (!this.events[event]) {
            return;
        }

        this.events[event].forEach((callback: Callback) => callback(data));
    },

    subscribe: function (event: string, callback: Callback): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }

        this.events[event].push(callback);
    },

    off: function (event: string, callback: Callback): void {
        if (this.events[event]) {
            for (let i = 0; i < this.events[event].length; i++) {
                if (this.events[event][i] === callback) {
                    this.events[event].splice(i, 1);
                    i--;
                }
            }
        }
    },
};

class EventHanlders {
    events: Events = {};

    addEvents(name: string, fn: Callback) {
        this.events[name] = fn;
    }
}

export { EventEmitter, EventHanlders };
