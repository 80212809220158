import React from "react";
import Loader from "../Loader/Loader";
import { Route } from "react-router-dom";
import { useAuth0 } from "../../auth/react-auth0-spa";

interface SecureRouteProps {
    path: string;
    authenticatedRoute: boolean;
    isDefault: boolean;
    Component: any;
}

const SecureRoute = ({
    path,
    authenticatedRoute,
    Component,
    isDefault = false,
}: SecureRouteProps) => {
    const { isAuthenticated, loginWithRedirect, loading } = useAuth0();

    return (
        <Route
            exact={!isDefault}
            path={isDefault ? "/" : path}
            render={(props) => {
                if (loading) {
                    return (
                        <Loader
                            message={"Loading application"}
                            fullpage={true}
                        />
                    );
                }

                if (authenticatedRoute && !isAuthenticated) {
                    loginWithRedirect({
                        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URI,
                    });

                    return null;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default SecureRoute;
