import React, { useMemo } from "react";
import { Loader, Toast } from "Components";
import styles from "./GlobalSuccessParams.module.scss";
import {
    FLOW_RULE_VALS,
    ID_ATTRIBUTE,
    SOFT_DELETE_ATTRIBUTE,
} from "Constants/constants";
import BaseORMModel from "models/BaseORMModel";
import { createSelector } from "redux-orm";
import orm from "models/orm.register";
import { useSelector } from "react-redux";
import { FlowRule } from "types";
import UpdateSuccessWarning from "./UpdateSuccessWarning";
import ScenarioService from "services/scenario.service";
import { UpdateGlobalSettingFlowRuleTablesRequest } from "services/types";
import GlobalSuccessParam from "./GlobalSuccessParam";
import { keyBy } from "lodash";

interface _GlobalSuccessParamsProps {
    globalSettingId: string;
    scenarioId: string;
    flowRules: FlowRule[];
}

const _GlobalSuccessParams = ({
    globalSettingId,
    scenarioId,
    flowRules,
}: _GlobalSuccessParamsProps) => {
    if (!flowRules || !globalSettingId) {
        return <Loader fullpage={false} message={"Loading..."} />;
    }

    const ruleMap = keyBy(flowRules ?? [], (r) => r.type);

    const handleGlobalSettingUpdate = async (flowRule: FlowRule) => {
        await Toast.wrap(
            async () => {
                const request: UpdateGlobalSettingFlowRuleTablesRequest = {
                    tables: flowRule.tables,
                };

                await ScenarioService.updateGlobalSettingFlowRuleTables(
                    scenarioId,
                    flowRule.id,
                    request
                );
            },
            "Successfully updated global setting flow rule",
            "Failed to update global setting flow rule"
        );
    };

    return (
        <div className={`${styles["padding-right"]} col-12 mt-0 mb-2`}>
            <UpdateSuccessWarning scenarioId={scenarioId} />

            <div className="row">
                {FLOW_RULE_VALS.map(({ label, state }) => {
                    const flowRule = ruleMap[state];

                    return (
                        <GlobalSuccessParam
                            key={flowRule.id}
                            flowRule={flowRule}
                            label={label}
                            onFlowRuleUpdated={handleGlobalSettingUpdate}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const successParamSelector = (globalSettingId: string) => {
    return createSelector(orm, (session) => {
        const { GlobalSetting } = session;

        return (
            GlobalSetting.select(session, {
                include: [
                    {
                        flowRules: [{ tables: ["records"] }],
                    },
                ],
                filter: {
                    [SOFT_DELETE_ATTRIBUTE]: false,
                    [ID_ATTRIBUTE]: globalSettingId,
                },
            })[0] ?? {}
        ).flowRules;
    });
};

interface GlobalSuccessParamsProps {
    globalSettingId: string;
    scenarioId: string;
}

const GlobalSuccessParams = ({
    globalSettingId,
    scenarioId,
}: GlobalSuccessParamsProps) => {
    const flowRules = useSelector(successParamSelector(globalSettingId));

    const scene = useMemo(() => {
        return (
            <_GlobalSuccessParams
                scenarioId={scenarioId}
                globalSettingId={globalSettingId}
                flowRules={flowRules}
            />
        );
    }, [BaseORMModel.getVersionId(flowRules ?? [])]);

    return scene;
};

export default GlobalSuccessParams;
