import { AppUser } from "types";
import API from "../api";
import { RegisterRequest } from "./types";

export default class RegistrationService {
    public static async register(request: RegisterRequest): Promise<AppUser> {
        const user = await API.post<AppUser>(`/users/register`, request);

        return user;
    }
}
