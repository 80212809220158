import React, { useMemo } from "react";
import { Loader } from "Components";
import {
    ID_ATTRIBUTE,
    ORM_SLICE,
    ORM_GLOBAL_SETTING,
    EVENT_FLOW_RULE_SWITCH_PREPARE,
    SOFT_DELETE_ATTRIBUTE,
} from "../../Constants/constants";
import { GlobalSuccessParams } from "../SuccessTable";
import { useSelector } from "react-redux";
import { createSelector } from "redux-orm";
import orm from "models/orm.register";
import BaseORMModel from "models/BaseORMModel";
import { store } from "../../redux";
import { changeFlowRuleType } from "stores/flowRule.store";
import { GlobalSetting } from "types";
import { useEvent } from "hooks/useEvent";
import ScenarioSection from "Components/ScenarioSection/ScenarioSection";

const getGlobalSetting = (gID: string) => {
    if (store && orm) {
        const state = store.getState();
        const session = orm.session(state[ORM_SLICE] || orm.getEmptyState());

        const sGlobalSetting = session[ORM_GLOBAL_SETTING].select(session, {
            include: [
                {
                    flowRules: [
                        {
                            tables: ["records"],
                        },
                    ],
                },
            ],
            filter: {
                [ID_ATTRIBUTE]: gID,
            },
        })[0];

        return sGlobalSetting;
    }
    return null;
};

interface _GlobalSettingsProps {
    globalSetting: GlobalSetting;
}

const _GlobalSettings = ({ globalSetting }: _GlobalSettingsProps) => {
    useEvent(
        EVENT_FLOW_RULE_SWITCH_PREPARE,
        async (event) => {
            const { flowRule: currentFlowRule, newType } = event ?? {};

            const sGlobalSetting: GlobalSetting = getGlobalSetting(
                globalSetting[ID_ATTRIBUTE]
            );

            if (sGlobalSetting && sGlobalSetting.flowRules) {
                const flowRule = sGlobalSetting?.flowRules.filter(
                    (flowRule) => flowRule.type === newType
                )[0];

                changeFlowRuleType(
                    currentFlowRule,
                    currentFlowRule.type,
                    newType,
                    flowRule?.tables
                );
            }
        },
        [BaseORMModel.getVersionId(globalSetting ?? {})]
    );

    if (!globalSetting) {
        return <Loader fullpage={false} message={"Loading..."} />;
    }

    return (
        <ScenarioSection title="Default partial success parameters">
            <GlobalSuccessParams
                scenarioId={globalSetting.scenarioId}
                globalSettingId={globalSetting.id}
            />
        </ScenarioSection>
    );
};

const globalSettingSelector = (scenarioId: string) => {
    return createSelector(orm, (session) => {
        const { GlobalSetting } = session;
        return GlobalSetting.select(session, {
            include: [],
            filter: { [SOFT_DELETE_ATTRIBUTE]: false, scenarioId },
        })[0];
    });
};

interface GlobalSettingsProps {
    scenarioId: string;
}

const GlobalSettings = ({ scenarioId }: GlobalSettingsProps) => {
    const globalSetting = useSelector(globalSettingSelector(scenarioId));

    const scene = useMemo(() => {
        return <_GlobalSettings globalSetting={globalSetting} />;
    }, [BaseORMModel.getVersionId(globalSetting ?? {})]);

    return scene;
};

export default GlobalSettings;
