import { KnownRoleOperations, KnownRoles } from "Constants/auth";
import { orderBy } from "lodash";
import { AppUser, Group, Membership, RoleOperation } from "types";

/**
 * Represent a wrapper arround a user object
 */
export default class EflowUser {
    public activeMembership: Membership;

    constructor(private user: AppUser) {
        if (user.activeMembershipId) {
            this.activeMembership = user.memberships?.find(
                (x) => x.id === user.activeMembershipId
            );
        }
    }

    /**
     * This user uuid
     */
    public get id(): string {
        return this.user.id;
    }

    /**
     * This user's full name
     */
    public get fullName(): string {
        return `${this.user.firstName}.${this.user.lastName}`;
    }

    public get isAppAdmin(): boolean {
        return this.user.roles?.some((x) => x.name === KnownRoles.APP_ADMIN);
    }

    /**
     * Get this user's groups
     * @returns The list of group this user is a member of
     */
    getAllGroups(): Group[] {
        const groups = this.user.memberships.map((membership) => {
            return membership.group;
        });

        return orderBy(groups, (group) => group.createdAt);
    }

    /**
     * Get the list of group that this user is allowed to view.
     * The user must have the role operation group:data in order to
     * view the group
     * @returns The list of groups that this user can see
     */
    public getViewableMemberships(): Membership[] {
        const memberships = this.user.memberships?.filter((membership) => {
            return membership.roles?.some((x) =>
                x.operations.some(
                    (o) => o.name === KnownRoleOperations.GROUP_DATA
                )
            );
        });

        return orderBy(memberships, (membership) => membership.group.createdAt);
    }

    /**
     * Check if this user is part of the give group
     * @param group The group to check for membership
     * @returns Whether this user is part of the group or not
     */
    public isMemberOfGroup(group: Group): boolean {
        if (group.memberships) {
            return group.memberships.some((x) => x.userId === this.id);
        } else {
            return this.user.memberships.some((x) => x.group.id === group.id);
        }
    }

    /**
     * Check if this user can perform an operation.
     * Will default to only check the user's role operations. If a groupId is provided then
     * the membership operations will be added.
     * @param operation The operation requiring permission
     * @param groupId Optional, a groupId to check for permission
     * @returns Whether this user has permission to perform the requested operation
     */
    public hasRolePermission(
        operation: KnownRoleOperations,
        groupId?: string
    ): boolean {
        const roleOperations =
            this.user.roles.flatMap((x) => x?.operations ?? []) ?? [];

        let membershipOperations: RoleOperation[] = [];

        if (groupId) {
            const membership = this.user.memberships?.find(
                (x) => x.groupId === groupId
            );

            if (membership) {
                membershipOperations =
                    membership.roles?.flatMap((x) => x.operations) ?? [];
            }
        }

        return [...roleOperations, ...membershipOperations].some(
            (x) => x.name === operation
        );
    }

    /**
     * Set the given membership as active
     * @param membership The membership to set active
     */
    public setActiveMembership(membership: Membership): void {
        this.user.activeMembershipId = membership.id;
        this.activeMembership = membership;
    }

    /**
     * Copy this wrapper
     * @returns A new user wrapper
     */
    public copy(): EflowUser {
        return new EflowUser(this.user);
    }
}
