import { AppUser, Membership } from "types";
import API from "../api";
import { ChangePasswordRequest } from "./types";

export default class UserService {
    public static async me(): Promise<AppUser> {
        const me = await API.get<AppUser>("/me");

        return me;
    }

    public static async setActiveMembership(
        membership: Membership
    ): Promise<void> {
        await API.patch<void>(`/me/memberships/${membership.id}/active`);
    }

    public static async changePassword(
        request: ChangePasswordRequest
    ): Promise<void> {
        await API.patch<void>("/me/change-password", request);
    }
}
