import { attr, oneToOne } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import {
    ORM_LOW_FLOW_FLOW_RULE,
    ID_ATTRIBUTE,
    ORM_FLOW_RULE,
} from "Constants/constants";

export default class LowFlowFlowRuleORM extends BaseORMModel {}

LowFlowFlowRuleORM.modelName = ORM_LOW_FLOW_FLOW_RULE;
LowFlowFlowRuleORM.fields = {
    [ID_ATTRIBUTE]: attr(),
    flowRuleId: oneToOne({
        to: ORM_FLOW_RULE,
        as: "flowRule",
        relatedName: "lowflow",
    }),
};
