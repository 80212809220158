import { attr } from "redux-orm";
import BaseORMModel from "./BaseORMModel";
import { ID_ATTRIBUTE, ORM_FLOW_DATA } from "Constants/constants";

export default class FlowDataORM extends BaseORMModel {}

FlowDataORM.modelName = ORM_FLOW_DATA;
FlowDataORM.fields = {
    [ID_ATTRIBUTE]: attr(),

    start: attr(),
    end: attr(),
    latestUpdatedFileAzureId: attr(),
    latestFileName: attr(),
};
