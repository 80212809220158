import React from "react";
import NumericInput from "Components/NumericInput/NumericInput";
import { ComplianceData, FlowData, ReportingSite } from "types";
import styles from "./SystemSetupReportingSite.module.scss";
import { UpdateReportingSiteRequest } from "services/types";
import SystemSetupReportingSiteFlowDataUpload from "./SystemSetupReportingSiteFlowDataUpload";
import ReportingSiteComplianceDataManualUpload from "./ReportingSiteComplianceDataManualUpload";

type SystemSetupReportingSiteProps = {
    data: ReportingSite;
    updateReportingSite: (
        id: string,
        data: Partial<UpdateReportingSiteRequest>
    ) => void;
    onFlowDataUpdate: (id: string, flowData: FlowData) => void;
    onComplianceDataUpdate: (
        id: string,
        complianceData: ComplianceData
    ) => void;
};

const SystemSetupReportingSite = (props: SystemSetupReportingSiteProps) => {
    const {
        data,
        updateReportingSite,
        onFlowDataUpdate,
        onComplianceDataUpdate,
    } = props;

    if (!data) {
        return null;
    }

    const id = data.id;

    const flowData: Partial<FlowData> = data?.flowData ?? {};
    const complianceData: Partial<ComplianceData> = data?.complianceData ?? {};

    return (
        <>
            <div>
                <b>Gauge number</b>
            </div>

            <div>
                <input
                    id={`gaugename-${id}`}
                    type="text"
                    placeholder="e.g. "
                    className={`form-control ${styles["input-name"]}`}
                    defaultValue={data.gaugeNumber}
                    onBlur={(e) => {
                        updateReportingSite(id, {
                            gaugeNumber: e.target.value,
                        });
                    }}
                ></input>
            </div>

            <SystemSetupReportingSiteFlowDataUpload
                reportingSiteId={id}
                gaugeNumber={data.gaugeNumber}
                flowData={flowData}
                flowDataUpdated={onFlowDataUpdate}
            />

            <ReportingSiteComplianceDataManualUpload
                reportingSiteId={id}
                complianceData={complianceData}
                complianceDataUpdated={onComplianceDataUpdate}
            />

            <div className="pt-1">
                <b>Lag</b>
            </div>

            <div>
                <div className="row">
                    <div className="col-1">
                        <NumericInput
                            name=""
                            className={`form-control ${styles["input-name"]}`}
                            value={data.lag}
                            wholeNumber={true}
                            requirePositive={false}
                            defaultValue={0}
                            onBlur={(value) => {
                                value = Math.round(value);

                                updateReportingSite(id, {
                                    lag: value,
                                });
                            }}
                        />
                    </div>
                    <div className={`col-1 ${styles["vertical-center"]}`}>
                        Days
                    </div>
                </div>
            </div>
        </>
    );
};

export default SystemSetupReportingSite;
