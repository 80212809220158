import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/pro-solid-svg-icons";
import { guid } from "utils/functions";

interface TableProps {
    columns: any[];
    data: any[];
    canSort: boolean;
}

const Table = ({ columns, data, canSort }: TableProps) => {
    const columnsMem = useMemo(() => {
        return columns;
    }, [columns]);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns: columnsMem,
                data,
            },
            useSortBy
        );

    // Render the UI for your table
    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup: any) => (
                    <tr key={guid()} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th
                                key={guid()}
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                )}
                            >
                                {column.render("Header")}

                                {canSort && column.sortable && (
                                    <span className="pl-2">
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <FontAwesomeIcon
                                                    icon={faSortUp}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSortDown}
                                                />
                                            )
                                        ) : (
                                            <FontAwesomeIcon icon={faSort} />
                                        )}
                                    </span>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row: any) => {
                    prepareRow(row);

                    return (
                        <tr key={guid()} {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                                return (
                                    <td key={guid()} {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
