import React from "react";
import { Accordian, Toast } from "Components";
import { ComplianceData, FlowData, ReportingSite } from "types";
import SystemSetupReportingSite from "./SystemSetupReportingSite";
import AccodianTitle from "./AccordianTitle";
import { ReportingSiteService } from "services";
import { EventEmitter } from "Components/EventEmitter";
import { EVENT_SPATIAL_SETUP_ENTITY_REMOVE } from "Constants/constants";
import { UpdateReportingSiteRequest } from "services/types";
import { useState } from "react";

type AccordianReportingSiteProps = {
    reportingSite: ReportingSite;
    onRemoved: (reportingSite: ReportingSite) => void;
    onFlowDataUpdate: (id: string, flowData: FlowData) => void;
    onComplianceDataUpdate: (
        id: string,
        complianceData: ComplianceData
    ) => void;
};

const AccordianReportingSite = (props: AccordianReportingSiteProps) => {
    const {
        reportingSite,
        onRemoved,
        onFlowDataUpdate,
        onComplianceDataUpdate,
    } = props;

    const [rs, setRs] = useState<ReportingSite>(reportingSite);

    const updateReportingSite = async (
        rs: ReportingSite,
        request: Partial<UpdateReportingSiteRequest>
    ) => {
        try {
            const next = await ReportingSiteService.updateReportingSite(
                rs.id,
                request
            );

            setRs({ ...rs, ...next });
        } catch (error) {
            Toast.error("Error updating reporting site");
        }
    };

    const removeReportingSite = (event: MouseEvent) => {
        event.stopPropagation();

        EventEmitter.dispatch(EVENT_SPATIAL_SETUP_ENTITY_REMOVE, {
            title: `Remove ${reportingSite.name}`,
            onDelete: async () => {
                try {
                    await ReportingSiteService.removeReportingSite(
                        reportingSite.id
                    );

                    onRemoved(reportingSite);
                } catch (error) {
                    Toast.error("Error removing reporting site");
                }
            },
        });
    };

    const updateFlowDataUpdate = (id: string, fd?: FlowData) => {
        setRs({
            ...rs,
            flowData: fd,
        });

        onFlowDataUpdate(id, fd);
    };

    const updateComplianceDataUpdate = (id: string, cd?: ComplianceData) => {
        setRs({
            ...rs,
            complianceData: cd,
        });

        onComplianceDataUpdate(id, cd);
    };

    return (
        <Accordian
            key={rs.id}
            expandAtStart={false}
            stylingHeader={"region-header"}
            title={
                <AccodianTitle
                    id={rs.id}
                    name={rs.name}
                    onUpdate={async (name) => {
                        await updateReportingSite(reportingSite, {
                            name: name,
                        });
                    }}
                    onDelete={removeReportingSite}
                />
            }
        >
            <SystemSetupReportingSite
                data={rs}
                updateReportingSite={async (id, request) => {
                    await updateReportingSite(reportingSite, request);
                }}
                onFlowDataUpdate={updateFlowDataUpdate}
                onComplianceDataUpdate={updateComplianceDataUpdate}
            />
        </Accordian>
    );
};

export default AccordianReportingSite;
