import styles from "./HeaderBar.module.scss";
import React from "react";
import TruiiLogo from "utils/truii-logo-160w.png";
import EflowLogo from "utils/eflow-projector-logo.png";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "../../auth/react-auth0-spa";
import HeaderBarDropdownMenu from "./HeaderBarDropdownMenu";
import { Membership } from "types";

const HeaderBar = () => {
    const { eflowUser: user, logout, setActiveMembership } = useAuth0();

    function logoutWithRedirect() {
        logout({ returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URI });
    }

    const handleMembershipSelected = async (
        membership: Membership
    ): Promise<void> => {
        await setActiveMembership(membership);
    };

    return (
        <div className={`${styles.navbar} row`}>
            <NavLink
                className={styles["truii-logo"]}
                activeClassName={styles["selected"]}
                style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                }}
                to={"/scenario-library"}
            >
                <img src={TruiiLogo} alt="" />
            </NavLink>

            <NavLink className={styles["eflow-logo"]} to={"/scenario-library"}>
                <img src={EflowLogo} alt="" />
            </NavLink>

            {user && (
                <HeaderBarDropdownMenu
                    user={user}
                    logout={logoutWithRedirect}
                    membershipSelected={handleMembershipSelected}
                />
            )}
        </div>
    );
};

export default HeaderBar;
