import React from "react";
import styles from "./ScenarioSection.module.scss";

interface ScenarioSectionProps {
    title: string;
    children: React.ReactNode;
}

const ScenarioSection = ({ title, children }: ScenarioSectionProps) => {
    return (
        <div className={styles["scenario-section"]}>
            <div
                className={`mb-2 row pl-5 pl-md-3 ${styles["scenario-section--header"]}`}
            >
                {title}
            </div>

            <div className="row pl-4 pr-3 pl-md-0 pr-md-0">{children}</div>
        </div>
    );
};

export default ScenarioSection;
