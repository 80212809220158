export const URL = {
    REGIONS: "/regions",
    SYSTEMS: "/systems",
    REPORTING_SITES: "/reporting-sites",
    SCENARIOS: "/scenarios",
    AZURE: "/azure",
    FLOW_DATA: "/flow-data",
    COMPLIANCE_DATA: "/compliance-data",
    CLIMATE_DATA: "/climate-data",
    GROUP: "/groups",
};
